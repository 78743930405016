var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, PropSync, Emit } from 'vue-property-decorator';
let Input = class Input extends Vue {
    input(event) {
        return event;
    }
    enter(event) {
        return event;
    }
};
__decorate([
    PropSync('value')
], Input.prototype, "valueInput", void 0);
__decorate([
    Prop({ default: 'text' })
], Input.prototype, "type", void 0);
__decorate([
    Prop()
], Input.prototype, "classes", void 0);
__decorate([
    Prop()
], Input.prototype, "inputClass", void 0);
__decorate([
    Prop({ default: () => ({ 'padding-top': '0', 'padding-bottom': '0' }) })
], Input.prototype, "inputStyle", void 0);
__decorate([
    Prop({ default: '' })
], Input.prototype, "iconClass", void 0);
__decorate([
    Prop({ default: '' })
], Input.prototype, "iconSize", void 0);
__decorate([
    Prop()
], Input.prototype, "prependIcon", void 0);
__decorate([
    Prop()
], Input.prototype, "appendIcon", void 0);
__decorate([
    Prop({ default: '#f4f4f4' })
], Input.prototype, "background", void 0);
__decorate([
    Prop({ default: true })
], Input.prototype, "prepend", void 0);
__decorate([
    Prop({ default: false })
], Input.prototype, "append", void 0);
__decorate([
    Prop({ default: false })
], Input.prototype, "selectText", void 0);
__decorate([
    Prop({ default: false })
], Input.prototype, "clearable", void 0);
__decorate([
    Prop({ default: false })
], Input.prototype, "disabled", void 0);
__decorate([
    Prop({ default: 'grey-2' })
], Input.prototype, "bgColor", void 0);
__decorate([
    Prop({ default: 'grey-2' })
], Input.prototype, "labelColor", void 0);
__decorate([
    Prop({ default: 'grey-2' })
], Input.prototype, "color", void 0);
__decorate([
    Prop({ default: '0' })
], Input.prototype, "debounceTime", void 0);
__decorate([
    Prop({ default: false })
], Input.prototype, "loading", void 0);
__decorate([
    Prop({ default: '' })
], Input.prototype, "mask", void 0);
__decorate([
    Prop({ default: () => [] })
], Input.prototype, "rules", void 0);
__decorate([
    Prop({ default: false })
], Input.prototype, "error", void 0);
__decorate([
    Prop({ default: '' })
], Input.prototype, "errorMessage", void 0);
__decorate([
    Prop({ default: '' })
], Input.prototype, "min", void 0);
__decorate([
    Prop({ default: '' })
], Input.prototype, "max", void 0);
__decorate([
    Prop({ default: true })
], Input.prototype, "bottomSlots", void 0);
__decorate([
    Prop({ default: false })
], Input.prototype, "hideBottomSpace", void 0);
__decorate([
    Prop()
], Input.prototype, "maxlength", void 0);
__decorate([
    Emit('input')
], Input.prototype, "input", null);
__decorate([
    Emit('enter')
], Input.prototype, "enter", null);
Input = __decorate([
    Component({
        name: 'Input',
    })
], Input);
export default Input;
