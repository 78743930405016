var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Emit, Prop, PropSync } from 'vue-property-decorator';
import { CompanyStatus } from '@/overmind/search/state';
import { mixpanelTracking } from '@/services/mixpanel';
let CompanyDrawerHeader = class CompanyDrawerHeader extends Vue {
    get insideSearch() {
        return this.$route.path.match(/search/);
    }
    closeDrawer() {
        return true;
    }
    openModalExport() {
        mixpanelTracking('exportação interna: Clicou em exportar lista');
        return true;
    }
    fetching() {
        return this.companyStatus === CompanyStatus.FETCHING;
    }
    hasHyperbolicData() {
        return (this.badges &&
            this.badges.hyperbolictree_size_estimation &&
            this.hyperbolicData &&
            this.hyperbolicData.nodes &&
            this.hyperbolicData.links &&
            this.hyperbolicData.nodes.length > 0 &&
            this.hyperbolicData.links.length > 0);
    }
};
__decorate([
    PropSync('currentTab', { default: 'summary' })
], CompanyDrawerHeader.prototype, "tab", void 0);
__decorate([
    Prop({ default: false })
], CompanyDrawerHeader.prototype, "showExportButton", void 0);
__decorate([
    Prop({ default: false })
], CompanyDrawerHeader.prototype, "isConsultHistory", void 0);
__decorate([
    Prop({ default: '' })
], CompanyDrawerHeader.prototype, "companyName", void 0);
__decorate([
    Prop({ default: '' })
], CompanyDrawerHeader.prototype, "companyArea", void 0);
__decorate([
    Prop({ default: () => { } })
], CompanyDrawerHeader.prototype, "badges", void 0);
__decorate([
    Prop({ default: false })
], CompanyDrawerHeader.prototype, "noCreditsToGetCompany", void 0);
__decorate([
    Prop({ default: CompanyStatus.INITIAL })
], CompanyDrawerHeader.prototype, "companyStatus", void 0);
__decorate([
    Prop({ default: () => { } })
], CompanyDrawerHeader.prototype, "hyperbolicData", void 0);
__decorate([
    Emit('closeDrawer')
], CompanyDrawerHeader.prototype, "closeDrawer", null);
__decorate([
    Emit('openModalExport')
], CompanyDrawerHeader.prototype, "openModalExport", null);
CompanyDrawerHeader = __decorate([
    Component({
        name: 'CompanyDrawerHeader',
    })
], CompanyDrawerHeader);
export default CompanyDrawerHeader;
