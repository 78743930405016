var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Chart } from 'highcharts-vue';
import TableBaseComponent from './ComponentsBase/TableBaseComponent.vue';
import MiniCardComponent from './ComponentsBase/MiniCardComponent.vue';
let FinancialCompanyComponent = class FinancialCompanyComponent extends Vue {
    constructor() {
        super(...arguments);
        this.model = ''; // Se tiver só um nem aparece o selection box
        this.options = []; // vai ser gerado a partir dos dados que temos
        this.table = true;
        this.header = [''];
        this.rowsTitleDemonstrativoExercicio = {
            receita_bruta: 'Receita Bruta',
            custos_operacionais: '(-) Custos Operacionais',
            operacional_bruto: 'Resultado operacional Bruto',
            despesas_operacionais: '(-) Despesas Operacionais',
            ebitda: 'EBITDA',
            ebit: 'EBIT',
            lucro_liquido: 'Lucro Líquido',
        };
        this.rowsTitleBalancoPatrimonial = {
            ativo_total: 'Ativo Total',
            passivo_total: 'Passivo Total',
            passivo_nao_circulante: '(-) Passivo não Circulante',
            passivo_circulante: '(-) Passivo Circulante',
            patrimonio_liquido: 'Patrimônio Líquido',
        };
        this.rowsTitleFluxoCaixa = {
            fluxo_caixa_atv_operacionais: 'Fluxo de caixa das atividades operacionais',
        };
        this.rowsTitleMetrics = {
            roa: 'ROA',
            roe: 'ROE',
            margem_bruta: 'Margem Bruta',
            margem_operacional: 'Margem Operacional',
            margem_ebitda: 'Margem EBITDA',
            margem_liquida: 'Margem Líquida',
            divida_sobre_ebitda: 'Dívida sobre EBITDA',
            divida_sobre_ativo: 'Dívida sobre Ativo',
        };
    }
    handleTitleSectionVisibility(model) {
        const titleSection = [
            'Balanço Patrimonial',
            'Demonstrativo do Exercício',
            'Fluxo de Caixa',
        ];
        if (titleSection.indexOf(model) > -1) {
            return true;
        }
    }
    getDataFinancialCompany() {
        return this.dataComponent.financial_company.data || [];
    }
    handleDataVisibility(value, key, label) {
        return value[key] && this.model === label;
    }
    buildOptions() {
        this.dataComponent.financial_company.data.forEach(value => {
            if (value.financial_data) {
                value.financial_data.forEach(row => {
                    if (this.getFirstKey(row) in this.rowsTitleDemonstrativoExercicio &&
                        this.handleExistKeyForSelect('Demonstrativo do Exercício')) {
                        this.options.push('Demonstrativo do Exercício');
                    }
                    if (this.getFirstKey(row) in this.rowsTitleBalancoPatrimonial &&
                        this.handleExistKeyForSelect('Balanço Patrimonial')) {
                        this.options.push('Balanço Patrimonial');
                    }
                    if (this.getFirstKey(row) in this.rowsTitleFluxoCaixa &&
                        this.handleExistKeyForSelect('Fluxo de Caixa')) {
                        this.options.push('Fluxo de Caixa');
                    }
                });
            }
            if (value.financial_metrics) {
                value.financial_metrics.forEach(row => {
                    if (this.getFirstKey(row) in this.rowsTitleMetrics &&
                        this.handleExistKeyForSelect('Métricas financeiras')) {
                        this.options.push('Métricas financeiras');
                    }
                });
            }
            this.model = this.options[0];
        });
    }
    getFirstKey(data) {
        return Object.keys(data)[0] ? Object.keys(data)[0] : '';
    }
    handleExistKeyForSelect(key) {
        if (this.options.indexOf(key) === -1) {
            return true;
        }
        return false;
    }
    mounted() {
        this.buildOptions();
    }
    buildRows(type) {
        let rows = [];
        this.dataComponent.financial_company.data.forEach(value => {
            this.buildFinancialDemonstrativeData(value, 'financial_data', type, rows);
            this.buildBalanceEquityData(value, 'financial_data', type, rows);
            this.buildCashFlowData(value, 'financial_data', type, rows);
            this.buildFinancialMetricsData(value, 'financial_metrics', type, rows);
        });
        return rows;
    }
    buildFinancialDemonstrativeData(value, key, constructType, rows = []) {
        if (value[key] && constructType === 'demonstrativo_exercicio') {
            value[key].forEach(row => {
                if (this.getFirstKey(row) in this.rowsTitleDemonstrativoExercicio) {
                    const key = this.getFirstKey(row);
                    let rowFormated = {};
                    rowFormated['titulo'] = this.rowsTitleDemonstrativoExercicio[key];
                    row[key].forEach(item => {
                        const keyItem = this.getFirstKey(item);
                        rowFormated[' ' + keyItem] = this.formatPrice(item[keyItem]);
                        if (this.header.indexOf(keyItem) === -1) {
                            this.header.push(keyItem);
                        }
                    });
                    rows.push(rowFormated);
                }
            });
        }
    }
    buildBalanceEquityData(value, key, constructType, rows = []) {
        if (value[key] && constructType === 'balanco_patrimonial') {
            value[key].forEach(row => {
                if (this.getFirstKey(row) in this.rowsTitleBalancoPatrimonial) {
                    const key = this.getFirstKey(row);
                    let rowFormated = {};
                    rowFormated['titulo'] = this.rowsTitleBalancoPatrimonial[key];
                    row[key].forEach(item => {
                        const keyItem = this.getFirstKey(item);
                        rowFormated[' ' + keyItem] = this.formatPrice(item[keyItem]);
                        if (this.header.indexOf(keyItem) === -1) {
                            this.header.push(keyItem);
                        }
                    });
                    rows.push(rowFormated);
                }
            });
        }
    }
    buildCashFlowData(value, key, constructType, rows = []) {
        if (value[key] && constructType === 'fluxo_caixa') {
            value[key].forEach(row => {
                if (this.getFirstKey(row) in this.rowsTitleFluxoCaixa) {
                    const key = this.getFirstKey(row);
                    let rowFormated = {};
                    rowFormated['titulo'] = this.rowsTitleFluxoCaixa[key];
                    row[key].forEach(item => {
                        const keyItem = this.getFirstKey(item);
                        rowFormated[' ' + keyItem] = this.formatPrice(item[keyItem]);
                        if (this.header.indexOf(keyItem) === -1) {
                            this.header.push(keyItem);
                        }
                    });
                    rows.push(rowFormated);
                }
            });
        }
    }
    buildFinancialMetricsData(value, key, constructType, rows = []) {
        if (value[key] && constructType === 'metricas_financeiras') {
            value[key].forEach(row => {
                if (this.getFirstKey(row) in this.rowsTitleMetrics) {
                    const key = this.getFirstKey(row);
                    let rowFormated = {};
                    rowFormated['titulo'] = this.rowsTitleMetrics[key];
                    row[key].forEach(item => {
                        const keyItem = this.getFirstKey(item);
                        rowFormated[' ' + keyItem] = item[keyItem] + '%';
                        if (this.header.indexOf(keyItem) === -1) {
                            this.header.push(keyItem);
                        }
                    });
                    rows.push(rowFormated);
                }
            });
        }
    }
    buildDemonstrativeExerciseDataChart(chartOptions, value, type) {
        if (value['financial_data'] && type === 'demonstrativo_exercicio') {
            let counter = -1;
            value['financial_data'].forEach(row => {
                if (this.getFirstKey(row) in this.rowsTitleDemonstrativoExercicio) {
                    const key = this.getFirstKey(row);
                    let rowFormated = {};
                    rowFormated['name'] = this.rowsTitleDemonstrativoExercicio[key];
                    rowFormated['data'] = [];
                    this.buildHighchartsInitialData(chartOptions, row, key, rowFormated);
                    counter++;
                    rowFormated['color'] = this.pickupGraphicColor(counter);
                    this.demonstrativeOfExerciseChartOptions(chartOptions, rowFormated);
                    rowFormated['data'] = rowFormated['data'].reverse();
                    chartOptions['series'].push(rowFormated);
                }
            });
        }
    }
    demonstrativeOfExerciseChartOptions(chartOptions, rowFormated) {
        if (rowFormated['data'].length <= 1) {
            chartOptions['chart'] = {
                type: 'column',
                height: 350,
                width: 1000,
                style: {
                    fontFamily: 'Roboto',
                },
            };
            (chartOptions['tooltip'] = {
                headerFormat: '',
            }),
                (chartOptions['plotOptions']['column'] = {
                    borderWidth: 0,
                    groupPadding: 0.1,
                    shadow: false,
                    dataLabels: {
                        enabled: true,
                        format: 'R$ {point.y:,.1f}',
                        style: {
                            fontFamily: 'Roboto',
                        },
                    },
                });
            chartOptions['xAxis'] = {
                type: '',
                categories: rowFormated['data'][0],
                visible: false,
            };
        }
        chartOptions['yAxis'] = {
            title: {
                text: '',
            },
            stackLabels: {
                enabled: true,
                format: 'R$ {total:,.0f}',
                style: {
                    fontFamily: 'Roboto',
                },
            },
            labels: {
                format: 'R$ {value:,.0f}',
                style: {
                    fontFamily: 'Roboto',
                },
            },
        };
    }
    buildBalanceEquityDataChart(chartOptions, value, type) {
        if (value['financial_data'] && type === 'balanco_patrimonial') {
            let counter = -1;
            value['financial_data'].forEach(row => {
                if (this.getFirstKey(row) in this.rowsTitleBalancoPatrimonial) {
                    const key = this.getFirstKey(row);
                    let rowFormated = {};
                    rowFormated['name'] = this.rowsTitleBalancoPatrimonial[key];
                    rowFormated['data'] = [];
                    this.buildHighchartsInitialData(chartOptions, row, key, rowFormated);
                    counter++;
                    rowFormated['color'] = this.pickupGraphicColor(counter);
                    this.balanceEquityChartsOptions(chartOptions, rowFormated);
                    rowFormated['data'] = rowFormated['data'].reverse();
                    chartOptions['series'].push(rowFormated);
                }
            });
        }
    }
    balanceEquityChartsOptions(chartOptions, rowFormated) {
        if (rowFormated['data'].length <= 1) {
            chartOptions['chart'] = {
                type: 'column',
                height: 350,
                width: 1000,
                style: {
                    fontFamily: 'Roboto',
                },
            };
            (chartOptions['tooltip'] = {
                headerFormat: '',
            }),
                (chartOptions['plotOptions']['column'] = {
                    borderWidth: 0,
                    groupPadding: 0.2,
                    shadow: false,
                    dataLabels: {
                        enabled: true,
                        format: 'R${point.y:,.1f}',
                        color: '#5F6A6C',
                    },
                });
            chartOptions['xAxis'] = {
                type: '',
                visible: false,
            };
        }
        chartOptions['yAxis'] = {
            title: {
                text: '',
            },
            stackLabels: {
                enabled: true,
                format: 'R${total:,.0f}',
                style: {
                    fontFamily: 'Roboto',
                },
            },
            labels: {
                format: 'R${value:,.0f}',
                style: {
                    fontFamily: 'Roboto',
                },
            },
        };
    }
    buildCashFlowDataChart(chartOptions, value, type) {
        if (value['financial_data'] && type === 'fluxo_caixa') {
            let counter = -1;
            value['financial_data'].forEach(row => {
                if (this.getFirstKey(row) in this.rowsTitleFluxoCaixa) {
                    const key = this.getFirstKey(row);
                    let rowFormated = {};
                    rowFormated['name'] = this.rowsTitleFluxoCaixa[key];
                    rowFormated['data'] = [];
                    this.buildHighchartsInitialData(chartOptions, row, key, rowFormated);
                    counter++;
                    rowFormated['color'] = this.pickupGraphicColor(counter);
                    this.cashFlowChartOptions(chartOptions, rowFormated);
                    rowFormated['data'] = rowFormated['data'].reverse();
                    chartOptions['series'].push(rowFormated);
                }
            });
        }
    }
    cashFlowChartOptions(chartOptions, rowFormated) {
        if (rowFormated['data'].length <= 1) {
            chartOptions['chart'] = {
                type: 'column',
                height: 350,
                width: 1000,
            };
            chartOptions['plotOptions']['column'] = {
                borderWidth: 0,
                groupPadding: 0.42,
                shadow: false,
                dataLabels: {
                    enabled: true,
                    format: 'R${point.y:,.1f}',
                },
            };
            chartOptions['xAxis'] = {
                type: '',
                categories: rowFormated['data'][0],
                visible: false,
            };
        }
        ;
        (chartOptions['tooltip'] = {
            headerFormat: '',
        }),
            (chartOptions['yAxis'] = {
                title: {
                    text: '',
                },
                stackLabels: {
                    enabled: true,
                    format: 'R${total:,.0f}',
                },
                labels: {
                    format: 'R${value:,.0f}',
                },
            });
    }
    buildFinancialMetricsDataChart(chartOptions, value, type) {
        if (value['financial_metrics'] && type === 'metricas_financeiras') {
            let counter = -1;
            value['financial_metrics'].forEach(row => {
                const key = this.getFirstKey(row);
                let rowFormated = {};
                rowFormated['name'] = this.rowsTitleMetrics[key];
                rowFormated['data'] = [];
                this.buildHighchartsInitialData(chartOptions, row, key, rowFormated);
                counter++;
                rowFormated['color'] = this.pickupGraphicColor(counter);
                this.financialMetricsChartsOptions(chartOptions, rowFormated);
                rowFormated['data'] = rowFormated['data'].reverse();
                chartOptions['series'].push(rowFormated);
            });
        }
    }
    financialMetricsChartsOptions(chartOptions, rowFormated) {
        if (rowFormated['data'].length <= 1) {
            chartOptions['chart'] = {
                type: 'column',
                height: 400,
                width: 1000,
            };
            chartOptions['plotOptions']['column'] = {
                borderWidth: 0,
                groupPadding: 0.2,
                shadow: false,
                dataLabels: {
                    enabled: true,
                    format: '{point.y:,.3f}%',
                },
            };
            (chartOptions['tooltip'] = {
                headerFormat: '',
            }),
                (chartOptions['xAxis'] = {
                    visible: false,
                });
        }
    }
    buildHighchartsInitialData(chartOptions, row, key, rowFormated) {
        row[key].forEach(item => {
            const keyAno = this.getFirstKey(item);
            if (chartOptions['plotOptions']['series']['pointStart'] > Number(keyAno)) {
                chartOptions['plotOptions']['series']['pointStart'] = Date.UTC(Number(keyAno), 0);
            }
            rowFormated['data'].push(item[keyAno]);
        });
    }
    buildGraphic(type) {
        let chartOptions = {
            chart: {
                style: {
                    fontFamily: 'Roboto',
                },
            },
            credits: {
                enabled: false,
            },
            xAxis: {
                type: 'datetime',
            },
            subtitle: {
                text: '',
            },
            yAxis: {
                title: {
                    text: '',
                },
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false,
                    },
                    pointStart: 99999,
                    pointIntervalUnit: 'year',
                },
            },
            title: {
                text: '',
            },
            series: [],
        };
        this.dataComponent.financial_company.data.forEach(value => {
            this.buildDemonstrativeExerciseDataChart(chartOptions, value, type);
            this.buildBalanceEquityDataChart(chartOptions, value, type);
            this.buildCashFlowDataChart(chartOptions, value, type);
            this.buildFinancialMetricsDataChart(chartOptions, value, type);
        });
        return chartOptions;
    }
    formatPrice(price) {
        price = price.toString().replace(/\./g, ',');
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    pickupGraphicColor(position) {
        const colors = [
            '#084CBD',
            '#4C525A',
            '#2F80ED',
            '#828282',
            '#1296FC',
            '#95A5A6',
            '#14AAFF',
            '#9EB3DC',
            '#2D9CDB',
            '#56CCF2',
        ];
        return colors[position];
    }
};
__decorate([
    Prop({ default: {} })
], FinancialCompanyComponent.prototype, "dataComponent", void 0);
FinancialCompanyComponent = __decorate([
    Component({
        name: 'FinancialCompany',
        components: {
            TableBaseComponent,
            MiniCardComponent,
            highcharts: Chart,
        },
    })
], FinancialCompanyComponent);
export default FinancialCompanyComponent;
