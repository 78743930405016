var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import IconLogoSad from '@/components/Icons/IconLogoSad.vue';
import IconRetry from '@/components/Icons/IconRetry.vue';
let ErrorMessage = class ErrorMessage extends Vue {
    get errorMessage() {
        return `<b style="color:#5F6A6C;">Ops!</b> ${this.message}`;
    }
    tryToRetry() {
        this.$emit('retryButtonClicked');
    }
};
__decorate([
    Prop({ default: 'Oh no,' })
], ErrorMessage.prototype, "title", void 0);
__decorate([
    Prop({ default: 'Um erro inesperado aconteceu!' })
], ErrorMessage.prototype, "message", void 0);
__decorate([
    Prop({ default: 'Entre em contato com nossa equipe de suporte.' })
], ErrorMessage.prototype, "description", void 0);
__decorate([
    Prop({ default: '' })
], ErrorMessage.prototype, "title_style", void 0);
__decorate([
    Prop({ default: '' })
], ErrorMessage.prototype, "message_style", void 0);
__decorate([
    Prop({ default: '' })
], ErrorMessage.prototype, "description_style", void 0);
__decorate([
    Prop({ default: false })
], ErrorMessage.prototype, "has_retry", void 0);
ErrorMessage = __decorate([
    Component({
        components: {
            IconLogoSad,
            IconRetry,
        },
    })
], ErrorMessage);
export default ErrorMessage;
