var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import CardBaseComponent from './ComponentsBase/CardBaseComponent.vue';
let FullQsa = class FullQsa extends Vue {
    constructor() {
        super(...arguments);
        this.model = 'Atual';
        this.options = ['Atual'];
    }
    getFullQsa() {
        return this.dataComponent.full_qsa;
    }
    buildOptions() {
        this.dataComponent.full_qsa.forEach(item => {
            if (item.data_historic) {
                item.data_historic.forEach(hist => {
                    this.options.push(this.getFirstKey(hist));
                });
            }
        });
    }
    mounted() {
        this.buildOptions();
    }
    getFirstKey(data) {
        return Object.keys(data)[0] ? Object.keys(data)[0] : '';
    }
};
__decorate([
    Prop({ default: {} })
], FullQsa.prototype, "dataComponent", void 0);
FullQsa = __decorate([
    Component({
        name: 'FullQsa',
        components: {
            CardBaseComponent,
        },
    })
], FullQsa);
export default FullQsa;
