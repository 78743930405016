var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import TableBaseComponent from './ComponentsBase/TableBaseComponent.vue';
import MiniCardComponent from './ComponentsBase/MiniCardComponent.vue';
import IconContract from '@/components/Icons/IconContract.vue';
import IconContractWait from '@/components/Icons/IconContractWait.vue';
let Lawsuits = class Lawsuits extends Vue {
    constructor() {
        super(...arguments);
        this.header = ['Nome', 'Valor total', 'Quantidade total'];
        this.last5Header = ['Descrição', 'Orgão', 'Data', 'Valor', 'Número do processo'];
        this.titleTables = {
            principais_oponentes_em_que_a_empresa_e_re: 'Principais Oponentes em que a empresa é ré',
            orgaos_mais_recorrentes_em_que_a_empresa_e_autora: 'Órgãos mais recorrentes em que a empresa é autora',
            assuntos_mais_recorrentes_em_que_a_empresa_e_autora: 'Assuntos mais recorrentes em que a empresa é autora',
            ultimos_cinco_processos: 'Últimos 5 processos',
            principais_oponentes_em_que_a_empresa_e_autora: 'Principais oponentes em que a empresa é autora',
            assuntos_mais_recorrentes_em_que_a_empresa_e_re: 'Assuntos mais recorrentes em que a empresa é ré',
            orgaos_mais_recorrentes_em_que_a_empresa_e_re: 'Órgãos mais recorrentes em que a empresa é ré',
            areas_dos_processos_ativos_em_que_a_empresa_e_re: 'Área dos processos ativos em que a empresa é ré',
            areas_dos_processos_ativos_em_que_a_empresa_e_autora: 'Área dos processos ativos em que a empresa é autora',
        };
    }
    getDataLawsuits(key) {
        return this.dataComponent.lawsuits[key] || [];
    }
    formatPrice(price) {
        price = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return 'R$ ' + price;
    }
    buildRows(type) {
        const rows = [];
        this.dataComponent.lawsuits.data.forEach(value => {
            this.buildMainOpponentsCompanyDefendant(value, 'principais_oponentes_em_que_a_empresa_e_re', type, rows);
            this.buildRecurrentOrganCompanyAuthor(value, 'orgaos_mais_recorrentes_em_que_a_empresa_e_autora', type, rows);
            this.buildRecurrentMattersCompanyAuthor(value, 'assuntos_mais_recorrentes_em_que_a_empresa_e_autora', type, rows);
            this.buildLastFiveProcess(value, 'ultimos_cinco_processos', type, rows);
            this.buildMainOpponentsCompanyAuthor(value, 'principais_oponentes_em_que_a_empresa_e_autora', type, rows);
            this.buildRecurrentMattersCompanyDefendant(value, 'assuntos_mais_recorrentes_em_que_a_empresa_e_re', type, rows);
            this.buildRecurrentOrganCompanyDefendant(value, 'orgaos_mais_recorrentes_em_que_a_empresa_e_re', type, rows);
            this.buildActiveProcessZoneCompanyDefendant(value, 'areas_dos_processos_ativos_em_que_a_empresa_e_re', type, rows);
            this.buildActiveProcessZoneCompanyAuthor(value, 'areas_dos_processos_ativos_em_que_a_empresa_e_autora', type, rows);
        });
        return rows;
    }
    buildMainOpponentsCompanyDefendant(value, key, constructType, rows) {
        if (constructType == 'principais_oponentes_em_que_a_empresa_e_re' &&
            value[key]) {
            value[key].forEach(item => {
                let rowFormated = {};
                rowFormated['name'] = item.nome;
                rowFormated['price'] = this.formatPrice(item.valor);
                rowFormated['quantity'] = item.quantidade;
                rows.push(rowFormated);
            });
        }
    }
    buildRecurrentOrganCompanyAuthor(value, key, constructType, rows) {
        if (constructType == 'orgaos_mais_recorrentes_em_que_a_empresa_e_autora' &&
            value[key]) {
            value[key].forEach(item => {
                let rowFormated = {};
                rowFormated['name'] = item.orgao;
                rowFormated['price'] = this.formatPrice(item.valor);
                rowFormated['quantity'] = item.quantidade;
                rows.push(rowFormated);
            });
        }
    }
    buildRecurrentMattersCompanyAuthor(value, key, constructType, rows) {
        if (constructType == 'assuntos_mais_recorrentes_em_que_a_empresa_e_autora' &&
            value[key]) {
            value[key].forEach(item => {
                let rowFormated = {};
                rowFormated['name'] = item.assunto;
                rowFormated['price'] = this.formatPrice(item.valor);
                rowFormated['quantity'] = item.quantidade;
                rows.push(rowFormated);
            });
        }
    }
    buildLastFiveProcess(value, key, constructType, rows) {
        if (constructType == 'ultimos_cinco_processos' && value[key]) {
            value[key].forEach(item => {
                let rowFormated = {};
                rowFormated['name'] =
                    item.assunto == null ? 'Não contém descrição' : item.assunto;
                rowFormated['orgao'] = item.orgao;
                rowFormated['date'] = item.data;
                rowFormated['price'] = this.formatPrice(item.valor);
                rowFormated['cnj'] = item.cnj;
                rows.push(rowFormated);
            });
        }
    }
    buildMainOpponentsCompanyAuthor(value, key, constructType, rows) {
        if (constructType == 'principais_oponentes_em_que_a_empresa_e_autora' &&
            value[key]) {
            value[key].forEach(item => {
                let rowFormated = {};
                rowFormated['name'] = item.nome;
                rowFormated['price'] = this.formatPrice(item.valor);
                rowFormated['quantity'] = item.quantidade;
                rows.push(rowFormated);
            });
        }
    }
    buildRecurrentMattersCompanyDefendant(value, key, constructType, rows) {
        if (constructType == 'assuntos_mais_recorrentes_em_que_a_empresa_e_re' &&
            value[key]) {
            value[key].forEach(item => {
                let rowFormated = {};
                rowFormated['name'] = item.assunto;
                rowFormated['price'] = this.formatPrice(item.valor);
                rowFormated['quantity'] = item.quantidade;
                rows.push(rowFormated);
            });
        }
    }
    buildRecurrentOrganCompanyDefendant(value, key, constructType, rows) {
        if (constructType == 'orgaos_mais_recorrentes_em_que_a_empresa_e_re' &&
            value[key]) {
            value[key].forEach(item => {
                let rowFormated = {};
                rowFormated['name'] = item.orgao;
                rowFormated['price'] = this.formatPrice(item.valor);
                rowFormated['quantity'] = item.quantidade;
                rows.push(rowFormated);
            });
        }
    }
    buildActiveProcessZoneCompanyDefendant(value, key, constructType, rows) {
        if (constructType == 'areas_dos_processos_ativos_em_que_a_empresa_e_re' &&
            value[key]) {
            if (value[key].processos) {
                value[key].processos.forEach(processo => {
                    let rowFormated = {};
                    rowFormated['name'] = processo.area;
                    rowFormated['price'] = this.formatPrice(processo.valor);
                    rowFormated['quantity'] = processo.quantidade;
                    rows.push(rowFormated);
                });
            }
            else {
                let rowFormated = {};
                rowFormated['name'] = 'Outros';
                rowFormated['price'] = this.formatPrice(value[key]['valor total']);
                rowFormated['quantity'] = 'Indefinido';
                rows.push(rowFormated);
            }
        }
    }
    buildActiveProcessZoneCompanyAuthor(value, key, constructType, rows) {
        if (constructType == 'areas_dos_processos_ativos_em_que_a_empresa_e_autora' &&
            value[key]) {
            if (value[key].processos) {
                value[key].processos.forEach(processo => {
                    let rowFormated = {};
                    rowFormated['name'] = processo.area;
                    rowFormated['price'] = this.formatPrice(processo.valor);
                    rowFormated['quantity'] = processo.quantidade;
                    rows.push(rowFormated);
                });
            }
            else {
                let rowFormated = {};
                rowFormated['name'] = 'Outros';
                rowFormated['price'] = this.formatPrice(0);
                rowFormated['quantity'] = 'Indefinido';
                rows.push(rowFormated);
            }
        }
    }
};
__decorate([
    Prop({ default: {} })
], Lawsuits.prototype, "dataComponent", void 0);
Lawsuits = __decorate([
    Component({
        name: 'Lawsuits',
        components: {
            TableBaseComponent,
            MiniCardComponent,
            IconContract,
            IconContractWait,
        },
    })
], Lawsuits);
export default Lawsuits;
