var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import Checkbox from '@/components/Checkbox.vue';
import EditPaymentMethod from './EditPaymentMethod.vue';
import IconCreditCardManager from '@/components/Icons/IconCreditCardManager.vue';
import IconCheckedNoBorder from '@/components/Icons/IconCheckedNoBorder.vue';
import IconAddPlus from '@/components/Icons/IconAddPlus.vue';
import IconAmex from '@/components/Icons/CreditCardFlags/IconAmex.vue';
import IconMasterCard from '@/components/Icons/CreditCardFlags/IconMasterCard.vue';
import IconVisa from '@/components/Icons/CreditCardFlags/IconVisa.vue';
import IconElo from '@/components/Icons/CreditCardFlags/IconElo.vue';
import IconBoleto from '@/components/Icons/IconBoleto.vue';
import IconCardDefault from '@/components/Icons/CreditCardFlags/IconCardDefault.vue';
import { connect } from '@/overmind';
import { mixpanelTracking } from '@/services/mixpanel';
var resizeIcon;
(function (resizeIcon) {
    resizeIcon["elo"] = "20px";
    resizeIcon["boleto"] = "20px";
    resizeIcon["credit_card"] = "18px";
    resizeIcon["amex"] = "30px";
})(resizeIcon || (resizeIcon = {}));
let ChangePaymentMethod = class ChangePaymentMethod extends Vue {
    constructor() {
        super(...arguments);
        this.finishEdit = false;
        this.editMode = false;
        this.acceptTerms = false;
        this.selected = 0;
        this.itemList = [
            {
                name: 'Boleto',
                desc: 'Pagamento via código de barras',
                icon: 'IconBoleto',
                flag: 'boleto',
                data: {
                    type: 'bank_slip',
                },
            },
            {
                name: 'Adicionar método de pagamento',
                desc: 'Cartão de crédito',
                icon: 'IconCardDefault',
                flag: 'credit_card',
            },
        ];
        this.flagNames = {
            amex: 'American Express',
            diners: 'Diners Club',
        };
    }
    beforeMount() {
        const isCredit = this.paymentMethod.type == 'credit_card';
        if (isCredit) {
            const card = this.paymentMethod.card;
            this.itemList.splice(1, 1);
            const credit_card = {
                name: `${this.FlagName} **** ${card.number.slice(-4)}`,
                desc: 'Vencimento cartão ' + this.ExpirationDate,
                icon: 'IconCreditCardManager',
                flag: card.flag,
                edit: true,
            };
            this.itemList.splice(0, 0, credit_card);
        }
    }
    get ExpirationDate() {
        const currentMonth = +this.paymentMethod.card.expiry_month;
        const month = currentMonth > 9 ? currentMonth : '0' + currentMonth;
        const year = this.paymentMethod.card.expiry_year;
        return month + '/' + year;
    }
    get FlagName() {
        const flag = this.paymentMethod.card.flag;
        return this.flagNames[flag] || flag;
    }
    ResizedIcon(icon) {
        const customSize = resizeIcon[icon];
        const size = customSize ? customSize : '25px';
        return size;
    }
    isActive(index) {
        return index == this.selected;
    }
    cancelBtn() {
        if (this.editMode) {
            this.editMode = false;
        }
        else {
            this.$emit('closeChangePayment', this.finishEdit);
        }
    }
    changeSelected(index) {
        if (this.itemList[index].flag == 'credit_card') {
            this.editMode = true;
        }
        else {
            this.selected = index;
        }
    }
    addedCard(cardData) {
        this.editMode = false;
        this.finishEdit = true;
        const credit_card = {
            name: `Cartão de crédito **** ${cardData.number.slice(-4)}`,
            desc: 'Vencimento cartão ' + cardData.expiration_date,
            icon: 'IconCardDefault',
            flag: 'default',
            edit: true,
            data: {
                type: 'credit_card',
                number: cardData.number,
                expiration_date: cardData.expiration_date,
                cvv: cardData.cvv,
                name: cardData.name,
                card_token: cardData.token,
            },
        };
        const isCredit = this.paymentMethod.type == 'credit_card';
        if (isCredit) {
            this.itemList[0] = credit_card;
        }
        else {
            this.paymentMethod.type = 'credit_card';
            this.itemList.splice(1, 1);
            this.itemList.splice(0, 0, credit_card);
        }
        this.selected = 0;
    }
    async confirmPaymentMethod() {
        const data = this.itemList[this.selected].data;
        if (data) {
            //@ts-ignore
            const valid = await this.actions.setPaymentMethod({
                card: { ...data },
                type: data.type,
            });
            if (!valid) {
                return;
            }
            mixpanelTracking('upgrade: Método de pagamento alterado com sucesso!');
            this.$q.notify({
                message: 'Método de pagamento alterado com sucesso!',
                type: 'success-user',
            });
        }
        this.$emit('closeChangePayment', true);
    }
    get isStripe() {
        //@ts-ignore
        return this.currentUser.customer_id;
    }
};
__decorate([
    Prop({
        default: {
            type: '',
            card: {
                number: '',
                flag: '',
                expiry_month: '',
                expiry_year: '',
            },
        },
    })
], ChangePaymentMethod.prototype, "paymentMethod", void 0);
ChangePaymentMethod = __decorate([
    Component(connect(({ actions, state }) => ({
        actions: actions.shop,
        currentUser: state.users.currentUser,
    }), {
        components: {
            Checkbox,
            EditPaymentMethod,
            IconCreditCardManager,
            IconBoleto,
            IconCardDefault,
            IconCheckedNoBorder,
            IconAddPlus,
            IconAmex,
            IconMasterCard,
            IconVisa,
            IconElo,
        },
    }))
], ChangePaymentMethod);
export default ChangePaymentMethod;
