var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Input from 'components/Input.vue';
import Tooltip from 'components/Tooltip.vue';
import { deburr, escapeRegExp, groupBy, isEmpty, isEqual, uniqWith, } from 'lodash';
import { json } from 'overmind';
import { copyToClipboard } from 'quasar';
import { Component, Prop, Vue } from 'vue-property-decorator';
let CompanyDrawerDecisorsTab = class CompanyDrawerDecisorsTab extends Vue {
    constructor() {
        super(...arguments);
        this.categories = {};
        this.tabs = [];
        this.currentIndexDecisors = null;
        this.filteredDecisors = json(this.decisors);
        this.clickCopyItem = false;
        this.itemCopiedIndex = -1;
        this.failedCopyItem = false;
        this.notFound = false;
        this.search = '';
        this.levels = [
            { label: 'Baixo', checked: false, queryField: 'level' },
            { label: 'Médio', checked: false, queryField: 'level' },
            { label: 'Alto', checked: false, queryField: 'level' },
            { label: 'Não classificado', checked: false, queryField: 'level' },
        ];
        this.decisorsWith = [
            { label: 'Linkedin', checked: false, queryField: 'lkd_url' },
            { label: 'E-mail', checked: false, queryField: 'mchecker_email' },
        ];
        this.filterDecisorAreaFor = [];
        this.filtersToogle = false;
        this.filters = {
            result: [],
            levelsBy: (decisors) => {
                this.filters.result = this.filterDecisorsLevelBy(decisors);
                return this.filters;
            },
            showWith: (decisors) => {
                this.filters.result = this.filterDecisorsWith(decisors);
                return this.filters;
            },
            areasBy: (decisors) => {
                this.filters.result = this.filterDecisorsAreaBy(decisors);
                return this.filters;
            },
        };
    }
    get toolTipBgColor() {
        return `
      background-color: #9EB3DC
    `;
    }
    get successToolTipBgColor() {
        return `
      background-color: #99CA3C
    `;
    }
    get selected() {
        return (this.decisorsWith
            //@ts-ignore
            .concat(this.levels)
            //@ts-ignore
            .concat(this.filterDecisorAreaFor)
            .filter(l => l.checked === true)
            .map(l => ({ label: l.label, queryField: l.queryField })));
    }
    buildCategories() {
        if (!isEmpty(this.decisors)) {
            this.categories = groupBy(json(this.filteredDecisors), 'department');
            this.tabs = Object.entries(this.categories);
            if (this.tabs.length === 0) {
                this.notFound = true;
            }
            else {
                this.notFound = false;
            }
        }
    }
    onShowDepartmentPanel(index) {
        this.currentIndexDecisors = index;
    }
    onHideDepartmentPanel(index) {
        if (index === this.currentIndexDecisors) {
            this.currentIndexDecisors = null;
        }
    }
    filterDecisorsByDepartmentOrNameOrPosition() {
        const search = escapeRegExp(this.search);
        if (!this.search) {
            if (this.selected.length > 0) {
                this.filteredDecisors = this.filters.result;
            }
            else {
                this.filteredDecisors = json(this.decisors);
            }
        }
        else {
            const origin = this.filters.result.length > 0 ? this.filters.result : this.decisors;
            this.filteredDecisors = json(origin.filter(d => deburr(d.name.toLowerCase()).match(deburr(search.toLowerCase())) ||
                deburr(d.department.toLowerCase()).match(deburr(search.toLowerCase())) ||
                deburr(d.position.toLowerCase()).match(deburr(search.toLowerCase()))));
            if (this.filteredDecisors.length > 0) {
                this.notFound = false;
            }
        }
        this.buildCategories();
    }
    buildFilterOfDepartmentDecisor() {
        const filters = this.tabs.map(item => {
            return { label: item[0], checked: false, queryField: 'department' };
        });
        this.filterDecisorAreaFor = json(filters);
    }
    clickToCopy(value, index) {
        this.clickCopyItem = true;
        this.itemCopiedIndex = index;
        copyToClipboard(value)
            .then(r => {
            setTimeout(() => {
                this.clickCopyItem = false;
                this.itemCopiedIndex = -1;
            }, 1000);
        })
            .catch(r => {
            setTimeout(() => {
                this.clickCopyItem = false;
                this.failedCopyItem = true;
                this.itemCopiedIndex = -1;
            }, 1000);
        });
    }
    openFilterMenu() {
        return (this.filtersToogle = !this.filtersToogle);
    }
    mounted() {
        this.buildCategories();
        this.buildFilterOfDepartmentDecisor();
    }
    filterDecisorsAreaBy(decisors) {
        let search = [];
        if (this.filterDecisorAreaFor.filter(f => f.checked).length > 0) {
            this.filterDecisorAreaFor
                .filter(f => f.checked)
                .forEach(selected => {
                search = search.concat(decisors.filter(decisor => decisor.department === selected.label));
            });
            return search;
        }
        return decisors;
    }
    filterDecisorsWith(decisors) {
        if (this.decisorsWith.filter(d => d.checked).length > 0) {
            let search = [];
            this.decisorsWith
                .filter(f => f.checked)
                .forEach(filterWith => {
                search = search.concat(decisors.filter(d => d[filterWith.queryField]));
                search = uniqWith(search, isEqual);
            });
            return search;
        }
        else {
            return decisors;
        }
    }
    filterDecisorsLevelBy(decisors) {
        let search = [];
        if (this.levels.filter(f => f.checked).length > 0) {
            this.levels
                .filter(f => f.checked)
                .forEach(selected => {
                search = search.concat(decisors.filter(decisor => decisor.level === selected.label));
            });
            return search;
        }
        return decisors;
    }
    applyFilters() {
        this.filters
            .levelsBy(json(this.decisors))
            .showWith(this.filters.result)
            .areasBy(this.filters.result);
        this.filteredDecisors = this.filters.result;
        if (this.search)
            this.filterDecisorsByDepartmentOrNameOrPosition();
        this.buildCategories();
    }
    getSelectedItem(item) {
        return Object.values(this.selected)
            .map(s => s.label)
            .includes(item);
    }
    resetDecisors() {
        this.filteredDecisors = json(this.decisors);
    }
};
__decorate([
    Prop({ default: () => [] })
], CompanyDrawerDecisorsTab.prototype, "decisors", void 0);
CompanyDrawerDecisorsTab = __decorate([
    Component({
        name: 'CompanyDrawerDecisorsTab',
        components: { Input, Tooltip },
    })
], CompanyDrawerDecisorsTab);
export default CompanyDrawerDecisorsTab;
