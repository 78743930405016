var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
let ExportationsFields = class ExportationsFields extends Vue {
};
__decorate([
    PropSync('checked', { default: () => { } })
], ExportationsFields.prototype, "checkbox", void 0);
__decorate([
    PropSync('input', { default: () => { } })
], ExportationsFields.prototype, "textValue", void 0);
__decorate([
    Prop({ default: () => { } })
], ExportationsFields.prototype, "icon", void 0);
__decorate([
    Prop({ default: () => { } })
], ExportationsFields.prototype, "label", void 0);
__decorate([
    Prop({ default: () => { } })
], ExportationsFields.prototype, "customLabel", void 0);
__decorate([
    Prop({ default: () => { } })
], ExportationsFields.prototype, "id", void 0);
__decorate([
    Prop({ default: () => { } })
], ExportationsFields.prototype, "disabled", void 0);
ExportationsFields = __decorate([
    Component({
        name: 'Checkbox',
        components: {},
    })
], ExportationsFields);
export default ExportationsFields;
