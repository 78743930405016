export function getJivoContainer() {
    const jivoMain = document.querySelector('jdiv#scrollbar-container');
    let jivoContainer = undefined;
    if (jivoMain) {
        jivoContainer = jivoMain.firstElementChild;
    }
    return jivoContainer;
}
export function getWelcomeMsg(msg) {
    const jivoContainer = getJivoContainer();
    let welcomeDiv = null;
    welcomeDiv = jivoContainer.querySelector('#jivo-container--welcome');
    const welcomeMessageText = document.createTextNode(msg);
    if (welcomeDiv) {
        welcomeDiv.textContent = '';
    }
    else {
        welcomeDiv = document.createElement('div');
        welcomeDiv.setAttribute('class', 'jivo-container--welcome-message text_017');
        welcomeDiv.setAttribute('id', 'jivo-container--welcome');
        welcomeDiv.textContent = '';
    }
    welcomeDiv.appendChild(welcomeMessageText);
    jivoContainer.appendChild(welcomeDiv);
    jivoContainer.scrollIntoView(false);
    return welcomeDiv;
}
export function getChoiceButton(choiceText, callback) {
    const button = document.createElement('button');
    button.setAttribute('class', 'jivo-container--choice-button');
    button.setAttribute('v-ripple', '');
    button.innerHTML = choiceText;
    button.onclick = callback;
    return button;
}
export function getChoiceCallback(msg, context, value) {
    //alert(msg);
    if (value) {
        const input = document.querySelector('.input_82b');
        const sendButton = document.querySelector('.sendButton_22d');
        const textArea = input.querySelector('textarea');
        textArea.setAttribute('value', 'Eu gostaria de mais créditos');
        textArea.textContent = 'Eu gostaria de mais créditos';
        const clickEvent = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: false,
        });
        sendButton.dispatchEvent(clickEvent);
    }
    // @ts-ignore
    window.jivo_api.sendOfflineMessage({
        name: 'name',
        email: 'sem-credito@email.com.br',
        message: 'Sim gostaria de mais créditos',
    });
}
export const clearClientHistory = () => {
    const jvClient = JSON.parse(localStorage.getItem('jv_store_Dv1depqfsv_client'));
    jvClient.client_info = {
        client_name: null,
        phone: null,
        email: null,
        description: null,
    };
    jvClient.display_name = null;
    localStorage.setItem('jv_store_Dv1depqfsv_client', JSON.stringify(jvClient));
    localStorage.removeItem('jv_messages_items_Dv1depqfsv');
};
export const open = () => {
    ;
    window.jivo_api.open();
};
export default {
    getJivoContainer,
    getChoiceButton,
    getChoiceCallback,
    getWelcomeMsg,
    clearClientHistory,
    open,
};
