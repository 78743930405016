var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import CompanyMapLocalization from 'components/CompanyMap.vue';
import TextClickToCopy from './TextClickToCopy.vue';
let CompanyDrawerSummaryTab = class CompanyDrawerSummaryTab extends Vue {
    constructor() {
        super(...arguments);
        this.cnpjEmployees = null;
        this.companyEmployees = null;
        this.cnpjRevenue = null;
        this.companyRevenue = null;
        this.clickCopy = false;
        this.showSearchCep = false;
    }
    mounted() {
        this.handleEmployees();
        this.handleRevenue();
    }
    onCompany(newVal) {
        if (newVal) {
            this.company = newVal;
            this.handleRevenue();
            this.handleEmployees();
        }
    }
    handleAddress() {
        return `${this.company.address.type_public_place} ${this.company.address.name_public_place} - ${this.company.address.number_public_place} - ${this.company.address.neighborhood} - ${this.company.address.city} - ${this.company.address.state} - ${this.formatCep(this.company.address.cep)}`;
    }
    get insideSearch() {
        return this.$route.path.match(/search/);
    }
    searchCompanyCEP(cep) {
        return cep;
    }
    handleCompanyAge(foundedAt) {
        const currentYear = new Date().getFullYear();
        const formatFoundedAt = currentYear - parseInt(foundedAt.substr(6, 9));
        return formatFoundedAt > 1
            ? `${formatFoundedAt} anos`
            : `${formatFoundedAt} ano`;
    }
    handleEmployees() {
        const { employees } = this.company;
        if (employees.employeesCnpjCount === employees.employeesCompanyCount) {
            this.companyEmployees = employees.employeesCompanyCount;
        }
        else {
            this.companyEmployees = employees.employeesCompanyCount;
            this.cnpjEmployees = employees.employeesCnpjCount;
        }
    }
    handleRevenue() {
        const { revenue } = this.company;
        if (revenue.cnpjRevenue === revenue.companyRevenue) {
            this.companyRevenue = revenue.companyRevenue;
        }
        else {
            this.companyRevenue = revenue.companyRevenue;
            this.cnpjRevenue = revenue.cnpjRevenue;
        }
    }
    formatCep(cep) {
        if (cep)
            this.showSearchCep = true;
        else
            this.showSearchCep = false;
        const part1 = cep.slice(0, 5);
        const part2 = cep.slice(5, 8);
        return `${part1}-${part2}`;
    }
};
__decorate([
    Prop({ default: () => { } })
], CompanyDrawerSummaryTab.prototype, "company", void 0);
__decorate([
    Watch('company', { deep: true, immediate: true })
], CompanyDrawerSummaryTab.prototype, "onCompany", null);
__decorate([
    Emit('searchCompanyCEP')
], CompanyDrawerSummaryTab.prototype, "searchCompanyCEP", null);
CompanyDrawerSummaryTab = __decorate([
    Component({
        name: 'CompanyDrawerSummaryTab',
        components: {
            TextClickToCopy,
            CompanyMapLocalization,
        },
    })
], CompanyDrawerSummaryTab);
export default CompanyDrawerSummaryTab;
