import { render, staticRenderFns } from "./icon-instagram.vue?vue&type=template&id=3c1a7a59&"
import script from "./icon-instagram.vue?vue&type=script&lang=ts&"
export * from "./icon-instagram.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./icon-instagram.vue?vue&type=custom&index=0&blockType=svg&xmlns=http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg&xmlns%3Axlink=http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink&version=1.1&x=0px&y=0px&viewBox=0%200%2024%2024&style=enable-background%3A%20new%200%200%2024%2024&xml%3Aspace=preserve&width=512&height=512"
if (typeof block0 === 'function') block0(component)

export default component.exports