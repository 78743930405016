var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import TableBaseComponent from './ComponentsBase/TableBaseComponent.vue';
let GPTData = class GPTData extends Vue {
    buildRows() {
        return this.dataComponent.data_gpt.product_service.map((val, i) => {
            return { [i]: val };
        });
    }
};
__decorate([
    Prop({ default: () => { } })
], GPTData.prototype, "dataComponent", void 0);
GPTData = __decorate([
    Component({
        name: 'GPTData',
        components: {
            TableBaseComponent,
        },
    })
], GPTData);
export default GPTData;
