var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
let TableBaseComponent = class TableBaseComponent extends Vue {
    headerCheck() {
        if (this.header[0] == 'Produto ou serviço') {
            return true;
        }
        else {
            return false;
        }
    }
    calculateMaxWidth() {
        const maxWidth = this.rows.reduce((max, row) => {
            const rowWidth = Object.keys(row).reduce((width, key) => {
                const cellValue = row[key];
                const cellWidth = typeof cellValue === 'object' && cellValue['value']
                    ? cellValue['value'].length
                    : String(cellValue).length;
                return Math.max(width, cellWidth);
            }, 0);
            return Math.max(max, rowWidth);
        }, 0);
        return maxWidth > 0 ? `${maxWidth * 8}px` : 'auto';
    }
    getKeyRow(data) {
        return Object.keys(data);
    }
};
__decorate([
    Prop()
], TableBaseComponent.prototype, "rows", void 0);
__decorate([
    Prop({ default: [] })
], TableBaseComponent.prototype, "header", void 0);
TableBaseComponent = __decorate([
    Component({
        name: 'TableBaseComponent',
        components: {},
    })
], TableBaseComponent);
export default TableBaseComponent;
