var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import Modal from 'components/Modal.vue';
import CompanyDrawerContactTabPhones from 'components/CompanyDrawerContactTabPhones.vue';
import CompanyDrawerContactTabEmails from 'components/CompanyDrawerContactTabEmails.vue';
import { json } from 'overmind';
import { copyToClipboard } from 'quasar';
let CompanyDrawerContactTab = class CompanyDrawerContactTab extends Vue {
    constructor() {
        super(...arguments);
        this.phonesValidated = this.buildValidatedPhones();
        this.emailsValidated = this.buildValidatedEmails();
        this.availableAddresses = this.buildAddress();
        this.allPhones = this.buildPhones();
        this.allEmails = this.buildEmails();
        this.showEmailsModal = false;
        this.showPhonesModal = false;
        this.infosetRoute = false;
        this.clickCopy = false;
        this.failedCopyItem = false;
    }
    mounted() {
        this.checkRoute();
    }
    buildEmails() {
        const emails = [];
        const contactDetails = this.company.additionalContactData;
        if (contactDetails && contactDetails.emails) {
            contactDetails.emails.forEach(item => {
                emails.push({
                    value: json(item.email),
                    status: {
                        flags: json(item.flags),
                    },
                });
            });
        }
        return emails;
    }
    buildPhones() {
        const phones = [];
        const contactDetails = this.company.additionalContactData;
        if (contactDetails && contactDetails.phones) {
            contactDetails.phones.forEach(item => {
                phones.push({
                    value: json(item.phone),
                    status: {
                        flags: json(item.flags),
                        validationAtElasticSearch: item.validations
                            ? item.validations
                            : undefined,
                    },
                });
            });
        }
        return phones;
    }
    buildAddress() {
        const contactDetails = this.company.additionalContactData;
        if (contactDetails && contactDetails.addresses) {
            return contactDetails.addresses.map(address => address.address);
        }
        else {
            return [];
        }
    }
    buildValidatedPhones() {
        const validPhones = [];
        if (this.company.phoneValidationRealtime.length > 0) {
            this.company.phoneValidationRealtime.forEach(item => {
                validPhones.push({
                    value: item.phone_number,
                    validation: item.validation_status,
                });
            });
            return validPhones;
        }
        return validPhones;
    }
    buildValidatedEmails() {
        const validEmails = [];
        if (this.company.emailValidatiosRealtime.length > 0) {
            this.company.emailValidatiosRealtime.forEach(item => {
                validEmails.push({
                    value: item.email_address,
                    validation: item.validation_status,
                });
            });
            return validEmails;
        }
        return validEmails;
    }
    checkRoute() {
        const path = this.$route.path.match('search');
        path !== null ? (this.infosetRoute = false) : (this.infosetRoute = true);
    }
    convertToObjetc(array) {
        const object = array.reduce((acc, { value, status }) => {
            acc[value] = status;
            return acc;
        }, {});
        return object;
    }
    copyTextItem(item) {
        this.clickCopy = true;
        copyToClipboard(item)
            .then(r => {
            setTimeout(() => {
                this.clickCopy = false;
            }, 1000);
        })
            .catch(r => {
            setTimeout(() => {
                this.clickCopy = false;
                this.failedCopyItem = true;
            }, 1000);
        });
    }
    handleCopyIcon() {
        return this.clickCopy
            ? 'icon-copy-success'
            : this.failedCopyItem
                ? 'icon-copy-failed'
                : 'icon-copy';
    }
};
__decorate([
    Prop({ default: () => { } })
], CompanyDrawerContactTab.prototype, "company", void 0);
CompanyDrawerContactTab = __decorate([
    Component({
        name: 'CompanyDrawerContactTab',
        components: {
            Modal,
            CompanyDrawerContactTabPhones,
            CompanyDrawerContactTabEmails,
        },
    })
], CompanyDrawerContactTab);
export default CompanyDrawerContactTab;
