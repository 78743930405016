var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import { isEmpty } from 'lodash';
import CompanyDemonstrativeOfExercise from 'components/CompanyDemonstrativeOfExercise.vue';
import CompanyEquityBalance from 'components/CompanyEquityBalance.vue';
import CompanyCashFlow from 'components/CompanyCashFlow.vue';
let CompanyFinancialData = class CompanyFinancialData extends Vue {
    // eslint-disable-next-line getter-return
    get demonstrativeOfExercise() {
        if (!isEmpty(this.data['Receita bruta']) ||
            !isEmpty(this.data['Custos operacionais']) ||
            !isEmpty(this.data['Resultado operacional bruto']) ||
            !isEmpty(this.data['Despesas operacionais']) ||
            !isEmpty(this.data['EBITDA']) ||
            !isEmpty(this.data['EBIT']) ||
            !isEmpty(this.data['Lucro líquido'])) {
            /*return {
              'Receita bruta': this.data['Receita bruta'],
              'Custos operacionais': this.data['Custos operacionais'],
              'Resultado operacional bruto': this.data['Resultado operacional bruto'],
              'Despesas operacionais': this.data['Despesas operacionais'],
              EBITDA: this.data['EBITDA'],
              EBIT: this.data['EBIT'],
              'Lucro líquido': this.data['Lucro líquido']
            };*/
            return this.buildDemonstrativeOfExerciseData();
        }
    }
    // eslint-disable-next-line getter-return
    get equityBalance() {
        if (!isEmpty(this.data['Ativo total']) ||
            !isEmpty(this.data['Passivo total']) ||
            !isEmpty(this.data['Passivo não circulante']) ||
            !isEmpty(this.data['Passivo circulante']) ||
            !isEmpty(this.data['Patrimonio líquido'])) {
            return this.buildEquityBalanceData();
        }
    }
    // eslint-disable-next-line getter-return
    get cashFlow() {
        if (!isEmpty(this.data['Fluxo de caixa das atividades operacionais']) ||
            !isEmpty(this.data['Fluxo de caixa livre'])) {
            return this.buildCashFlowData();
        }
    }
    buildDemonstrativeOfExerciseData() {
        let data = {
            'Receita bruta': this.data['Receita bruta'],
            'Custos operacionais': this.data['Custos operacionais'],
            'Resultado operacional bruto': this.data['Resultado operacional bruto'],
            'Despesas operacionais': this.data['Despesas operacionais'],
            EBITDA: this.data['EBITDA'],
            EBIT: this.data['EBIT'],
            'Lucro líquido': this.data['Lucro líquido'],
        };
        /* Remove possible undefined values */
        Object.keys(data).forEach(key => data[key] === undefined && delete data[key]);
        return data;
    }
    buildEquityBalanceData() {
        let data = {
            'Ativo total': this.data['Ativo total'],
            'Passivo total': this.data['Passivo total'],
            'Passivo não circulante': this.data['Passivo não circulante'],
            'Passivo circulante': this.data['Passivo circulante'],
            'Patrimonio líquido': this.data['Patrimonio líquido'],
        };
        /* Remove possible undefined values */
        Object.keys(data).forEach(key => data[key] === undefined && delete data[key]);
        return data;
    }
    buildCashFlowData() {
        let data = {
            'Fluxo de caixa das atividades operacionais': this.data['Fluxo de caixa das atividades operacionais'],
            'Fluxo de caixa livre': this.data['Fluxo de caixa livre'],
        };
        /* Remove possible undefined values */
        Object.keys(data).forEach(key => data[key] === undefined && delete data[key]);
        return data;
    }
};
__decorate([
    Prop({ default: () => { } })
], CompanyFinancialData.prototype, "data", void 0);
CompanyFinancialData = __decorate([
    Component({
        name: 'CompanyFinancialData',
        components: {
            CompanyDemonstrativeOfExercise,
            CompanyEquityBalance,
            CompanyCashFlow,
        },
    })
], CompanyFinancialData);
export default CompanyFinancialData;
