var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Modal from 'components/Modal.vue';
import TextClickToCopy from './TextClickToCopy.vue';
let CompanyDrawerContactTabEmails = class CompanyDrawerContactTabEmails extends Vue {
    constructor() {
        super(...arguments);
        this.showEmailsModal = false;
        this.clickCopyItem = false;
    }
    changeIconCopyClick(currVal) {
        if (currVal)
            setTimeout(() => {
                this.clickCopyItem = false;
            }, 300);
    }
    onInfosetRouteChange(curr, old) {
        if (curr) {
            this.validatedEmails();
        }
    }
    validatedEmails() {
        Object.keys(this.allEmails).forEach(key => {
            const email = this.validEmails.find(email => email.value === key);
            if (email && email.validation) {
                this.allEmails[key].validation = email.validation;
            }
        });
    }
};
__decorate([
    Prop({ default: () => { } })
], CompanyDrawerContactTabEmails.prototype, "allEmails", void 0);
__decorate([
    Prop({ default: () => { } })
], CompanyDrawerContactTabEmails.prototype, "validEmails", void 0);
__decorate([
    Prop({ default: () => false })
], CompanyDrawerContactTabEmails.prototype, "infosetRoute", void 0);
__decorate([
    Watch('clickCopyItem')
], CompanyDrawerContactTabEmails.prototype, "changeIconCopyClick", null);
__decorate([
    Watch('infosetRoute')
], CompanyDrawerContactTabEmails.prototype, "onInfosetRouteChange", null);
CompanyDrawerContactTabEmails = __decorate([
    Component({
        name: 'CompanyDrawerContactTabEmails',
        components: {
            Modal,
            TextClickToCopy,
        },
    })
], CompanyDrawerContactTabEmails);
export default CompanyDrawerContactTabEmails;
