var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import * as d3 from 'd3';
import { json } from 'overmind';
import Tooltip from '@/components/Tooltip.vue';
let CompanyDrawerHyperbolicGraph = class CompanyDrawerHyperbolicGraph extends Vue {
    constructor() {
        super(...arguments);
        this.active = false;
    }
    showFullscreenMode() {
        return true;
    }
    get hasNodes() {
        return this.hasHyperbolicData && this.data && this.data.nodes.length;
    }
    hTree(nodes, links) {
        // set the dimensions and margins of the graph
        var margin = { top: 0, right: 0, bottom: 0, left: 0 };
        // width = 960 - margin.left - margin.right,
        // height = 600 - margin.top - margin.bottom;
        var colors = d3.scaleOrdinal(d3.schemeCategory10);
        var link, node, edgepaths, edgelabels;
        // append the svg object to the body of the page
        var svg = d3
            .select('#company-hyperbolic-data svg')
            //.append('svg')
            .attr('width', 960)
            .attr('height', 600);
        let width = d3.select('svg').style('width');
        let height = d3.select('svg').style('height');
        width = width.substring(0, width.length - 2);
        height = height.substring(0, height.length - 2);
        svg.attr('viewBox', '0 0 700 700');
        //.attr('align', 'center')
        //.append('g')
        //.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
        svg
            .append('defs')
            .append('marker')
            .attr('id', 'arrowhead')
            .attr('viewBox', `${-width / 2} ${-height / 2} ${width * 2} ${height * 2}`)
            .attr('refX', 13)
            .attr('refY', 0)
            .attr('orient', 'auto')
            .attr('markerWidth', 13)
            .attr('markerHeight', 13)
            .attr('xoverflow', 'visible')
            .append('svg:path')
            .attr('d', 'M 0,-5 L 10 ,0 L 0,5')
            .attr('fill', '#999')
            .style('stroke', 'none');
        // Let's list the force we wanna apply on the network
        var simulation = d3
            .forceSimulation() // Force algorithm is applied to data.nodes
            .force('link', d3
            .forceLink() // This force provides links between nodes
            .id(function (d, i) {
            return d.id;
        }) // This provide  the id of a node
            //.links(links) // and this the list of links
            .distance(200))
            .force('charge', d3.forceManyBody().strength(-850)) // This adds repulsion between nodes. Play with the -400 for the repulsion strength
            .force('center', d3.forceCenter(width / 2, height / 2 + 300)); // This force attracts nodes to the center of the svg area
        // render
        function render() {
            // Initialize the links
            link = svg
                .selectAll('.link')
                .data(links)
                .enter()
                .append('line')
                .attr('class', 'link')
                .attr('marker-end', 'url(#arrowhead)')
                .style('stroke', '#aaa');
            link.append('title').text(function (d) {
                return d.label;
            });
            edgepaths = svg
                .selectAll('.edgepath')
                .data(links)
                .enter()
                .append('path')
                .attr('class', 'edgepath')
                .attr('fill-opacity', 0)
                .attr('stroke-opacity', 0)
                .attr('id', function (d, i) {
                return 'edgepath' + i;
            });
            edgelabels = svg
                .selectAll('.edgelabel')
                .data(links)
                .enter()
                .append('text')
                .style('pointer-events', 'none')
                .attr('class', 'edgelabel')
                .attr('id', function (d, i) {
                return 'edgelabel' + i;
            })
                .attr('font-size', 10)
                .attr('fill', '#aaa');
            /*.attrs({
                      'class': 'edgelabel',
                      'id': function (d, i) {return 'edgelabel' + i},
                      'font-size': 10,
                      'fill': '#aaa'
                  });*/
            /*.attrs({
                      'class': 'edgepath',
                      'fill-opacity': 0,
                      'stroke-opacity': 0,
                      'id': function (d, i) {return 'edgepath' + i}
                  })*/
            //.style('pointer-events', 'none');
            edgelabels
                .append('textPath')
                .attr('xlink:href', function (d, i) {
                return '#edgepath' + i;
            })
                .style('text-anchor', 'middle')
                .style('pointer-events', 'none')
                .attr('startOffset', '50%')
                .text(function (d) {
                return d.label;
            });
            // Initialize the nodes
            node = svg
                .selectAll('.node')
                .data(nodes)
                .enter()
                .append('g')
                .attr('class', 'node')
                .style('fill', '#69b3a2')
                .call(d3
                .drag()
                .on('start', dragstarted)
                .on('drag', dragged)
                /*.on('start', (event, d) =>
                node
                  .filter(p => p === d)
                  .raise()
                  .attr('stroke', 'black')
              )
              .on('drag', (event, d) => ((d.x = event.x), (d.y = event.y)))
              .on('end', (event, d) =>
                node.filter(p => p === d).attr('stroke', null)
              )*/
                .on('end', dragended));
            node
                .append('circle')
                .attr('r', 20)
                .style('fill', function (d, i) {
                return colors(i);
            })
                .append('svg:foreignObject')
                .attr('height', '15px')
                .attr('width', '15px')
                .html(function (d, i) {
                if (d.iconType === 'cpf') {
                    var color = colors(i);
                    return '<i aria-hidden="true" role="presentation" class="material-icons q-icon notranslate" style="font-size: 15px;">home_work</i>';
                }
            });
            /*.append('svg:foreignObject')
            .attr('height', '15px')
            .attr('width', '15px')
            .html(function(d, i) {
              var color = colors(i);
              if (d.iconType === 'cpf') {
                return (
                  '<i class="fa fa-user node-icon" style="color:' + color + '"></i>'
                );
              } else {
                return (
                  '<i class="fa fa-building node-icon" style="color:' +
                  color +
                  '"></i>'
                );
              }
            });*/
            var labels = node
                .append('text')
                .text(function (d) {
                return d.name;
            })
                .attr('dx', 0)
                .attr('dy', -25);
            node.append('title').text(function (d) {
                if (d.cnpj)
                    return `${d.name}\n${d.cnpj}`;
                else
                    return d.name;
            });
            /*node
              .append('text')
              .attr('font-family', 'Font Awesome 5 Free')
              .attr('font-weight', 900)
              .attr('font-size', function(d) {
                return '1.5em';
              })
              .text(function(d) {
                return '\uf101';
              });*/
            node
                .append('svg:image')
                .attr('class', 'circle')
                .attr('xlink:href', function (d) {
                if (d.iconType === 'cpf') {
                    return 'https://img.icons8.com/user';
                }
                else {
                    return '';
                }
            })
                .attr('x', '-8px')
                .attr('y', '-8px')
                .attr('width', '16px')
                .attr('height', '16px');
            link
                .append('text')
                .text(function (d) {
                return d.label;
            })
                .style('fill', '#ccc')
                .style('font-size', '12px')
                .attr('x', 560)
                .attr('y', 900);
            simulation.nodes(nodes).on('tick', ticked);
            simulation.force('link').links(links);
        } // end of render()
        function dragstarted(event, d) {
            if (!event.active)
                simulation.alphaTarget(0.3).restart();
            node
                .filter(p => p === d)
                .raise()
                .attr('stroke', 'black');
            d.fx = d.x;
            d.fy = d.y;
        }
        function dragged(event, d) {
            d.fx = event.x;
            d.fy = event.y;
            node.each(function (e) {
                if (d !== e) {
                    e.fx = e.x;
                    e.fy = e.y;
                }
            });
        }
        function dragended(event, d) {
            if (!event.active)
                simulation.alphaTarget(0);
            d.fx = d.x;
            d.fy = d.y;
            node
                .filter(p => p === d)
                .raise()
                .attr('stroke', undefined);
        }
        render();
        // This function is run at each iteration of the force algorithm, updating the nodes position.
        function ticked() {
            link
                .attr('x1', function (d) {
                return d.source.x;
            })
                .attr('y1', function (d) {
                return d.source.y;
            })
                .attr('x2', function (d) {
                edgepaths;
                return d.target.x;
            })
                .attr('y2', function (d) {
                return d.target.y;
            });
            node
                .attr('cx', function (d) {
                return d.x;
            })
                .attr('cy', function (d) {
                return d.y;
            });
            node.attr('transform', function (d) {
                return 'translate(' + d.x + ', ' + d.y + ')';
            });
            edgepaths.attr('d', function (d) {
                return ('M ' +
                    d.source.x +
                    ' ' +
                    d.source.y +
                    ' L ' +
                    d.target.x +
                    ' ' +
                    d.target.y);
            });
            edgelabels.attr('transform', function (d) {
                if (d.target.x < d.source.x) {
                    // @ts-ignore
                    var bbox = this.getBBox();
                    var rx = bbox.x + bbox.width / 2;
                    var ry = bbox.y + bbox.height / 2;
                    return 'rotate(180 ' + rx + ' ' + ry + ')';
                }
                else {
                    return 'rotate(0)';
                }
            });
        }
    }
    onShow() {
        this.active = true;
    }
    onHide() {
        this.active = false;
    }
    mounted() {
        if (this.data && this.data.nodes && this.data.links)
            //this.hyperbolicTree(json(this.data.nodes), json(this.data.links));
            this.hTree(json(this.data.nodes), json(this.data.links));
    }
};
__decorate([
    Prop({ default: () => { } })
], CompanyDrawerHyperbolicGraph.prototype, "data", void 0);
__decorate([
    Prop({ default: false })
], CompanyDrawerHyperbolicGraph.prototype, "hasHyperbolicData", void 0);
__decorate([
    Emit('showFullscreenMode')
], CompanyDrawerHyperbolicGraph.prototype, "showFullscreenMode", null);
CompanyDrawerHyperbolicGraph = __decorate([
    Component({
        name: 'CompanyDrawerHyperbolicGraph',
        components: {
            Tooltip,
        },
    })
], CompanyDrawerHyperbolicGraph);
export default CompanyDrawerHyperbolicGraph;
