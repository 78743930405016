var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, PropSync, Prop, Emit } from 'vue-property-decorator';
let Pagination = class Pagination extends Vue {
    onChangePage(page) {
        return page;
    }
};
__decorate([
    PropSync('page', { required: true, default: 1 })
], Pagination.prototype, "pageValue", void 0);
__decorate([
    Prop({ default: 1 })
], Pagination.prototype, "totalPages", void 0);
__decorate([
    Prop({ default: false })
], Pagination.prototype, "disabled", void 0);
__decorate([
    Prop({ default: true })
], Pagination.prototype, "boundary", void 0);
__decorate([
    Emit('pageChange')
], Pagination.prototype, "onChangePage", null);
Pagination = __decorate([
    Component({
        name: 'Pagination',
    })
], Pagination);
export default Pagination;
