var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import InnerLoading from '@/components/InnerLoading.vue';
let NewModal = class NewModal extends Vue {
    get calculateSectionHeight() {
        const headerHeight = this.disableHeader ? 0 : this.headerHeight;
        const footerHeight = this.disableFooter ? 0 : this.footerHeight;
        return headerHeight + footerHeight;
    }
    closeBtn() {
        this.show = false;
        this.$emit('closeBtn');
    }
};
__decorate([
    PropSync('open', { default: false })
], NewModal.prototype, "show", void 0);
__decorate([
    Prop({ default: false })
], NewModal.prototype, "loading", void 0);
__decorate([
    Prop({ default: 'Titulo' })
], NewModal.prototype, "title", void 0);
__decorate([
    Prop({ default: false })
], NewModal.prototype, "disableHeader", void 0);
__decorate([
    Prop({ default: false })
], NewModal.prototype, "disableFooter", void 0);
__decorate([
    Prop({ default: 1220 })
], NewModal.prototype, "width", void 0);
__decorate([
    Prop({ default: 720 })
], NewModal.prototype, "height", void 0);
__decorate([
    Prop({ default: 66 })
], NewModal.prototype, "headerHeight", void 0);
__decorate([
    Prop({ default: 66 })
], NewModal.prototype, "footerHeight", void 0);
NewModal = __decorate([
    Component({
        components: {
            InnerLoading,
        },
    })
], NewModal);
export default NewModal;
