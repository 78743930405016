var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import { json } from 'overmind';
let AditionalDataChar = class AditionalDataChar extends Vue {
    constructor() {
        super(...arguments);
        this.keys = this.dataActivity.map(item => item[0]);
        this.chartOptions = {
            chart: {
                type: 'column',
            },
            title: {
                text: '',
            },
            xAxis: {
                title: '',
                categories: json(this.keys),
            },
            yAxis: {
                title: '',
            },
            legend: {
                enabled: false,
            },
            plotOptions: {
                column: {
                    pointPadding: -0.2,
                },
            },
            series: [
                {
                    name: '',
                    data: json(this.values),
                    color: '#339FFF',
                },
            ],
            credits: false,
        };
    }
    get values() {
        if (this.millionData === true) {
            return this.dataActivity.map(item => item[1] * 1000);
        }
        else {
            return this.dataActivity.map(item => item[1]);
        }
    }
};
__decorate([
    Prop({ default: () => [] })
], AditionalDataChar.prototype, "dataActivity", void 0);
__decorate([
    Prop({ default: '' })
], AditionalDataChar.prototype, "tab", void 0);
__decorate([
    Prop({ default: '' })
], AditionalDataChar.prototype, "dataTitle", void 0);
__decorate([
    Prop({ default: false })
], AditionalDataChar.prototype, "millionData", void 0);
AditionalDataChar = __decorate([
    Component({
        components: {},
    })
], AditionalDataChar);
export default AditionalDataChar;
