var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
let CardBaseComponent = class CardBaseComponent extends Vue {
    constructor() {
        super(...arguments);
        this.err = false;
    }
    errorCaptured(err) {
        this.err = err;
    }
    returnFirstLetter() {
        if (this.iconText) {
            return this.title.split(' ')[0][0];
        }
        else {
            return '';
        }
    }
};
__decorate([
    Prop({ default: '' })
], CardBaseComponent.prototype, "title", void 0);
__decorate([
    Prop({ default: '' })
], CardBaseComponent.prototype, "subtitle", void 0);
__decorate([
    Prop({ default: false })
], CardBaseComponent.prototype, "iconText", void 0);
CardBaseComponent = __decorate([
    Component({
        name: 'CardBaseComponent',
        components: {},
    })
], CardBaseComponent);
export default CardBaseComponent;
