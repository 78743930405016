// visualizar contrato pela requisição de assinatura
// https://app.clicksign.com/sign/xxx-xxxxxxxxx-xxxxxxxxxxx-xxxxxxxx?embedded=true&origin=http://localhost:8080

function Clicksign(o) {
  'use strict'
  function n(n) {
    var t
    ;(e[(t = n).name || t] || []).forEach(function (t) {
      t(n.data)
    })
  }
  var r,
    u,
    t = window.location.protocol + '//' + window.location.host,
    e = {},
    a = function (t) {
      n(t.data)
    }
  return {
    endpoint: 'https://app.clicksign.com',
    origin: t,
    mount: function (t) {
      var n = '/sign/' + o,
        e = '?embedded=true&origin=' + this.origin,
        i = this.endpoint + n + e
      return (
        (u = document.getElementById(t)),
        (r = document.createElement('iframe')).setAttribute('src', i),
        r.setAttribute('style', 'width: 100%; height: 100%; border: none'),
        window.addEventListener('message', a),
        u.appendChild(r)
      )
    },
    unmount: function () {
      return (
        r &&
          (u.removeChild(r),
          (r = u = null),
          window.removeEventListener('message', a)),
        !0
      )
    },
    on: function (t, n) {
      return e[t] || (e[t] = []), e[t].push(n)
    },
    trigger: n,
  }
}

export const openClickSignEmbed = request_signature_key => {
  const container = 'signature-div'
  var widget = new Clicksign(request_signature_key)
  widget.endpoint = 'https://app.clicksign.com'
  widget.origin = window.location.protocol + '//' + window.location.host
  widget.mount(container)
  return widget
}
