var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { connect } from '@/overmind';
let ModalExperienceUser = class ModalExperienceUser extends Vue {
    constructor() {
        super(...arguments);
        this.close = true;
        this.rating = -1;
        this.message = '';
        this.type = 'Hyperbolic Tree Satisfaction';
    }
    closeModalExperienceUser() {
        this.openModal = false;
        return false;
    }
    submitExperience() {
        const data = { type: this.type, rating: this.rating, message: this.message };
        //@ts-ignore
        this.actionsUser.experienceUserHyperbolic(data);
        this.close = false;
    }
    changeColor(bannerId) {
        if (bannerId == 'banner1') {
            let banner = document.querySelector('.custom-banner1');
            let banner2 = document.querySelector('.custom-banner2');
            let banner3 = document.querySelector('.custom-banner3');
            let text = document.querySelector('.custom-text1');
            let text2 = document.querySelector('.custom-text2');
            let text3 = document.querySelector('.custom-text3');
            //@ts-ignore
            banner2.style.backgroundColor = '#f4f4f8';
            //@ts-ignore
            text2.style.color = '#95A5A6';
            //@ts-ignore
            banner3.style.backgroundColor = '#f4f4f8';
            //@ts-ignore
            text3.style.color = '#95A5A6';
            //@ts-ignore
            text.style.color = '#1296FC';
            //@ts-ignore
            banner.style.backgroundColor = '#1296FC';
            //@ts-ignore
            banner.style.color = '#1296FC';
            this.rating = 0;
        }
        else if (bannerId == 'banner2') {
            let banner = document.querySelector('.custom-banner2');
            let banner1 = document.querySelector('.custom-banner1');
            let banner3 = document.querySelector('.custom-banner3');
            let text = document.querySelector('.custom-text2');
            let text1 = document.querySelector('.custom-text1');
            let text3 = document.querySelector('.custom-text3');
            //@ts-ignore
            banner1.style.backgroundColor = '#f4f4f8';
            //@ts-ignore
            text1.style.color = '#95A5A6';
            //@ts-ignore
            banner3.style.backgroundColor = '#f4f4f8';
            //@ts-ignore
            text3.style.color = '#95A5A6';
            //@ts-ignore
            text.style.color = '#1296FC';
            //@ts-ignore
            banner.style.backgroundColor = '#1296FC';
            //@ts-ignore
            banner.style.color = '#1296FC';
            this.rating = 1;
        }
        else {
            let banner = document.querySelector('.custom-banner3');
            let banner1 = document.querySelector('.custom-banner1');
            let banner2 = document.querySelector('.custom-banner2');
            let text = document.querySelector('.custom-text3');
            let text1 = document.querySelector('.custom-text1');
            let text2 = document.querySelector('.custom-text2');
            //@ts-ignore
            banner1.style.backgroundColor = '#f4f4f8';
            //@ts-ignore
            text1.style.color = '#95A5A6';
            //@ts-ignore
            banner2.style.backgroundColor = '#f4f4f8';
            //@ts-ignore
            text2.style.color = '#95A5A6';
            //@ts-ignore
            text.style.color = '#1296FC';
            //@ts-ignore
            banner.style.backgroundColor = '#1296FC';
            //@ts-ignore
            banner.style.color = '#1296FC';
            this.rating = 2;
        }
    }
};
__decorate([
    Prop({ default: false })
], ModalExperienceUser.prototype, "openModal", void 0);
__decorate([
    Emit('closeModalExperienceUser')
], ModalExperienceUser.prototype, "closeModalExperienceUser", null);
ModalExperienceUser = __decorate([
    Component(connect(({ actions }) => ({
        actionsUser: actions.users,
    }), {
        name: 'ModalExperienceUser',
    }))
], ModalExperienceUser);
export default ModalExperienceUser;
