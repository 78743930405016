var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Emit } from 'vue-property-decorator';
let ModalNewHyperbolic = class ModalNewHyperbolic extends Vue {
    constructor() {
        super(...arguments);
        this.openModal = true;
        this.selected = false;
    }
    closeModal() {
        this.openModal = false;
    }
    newHyperbolicGo() {
        return true;
    }
    fixedHiperbolic(selected) {
        this.newHyperbolicGo();
        return selected;
    }
};
__decorate([
    Emit('newHyperbolicGo')
], ModalNewHyperbolic.prototype, "newHyperbolicGo", null);
__decorate([
    Emit('fixedHiperbolic')
], ModalNewHyperbolic.prototype, "fixedHiperbolic", null);
ModalNewHyperbolic = __decorate([
    Component({
        name: 'ModalNewHyperbolic',
    })
], ModalNewHyperbolic);
export default ModalNewHyperbolic;
