var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Prop, Component } from 'vue-property-decorator';
let Tooltip = class Tooltip extends Vue {
    get style() {
        return `
      font-size: ${this.fontSize};
      background-color: ${this.bgColor};
      padding-left: 1rem;
      padding-right: 1rem;
      min-width: 110px;
      text-align: center;
      font-family: ${this.fontFamily}, sans-serif;
    `;
    }
};
__decorate([
    Prop({ default: '' })
], Tooltip.prototype, "message", void 0);
__decorate([
    Prop({ default: '#9EB3DC' })
], Tooltip.prototype, "bgColor", void 0);
__decorate([
    Prop({ default: '250px' })
], Tooltip.prototype, "maxWidth", void 0);
__decorate([
    Prop({ default: '' })
], Tooltip.prototype, "maxHeight", void 0);
__decorate([
    Prop({ default: '14px' })
], Tooltip.prototype, "fontSize", void 0);
__decorate([
    Prop({ default: '' })
], Tooltip.prototype, "contentClass", void 0);
__decorate([
    Prop({ default: () => [10, 10] })
], Tooltip.prototype, "offSet", void 0);
__decorate([
    Prop({ default: 'bottom middle' })
], Tooltip.prototype, "anchor", void 0);
__decorate([
    Prop({ default: 'top middle' })
], Tooltip.prototype, "self", void 0);
__decorate([
    Prop({ default: 'Roboto' })
], Tooltip.prototype, "fontFamily", void 0);
Tooltip = __decorate([
    Component({
        name: 'Tooltip',
    })
], Tooltip);
export default Tooltip;
