var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import TableBaseComponent from './ComponentsBase/TableBaseComponent.vue';
import MiniCardComponent from './ComponentsBase/MiniCardComponent.vue';
import IconDislike from '@/components/Icons/IconDislike.vue';
import IconSchedule from '@/components/Icons/IconSchedule.vue';
import Tooltip from 'src/components/Tooltip.vue';
let Complaints = class Complaints extends Vue {
    constructor() {
        super(...arguments);
        this.model = ''; // Se tiver só um nem aparece o selection box
        this.options = []; // vai ser gerado a partir dos dados que temos
        this.header = ['', 'Quantidade'];
    }
    getDataComplaints() {
        return this.dataComponent.complaints || [];
    }
    handleShowModelProcon(value) {
        if (this.model === 'Procon' && Object.keys(value).shift() === 'procon') {
            return true;
        }
        return false;
    }
    handleShowModelReclameAqui(value) {
        if (this.model === 'Reclame Aqui' &&
            Object.keys(value).shift() === 'reclame_aqui') {
            return true;
        }
        return false;
    }
    buildOptions() {
        this.dataComponent.complaints.forEach(value => {
            if (Object.keys(value).shift() == 'procon') {
                this.options.push('Procon');
            }
            if (Object.keys(value).shift() == 'reclame_aqui') {
                this.options.push('Reclame Aqui');
            }
        });
        this.model = this.options[0];
    }
    mounted() {
        this.buildOptions();
    }
    buildRowsProcon(type) {
        const rows = [];
        this.dataComponent.complaints.forEach(value => {
            if (value.procon && type == 'categoria') {
                value.procon.problems_category.forEach(row => {
                    rows.push(this.buildRowFormated(row, 'ocurrency'));
                });
            }
            if (type == 'individual' && value.procon) {
                value.procon.problems.forEach(row => {
                    rows.push(this.buildRowFormated(row, 'ocurrency'));
                });
            }
        });
        return rows;
    }
    buildRowsReclameAqui(type) {
        const rows = [];
        this.dataComponent.complaints.forEach(value => {
            if (value.reclame_aqui && type == 'categoria') {
                value.reclame_aqui.complaints_by_category.forEach(row => {
                    rows.push(this.buildRowFormated(row, 'count'));
                });
            }
            if (type == 'individual' && value.reclame_aqui) {
                value.reclame_aqui.complaints_by_problem.forEach(row => {
                    rows.push(this.buildRowFormated(row, 'count'));
                });
            }
            if (type == 'produto' && value.reclame_aqui) {
                value.reclame_aqui.complaints_by_product.forEach(row => {
                    rows.push(this.buildRowFormated(row, 'count'));
                });
            }
        });
        return rows;
    }
    buildRowFormated(row, key) {
        const rowFormated = {
            name: row['name'],
            quantity: row[key],
        };
        return rowFormated;
    }
};
__decorate([
    Prop({ default: {} })
], Complaints.prototype, "dataComponent", void 0);
Complaints = __decorate([
    Component({
        name: 'Complaints',
        components: {
            TableBaseComponent,
            MiniCardComponent,
            IconDislike,
            IconSchedule,
            Tooltip,
        },
    })
], Complaints);
export default Complaints;
