var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-carousel-slide',{staticClass:"column no-wrap",attrs:{"name":_vm.name}},[_c('div',{staticClass:"modal-banner flex items-center justify-center"},[_c('img',{attrs:{"src":_vm.image}})]),_c('div',{staticClass:"q-mt-md column items-center"},[_c('span',{staticClass:"font-28 text-weight-bold q-mb-sm"},[_vm._v(_vm._s(_vm.title))]),_c('p',{staticClass:"text-weight-light text-center",class:_vm.messageSize === 'sm'
          ? 'font-17'
          : _vm.messageSize === 'md'
          ? 'font-18'
          : 'font-22',style:(_vm.messageSize === 'sm'
          ? 'line-height: 18px'
          : _vm.messageSize === 'md'
          ? 'line-height: 20px'
          : 'max-width: 420px; line-height: 22px'),domProps:{"innerHTML":_vm._s(_vm.message)}})])])}
var staticRenderFns = []

export { render, staticRenderFns }