var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import { uniq, flatten } from 'lodash';
import AditionalDataChar from './CompanyDrawerAdditionalDataChar.vue';
let CompanyEquityBalance = class CompanyEquityBalance extends Vue {
    getDataTable(data) {
        const entries = Object.entries(data);
        const headers = Object.keys(data);
        let rowsEntries = Object.values(data);
        rowsEntries = rowsEntries.map(r => {
            if (r['last_year']) {
                delete r['last_year'];
            }
            return r;
        });
        let rows = rowsEntries.map((r, i) => Object.entries(r));
        const yearsByCols = rows.map((col, i) => {
            return col.map(r => {
                if (r[0] !== 'last_year') {
                    return r[0];
                }
            });
        });
        const years = uniq(flatten(yearsByCols)).filter(y => y !== undefined);
        rows = years.map((y, i) => {
            let rowData = Object.entries(data).map((d, j) => {
                if (y === Object.keys(d[1])[i]) {
                    return d[1][y];
                }
            });
            return {
                year: y,
                data: rowData,
            };
        });
        return [{ headers: years, rows: entries }];
    }
    formatData(data) {
        return Intl.NumberFormat('pt-BR').format(Number.parseFloat(data));
    }
};
__decorate([
    Prop({ default: () => { } })
], CompanyEquityBalance.prototype, "data", void 0);
CompanyEquityBalance = __decorate([
    Component({
        name: 'CompanyEquityBalance',
        components: { AditionalDataChar },
    })
], CompanyEquityBalance);
export default CompanyEquityBalance;
