var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { connect } from '@/overmind';
import ModalExperienceUser from '@/components/HyperbolicTree/ModalExperienceUser.vue';
import CompanyDrawerHyperbolicGraph from '@/modules/infosets/components/CompanyDrawerHyperbolicGraph.vue';
import CompanyHyperbolicGraphFullscreen from '@/modules/infosets/components/CompanyHyperbolicGraphFullscreen.vue';
import ModalNewHyperbolic from '@/components/HyperbolicTree/ModalNewHyperbolic.vue';
import { mixpanelTracking } from '@/services/mixpanel';
let CompanyHyperbolicDataContainer = class CompanyHyperbolicDataContainer extends Vue {
    constructor() {
        super(...arguments);
        this.showDialog = false;
        this.openModal = false;
        this.showModalNew = false;
        this.showModalExperience = false;
        this.newHyperbolic = localStorage.getItem('newHyperbolic') || false;
        this.url = window.location.origin;
    }
    onShow(val, oldval) {
        const jvlabelWrap = document.getElementById('jvlabelWrap');
        if (!val && jvlabelWrap) {
            document.getElementById('jvlabelWrap').style.display = 'flex';
        }
        else {
            document.getElementById('jvlabelWrap') != null
                ? (document.getElementById('jvlabelWrap').style.display = 'none')
                : document.getElementById('jvlabelWrap');
        }
    }
    mounted() {
        mixpanelTracking('dados adicionais: Abriu arvore hiperbólica');
        const token = localStorage.getItem('auth_token_default');
        document.cookie = `TOKEN=${token}; path=/; domain=.speedio.com.br`;
        document.cookie =
            document.cookie = `API_URL=${this.getUrl()}; path=/; domain=.speedio.com.br`;
    }
    get getSearchId() {
        const searchId = this.$route.params.searchId;
        return searchId ? searchId : null;
    }
    getUrl() {
        if (window.location.hostname.match('localhost'))
            return 'https://api-only-internal-export.dev.speedio.com.br/api/v3';
        if (window.location.hostname.match('dev'))
            return 'https://api-only-internal-export.dev.speedio.com.br/api/v3';
        if (window.location.hostname.match('test') ||
            window.location.hostname.match('staging')) {
            return 'https://api.staging.speedio.com.br/api/v3';
        }
        if (window.location.hostname.match('app'))
            return 'https://api.speedio.com.br/api/v3';
    }
    closeModal() {
        this.showDialog = false;
    }
    closeModalExperienceUser() {
        this.showModalExperience = false;
    }
    async showModalExperienceUser() {
        this.showDialog = false;
        //@ts-ignore
        this.showModalExperience = await this.actionsUser.getShowHyperbolic();
    }
    async backToOld() {
        this.showModalNew = false;
        //@ts-ignore
        this.newHyperbolic = false;
        //@ts-ignore
        this.showModalExperience = await this.actionsUser.getShowHyperbolic();
    }
    showFullscreenMode() {
        this.showDialog = true;
        this.showModalNew = true;
    }
    fixedHiperbolic(selected) {
        localStorage.setItem('newHyperbolic', selected);
    }
    newHyperbolicGo() {
        this.newHyperbolic = true;
    }
    data() {
        return {
            // @ts-ignore
            cnpj: this.currentCompany.cnpj.replace(/\D/g, ''),
            // @ts-ignore
            company_name: this.currentCompany.name,
        };
    }
};
__decorate([
    Prop({ default: '' })
], CompanyHyperbolicDataContainer.prototype, "companyName", void 0);
__decorate([
    Prop({ default: false })
], CompanyHyperbolicDataContainer.prototype, "hasHyperbolicData", void 0);
__decorate([
    Watch('showDialog'),
    Watch('openModal')
], CompanyHyperbolicDataContainer.prototype, "onShow", null);
CompanyHyperbolicDataContainer = __decorate([
    Component(connect(({ state: { companies }, actions }) => ({
        actionsUser: actions.users,
        data: companies.hyperbolicData,
        currentCompany: companies.currentCompany,
    }), {
        name: 'CompanyHyperbolicDataContainer',
        components: {
            CompanyDrawerHyperbolicGraph,
            CompanyHyperbolicGraphFullscreen,
            ModalNewHyperbolic,
            ModalExperienceUser,
        },
    }))
], CompanyHyperbolicDataContainer);
export default CompanyHyperbolicDataContainer;
