import { loadStripe } from '@stripe/stripe-js';
function development() {
    return (window.location.hostname.match('dev') ||
        window.location.hostname.match('localhost'));
}
export async function initializeStripe() {
    let key = '';
    if (development()) {
        key =
            'pk_test_51NOLYcGWfTGK8UTRexQKdBmHey1GGnHx3wKtW3A8DgoNrk8IkqETlZOfyoGua0HLfgQiznCrqmBvQpK6SO50wE6i00yJkIjLYt';
    }
    else {
        key =
            'pk_live_51NOLYcGWfTGK8UTR7H42TJPwyaOESz1yeEjBGwdhfvznX5MUUEWj26fETUQKnyrhyRe7xTqERzaj69esCEth2PaS00kItcawdD';
    }
    return (await loadStripe(key));
}
