import { render, staticRenderFns } from "./IconLogoSad.vue?vue&type=template&id=f4b7d656&"
import script from "./IconLogoSad.vue?vue&type=script&lang=ts&"
export * from "./IconLogoSad.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports