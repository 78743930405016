var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import TableBaseComponent from './ComponentsBase/TableBaseComponent.vue';
import IconInstagram from '../Icons/icon-instagram.vue';
import IconFacebook from '../Icons/icon-facebook.vue';
let WebSiteSecondary = class WebSiteSecondary extends Vue {
    constructor() {
        super(...arguments);
        this.headerFacebook = [];
        this.headerInstagram = [];
        this.rowsFacebook = [];
        this.rowsInstagram = [];
        this.iterationFacebook = 0;
        this.iterationInstagram = 0;
    }
    getDataWebsiteSecondary() {
        return this.dataComponent.website_secondary.data;
    }
    buildRowsFacebook() {
        this.iterationFacebook += 1;
        if (this.iterationFacebook > 1)
            return;
        let rows = [];
        for (let i = 0; i < this.dataComponent.website_secondary.data.length; i++) {
            let value = this.dataComponent.website_secondary.data[i];
            let rowFormated = {};
            if (value.type === 'facebook') {
                if (value.category && !this.headerFacebook.includes('Categoria')) {
                    rowFormated['categoria'] =
                        value.category === '·' ? 'Não informado' : value.category;
                    this.headerFacebook.push('Categoria');
                }
                if (value.websites != null && !this.headerFacebook.includes('Site')) {
                    if (value.websites[0].length > 5) {
                        rowFormated['site'] = value.websites.join(', ');
                        this.headerFacebook.push('Site');
                    }
                }
                if (value.url && !this.headerFacebook.includes('Link')) {
                    this.headerFacebook.push('Link');
                    rowFormated['link'] = {
                        type: 'icon-url',
                        value: this.formatUrl(value['url']),
                    };
                }
                if (value.phones && !this.headerFacebook.includes('Telefone')) {
                    let arr_phones = [];
                    value.phones.forEach(tel => {
                        arr_phones.push(this.formatPhone(tel));
                    });
                    rowFormated['telefone'] = arr_phones.join(', ');
                    this.headerFacebook.push('Telefone');
                }
                if (value.likes && !this.headerFacebook.includes('Seguidores')) {
                    rowFormated['seguidores'] = value.likes;
                    this.headerFacebook.push('Seguidores');
                }
                rows.push(rowFormated);
            }
        }
        // @ts-ignore
        this.rowsFacebook = rows;
        return rows;
    }
    buildRowsInstagram() {
        this.iterationInstagram += 1;
        if (this.iterationInstagram > 1)
            return;
        let rows = [];
        this.dataComponent.website_secondary.data.forEach(value => {
            let rowFormated = {};
            if (value.type === 'instagram' &&
                !this.headerInstagram.includes('Site')) {
                if (value.websites) {
                    rowFormated['sites'] = value.websites;
                    this.headerInstagram.push('Site');
                }
                if (value.followers && !this.headerInstagram.includes('Seguidores')) {
                    rowFormated['seguidores'] = value.followers;
                    this.headerInstagram.push('Seguidores');
                }
                if (value.url && !this.headerInstagram.includes('Link')) {
                    this.headerInstagram.push('Link');
                    rowFormated['link'] = {
                        type: 'icon-url',
                        value: this.formatUrl(value.url),
                    };
                }
                if (value.following && !this.headerInstagram.includes('Seguindo')) {
                    rowFormated['seguindo'] = value.following;
                    this.headerInstagram.push('Seguindo');
                }
                rows.push(rowFormated);
            }
        });
        // @ts-ignore
        this.rowsInstagram = rows;
        return rows;
    }
    formatUrl(url) {
        if (url.indexOf('http://') < 0 || url.indexOf('https://') < 0) {
            return 'https://' + url;
        }
        else
            return url;
    }
    formatPhone(number) {
        if (number.length === 10) {
            let cleaned = ('' + number).replace(/\D/g, '');
            let match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
            if (match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3];
            }
        }
        else if (number.length === 11) {
            let cleaned = ('' + number).replace(/\D/g, '');
            let match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
            if (match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3];
            }
        }
        else if (number.length === 8) {
            //Telefone fixo sem ddd
            let cleaned = ('' + number).replace(/\D/g, '');
            let match = cleaned.match(/^(\d{4})(\d{4})$/);
            if (match) {
                return match[1] + '-' + match[2];
            }
        }
        else if (number.length === 9) {
            //Telefone móvel sem ddd
            let cleaned = ('' + number).replace(/\D/g, '');
            let match = cleaned.match(/^(\d{5})(\d{4})$/);
            if (match) {
                return match[1] + '-' + match[2];
            }
        }
        else {
            return number;
        }
    }
    mounted() {
        this.buildRowsFacebook();
        this.buildRowsInstagram();
    }
};
__decorate([
    Prop({ default: {} })
], WebSiteSecondary.prototype, "dataComponent", void 0);
WebSiteSecondary = __decorate([
    Component({
        name: 'WebSiteSecondary',
        components: {
            TableBaseComponent,
            IconInstagram,
            IconFacebook,
        },
    })
], WebSiteSecondary);
export default WebSiteSecondary;
