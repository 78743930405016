var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import TableBaseComponent from './ComponentsBase/TableBaseComponent.vue';
var FluffyFaixaFaturamentoEmpresa;
(function (FluffyFaixaFaturamentoEmpresa) {
    FluffyFaixaFaturamentoEmpresa["The10MA30M"] = "10M a 30M";
    FluffyFaixaFaturamentoEmpresa["The30MA100M"] = "30M a 100M";
    FluffyFaixaFaturamentoEmpresa["The360KA1M"] = "360k a 1M";
})(FluffyFaixaFaturamentoEmpresa || (FluffyFaixaFaturamentoEmpresa = {}));
let RaisHistory = class RaisHistory extends Vue {
    constructor() {
        super(...arguments);
        this.existsGraphic = true;
        this.header = [''];
        this.table = true;
        this.rows_title = {
            faixa_faturamento_empresa: 'Faixa de faturamento da empresa',
            numero_funcionarios_empresa: 'Número de funcionários da empresa',
            numero_funcionarios_unidade_cnpj: 'Número de funcionários da unidade/cnpj',
        };
    }
    mounted() {
        this.buildGraphic();
    }
    buildRows() {
        let rows = [];
        if (this.dataComponent.rais_history.data) {
            this.dataComponent.rais_history.data.forEach(value => {
                if (value.numero_funcionarios_empresa) {
                    let rowFormated = {};
                    rowFormated['titulo'] = this.rows_title.numero_funcionarios_empresa;
                    value.numero_funcionarios_empresa.forEach(row => {
                        let year = Object.keys(row).shift();
                        if (year !== 'last_year') {
                            rowFormated[' ' + year] = this.formatMil(row[year]);
                            if (this.header.indexOf(year) === -1) {
                                this.header.push(year);
                            }
                        }
                    });
                    rows.push(rowFormated);
                }
                if (value.faixa_faturamento_empresa) {
                    let rowFormated = {};
                    rowFormated['titulo'] = this.rows_title.faixa_faturamento_empresa;
                    value.faixa_faturamento_empresa.forEach(row => {
                        let year = Object.keys(row).shift();
                        if (year !== 'last_year') {
                            rowFormated[' ' + year] = row[year];
                            if (this.header.indexOf(year) === -1) {
                                this.header.push(year);
                            }
                        }
                    });
                    rows.push(rowFormated);
                }
            });
        }
        if (this.dataComponent.employees_and_revenues_history.data) {
            this.dataComponent.employees_and_revenues_history.data.forEach(value => {
                if (value.numero_funcionarios_unidade_cnpj) {
                    let rowFormated = {};
                    rowFormated['titulo'] =
                        this.rows_title.numero_funcionarios_unidade_cnpj;
                    value.numero_funcionarios_unidade_cnpj.forEach(row => {
                        let year = Object.keys(row).shift();
                        if (year !== 'last_year') {
                            rowFormated[' ' + year] = this.formatMil(row[year]['employees']);
                            if (this.header.indexOf(year) === -1) {
                                this.header.push(year);
                            }
                        }
                    });
                    rows.push(rowFormated);
                }
            });
        }
        return rows;
    }
    organizeHeader() {
        this.header.sort(function (a, b) {
            if (a > b) {
                return -1;
            }
            if (a < b) {
                return 1;
            }
            // a must be equal to b
            return 0;
        });
        this.header.unshift(' ');
    }
    buildGraphic() {
        let chartOptions = {
            credits: {
                enabled: false,
            },
            yAxis: {
                title: {
                    text: '',
                },
            },
            xAxis: {
                categories: [],
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false,
                    },
                },
            },
            title: {
                text: '',
            },
            series: [],
        };
        if (this.dataComponent.rais_history.data) {
            this.dataComponent.rais_history.data.forEach(value => {
                if (value.numero_funcionarios_empresa) {
                    let rowFormated = {};
                    rowFormated['name'] = 'Número de funcionários da empresa';
                    rowFormated['data'] = [];
                    value.numero_funcionarios_empresa.forEach(row => {
                        let ano = Object.keys(row).shift();
                        if (ano !== 'last_year') {
                            rowFormated['data'].push(row[ano]);
                            chartOptions['xAxis']['categories'].push(Number(ano));
                        }
                    });
                    if (rowFormated['data'].length <= 3) {
                        chartOptions['chart'] = {
                            type: 'column',
                            height: 350,
                            width: 1000,
                        };
                        chartOptions['plotOptions']['column'] = {
                            borderWidth: 0,
                            groupPadding: 0.35,
                            shadow: false,
                        };
                    }
                    rowFormated['data'] = rowFormated['data'].reverse();
                    chartOptions['series'].push(rowFormated);
                }
            });
            if (chartOptions['series'].length === 0) {
                this.existsGraphic = false;
            }
        }
        chartOptions['xAxis']['categories'] =
            chartOptions['xAxis']['categories'].reverse();
        return chartOptions;
    }
    formatMil(number) {
        return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }
};
__decorate([
    Prop({ default: {} })
], RaisHistory.prototype, "dataComponent", void 0);
RaisHistory = __decorate([
    Component({
        name: 'RaisHistory',
        components: {
            TableBaseComponent,
        },
    })
], RaisHistory);
export default RaisHistory;
