var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import TableBaseComponent from './ComponentsBase/TableBaseComponent.vue';
let PgfnDebts = class PgfnDebts extends Vue {
    constructor() {
        super(...arguments);
        this.table = true;
    }
    buildHeader() {
        let header = [''];
        this.dataComponent.pgfn_debts.data.forEach(value => {
            if (value.dividas_totais) {
                header.push('Dívidas totais');
            }
            if (value.demaisdebitos_tributarios) {
                header.push('Demais débitos tributários');
            }
            if (value.previdenciaria) {
                header.push('Previdenciária');
            }
        });
        return header;
    }
    buildRows() {
        let rows = [];
        let rowFormated = { titulo: 'Valor' };
        this.dataComponent.pgfn_debts.data.forEach(value => {
            if (value.dividas_totais) {
                rowFormated['dividas_totais'] = this.formatPrice(value.dividas_totais.valor);
            }
            if (value.demaisdebitos_tributarios) {
                rowFormated['demaisdebitos_tributarios'] = this.formatPrice(value.demaisdebitos_tributarios.valor);
            }
            if (value.previdenciaria) {
                rowFormated['previdenciaria'] = this.formatPrice(value.previdenciaria.valor);
            }
        });
        rows.push(rowFormated);
        return rows;
    }
    buildGraphic() {
        let chartOptions = {
            chart: {
                type: 'column',
                height: 350,
                width: 1000,
                style: {
                    fontFamily: 'Roboto',
                },
            },
            credits: {
                enabled: false,
            },
            subtitle: {
                text: '',
            },
            xAxis: {
                categories: [''],
                visible: false,
            },
            yAxis: {
                title: {
                    text: '',
                },
                stackLabels: {
                    enabled: true,
                    format: 'R${total:,.0f}',
                    style: {
                        fontFamily: 'Roboto',
                    },
                },
                labels: {
                    format: 'R${value:,.0f}',
                    style: {
                        fontFamily: 'Roboto',
                    },
                },
            },
            lang: {
                decimalPoint: ',',
                thousandsSep: '.',
            },
            plotOptions: {
                column: {
                    borderWidth: 0,
                    groupPadding: 0.25,
                    shadow: false,
                    dataLabels: {
                        enabled: true,
                        format: 'R${point.y:,.0f}',
                        style: {
                            fontFamily: 'Roboto',
                        },
                    },
                },
            },
            title: {
                text: '',
            },
            series: [],
        };
        if (this.dataComponent['pgfn_debts']['data']) {
            this.dataComponent['pgfn_debts']['data'].forEach(value => {
                let rowFormated = {};
                rowFormated['name'] = [];
                rowFormated['data'] = [];
                if (value['dividas_totais']) {
                    chartOptions['series'].push(this.buildTotalDebtsData(value));
                }
                if (value['demaisdebitos_tributarios']) {
                    chartOptions['series'].push(this.buildOtherTaxDebtsData(value));
                }
                if (value['previdenciaria']) {
                    chartOptions['series'].push(this.buildSocialSecurityData(value));
                }
            });
            if (chartOptions['series'].length <= 1) {
                chartOptions['chart'] = {
                    type: 'column',
                    height: 350,
                    width: 1000,
                    style: {
                        fontFamily: 'Roboto',
                    },
                };
                chartOptions['plotOptions']['column'] = {
                    borderWidth: 0,
                    groupPadding: 0.41,
                    shadow: false,
                    dataLabels: {
                        enabled: true,
                        format: 'R${point.y:,.0f}',
                        style: {
                            fontFamily: 'Roboto',
                        },
                    },
                };
            }
            else if (chartOptions['series'].length <= 2) {
                chartOptions['chart'] = {
                    type: 'column',
                    height: 350,
                    width: 1000,
                    style: {
                        fontFamily: 'Roboto',
                    },
                };
                chartOptions['plotOptions']['column'] = {
                    borderWidth: 0,
                    groupPadding: 0.35,
                    shadow: false,
                    dataLabels: {
                        enabled: true,
                        format: 'R${point.y:,.0f}',
                        style: {
                            fontFamily: 'Roboto',
                        },
                    },
                };
            }
        }
        return chartOptions;
    }
    buildTotalDebtsData(value) {
        let rowFormated = {};
        rowFormated['name'] = [];
        rowFormated['data'] = [];
        rowFormated['name'].push('Dívidas totais');
        rowFormated['data'].push(Number(value['dividas_totais']['valor']));
        rowFormated['color'] = '#084CBD';
        return rowFormated;
    }
    buildOtherTaxDebtsData(value) {
        let rowFormated = {};
        rowFormated['name'] = [];
        rowFormated['data'] = [];
        rowFormated['name'].push('Demais débitos tributários');
        rowFormated['data'].push(Number(value['demaisdebitos_tributarios']['valor']));
        rowFormated['color'] = '#95A5A6';
        return rowFormated;
    }
    buildSocialSecurityData(value) {
        let rowFormated = {};
        rowFormated['name'] = [];
        rowFormated['data'] = [];
        rowFormated['name'].push('Previdenciária');
        rowFormated['data'].push(Number(value['previdenciaria']['valor']));
        rowFormated['color'] = '#14AAFF';
        return rowFormated;
    }
    formatPrice(price) {
        price = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return 'R$ ' + price;
    }
};
__decorate([
    Prop({ default: {} })
], PgfnDebts.prototype, "dataComponent", void 0);
PgfnDebts = __decorate([
    Component({
        name: 'PgfnDebts',
        components: {
            TableBaseComponent,
        },
    })
], PgfnDebts);
export default PgfnDebts;
