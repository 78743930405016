var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import IconInformation from '@/components/Icons/IconInformation.vue';
import Tooltip from '@/components/Tooltip.vue';
import { formatCurrency, } from '@/shared/utils/helpers';
let PaymentDescription = class PaymentDescription extends Vue {
    formatCurrency(value) {
        return formatCurrency(value);
    }
};
__decorate([
    Prop({
        default: () => {
            return {
                items: [],
                warning: '',
                description: {
                    planName: '',
                    value: '',
                },
                prices: [],
                orderSummary: [],
                planTotal: 0,
            };
        },
    })
], PaymentDescription.prototype, "paymentInfo", void 0);
PaymentDescription = __decorate([
    Component({
        components: {
            IconInformation,
            Tooltip,
        },
    })
], PaymentDescription);
export default PaymentDescription;
