var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import TableBaseComponent from '../AdditionalDataComponents/ComponentsBase/TableBaseComponent.vue';
import MiniCardComponent from '../AdditionalDataComponents/ComponentsBase/MiniCardComponent.vue';
import { formatNumber } from '@/shared/utils/helpers.ts';
let CompanyGrowth = class CompanyGrowth extends Vue {
    constructor() {
        super(...arguments);
        this.model = 'Atual';
        this.options = ['Atual'];
        this.table = true;
        this.headers = {
            departments: ['Nome', 'Total de vagas', 'Tipo de contratação'],
            published_platform: ['Nome', 'Total'],
        };
    }
    formatNumeric(value) {
        return formatNumber(value);
    }
    getLastData() {
        return this.dataComponent.company_jobs.data[0].last_month;
    }
    getMonths() {
        return this.dataComponent.company_jobs.data[0].months;
    }
    buildMinicard() {
        if (this.model == 'Atual') {
            return this.getLastData();
        }
        else {
            let data = null;
            this.getMonths().forEach(value => {
                if (value['month_reference'].includes(this.model)) {
                    data = value;
                }
            });
            return data;
        }
    }
    buildRowsFake() {
        let rows = [];
        for (let index = 0; index < 5; index++) {
            rows.push({ '1': '******', '2': '****', '3': '****' });
        }
        return rows;
    }
    buildRows(type) {
        let rows = [];
        if (this.model == 'Atual') {
            rows = this.processData(type, this.getLastData());
        }
        else {
            this.getMonths().forEach(value => {
                if (value['month_reference'].includes(this.model)) {
                    rows = this.processData(type, value);
                }
            });
        }
        return rows;
    }
    processData(type, data) {
        let rows = [];
        if (type == 'departments' && data['departments']) {
            data['departments'].forEach(departament => {
                let rowFormated = {};
                rowFormated['nome'] = departament['name'];
                rowFormated['total_vagas'] = this.formatNumeric(departament['open_positions']);
                rowFormated['tipo_contratacao'] = 'Não Disponivel';
                if (departament['type_of_contract'].length > 1) {
                    rowFormated['tipo_contratacao'] =
                        departament['type_of_contract'].join(' e ');
                }
                else if (departament['type_of_contract'].length == 1) {
                    rowFormated['tipo_contratacao'] = departament['type_of_contract'][0];
                }
                rows.push(rowFormated);
            });
        }
        if (type == 'published_platform' && data['published_platform']) {
            Object.keys(data['published_platform']).forEach(platform_name => {
                let rowFormated = {};
                rowFormated['nome'] = platform_name;
                rowFormated['total'] = data['published_platform'][platform_name];
                rows.push(rowFormated);
            });
        }
        return rows;
    }
    buildOptions() {
        let months = this.getMonths()
            .sort((a, b) => a.month_reference.slice(0, 7) - b.month_reference.slice(0, 7))
            .reverse();
        months.forEach(item => {
            if (item) {
                this.options.push(item['month_reference'].slice(0, 7));
            }
        });
    }
    mounted() {
        this.buildOptions();
    }
};
__decorate([
    Prop({ default: {} })
], CompanyGrowth.prototype, "dataComponent", void 0);
CompanyGrowth = __decorate([
    Component({
        name: 'CompanyDrawerAdditionalDataTab',
        components: {
            TableBaseComponent,
            MiniCardComponent,
        },
    })
], CompanyGrowth);
export default CompanyGrowth;
