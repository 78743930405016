var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Modal from 'components/Modal.vue';
import TextClickToCopy from './TextClickToCopy.vue';
import { getApiUrl, showNotify } from '@/shared/utils/helpers';
let CompanyDrawerRegisterTab = class CompanyDrawerRegisterTab extends Vue {
    constructor() {
        super(...arguments);
        this.showCnpjModal = false;
        this.showCnaesModal = false;
        this.loadingCopy = false;
        this.currentCnpjsPage = 1;
        this.loadingCompanyCnpjs = false;
    }
    onCompanyChange(_newCompany) {
        this.loadingCompanyCnpjs = false;
    }
    async copyAllCNPJs() {
        this.loadingCopy = true;
        try {
            let cnpjs;
            if (this.company.totalCnpjs <= 10) {
                cnpjs = this.company.cnpjsGroup || [];
            }
            else {
                const base_api = getApiUrl('v4');
                const company_cnpj = this.company.cnpj.replace(/\D/g, '');
                const res = await Vue.axios.get(`${base_api}/companies/${company_cnpj}/cnpjs_group?all=true`);
                cnpjs = res.data || [];
            }
            const cnpjsString = cnpjs.join(' ');
            await navigator.clipboard.writeText(cnpjsString);
        }
        catch (e) {
            console.log(e);
            showNotify('Ocorreu um erro ao tentar copiar todos os cnpjs', 'error');
        }
        this.loadingCopy = false;
    }
    handleMatrizOrFilial() {
        return this.company.matriz === '1'
            ? 'Matriz'
            : this.company.matriz === '2'
                ? 'Filial'
                : '----';
    }
    handleSituationSimple(situation) {
        if (situation === 'S')
            return 'Sim';
        return 'Não';
    }
    get insideSearch() {
        return this.$route.path.match(/search/);
    }
    searchCompanyCNAE(activity, type, label) {
        return { activity, type, label };
    }
    searchCompanyCNPJ(cnpj) {
        return cnpj;
    }
    getCnpjsGroup(cnpjsGroup) {
        const cnpjs = cnpjsGroup.filter(i => i !== '');
        if (cnpjs) {
            if (cnpjs.length > 5) {
                return cnpjsGroup.slice(0, 5);
            }
            else {
                if (cnpjsGroup.length > 0) {
                    return cnpjs;
                }
            }
        }
        return [];
    }
    getTotalCnaes(cnaes) {
        return cnaes.split(';').filter(i => i !== '');
    }
    handleCnaes(cnaes) {
        const list = cnaes.split(';').filter(i => i !== '');
        if (list && list.length > 3) {
            return list.slice(0, 3);
        }
        else if (list.length > 0) {
            return list;
        }
        return [];
    }
    getNextCnpjsPage(data) {
        if (data.direction === 'increase') {
            if (data.index === data.to) {
                this.loadingCompanyCnpjs = true;
                this.currentCnpjsPage += 1;
                this.$emit('getCompanyCnpjsNextPage', this.currentCnpjsPage);
            }
        }
    }
};
__decorate([
    Prop({ default: () => { } })
], CompanyDrawerRegisterTab.prototype, "company", void 0);
__decorate([
    Watch('company')
], CompanyDrawerRegisterTab.prototype, "onCompanyChange", null);
__decorate([
    Emit('searchCompanyCNAE')
], CompanyDrawerRegisterTab.prototype, "searchCompanyCNAE", null);
__decorate([
    Emit('searchCompanyCNPJ')
], CompanyDrawerRegisterTab.prototype, "searchCompanyCNPJ", null);
CompanyDrawerRegisterTab = __decorate([
    Component({
        name: 'CompanyDrawerRegisterTab',
        components: {
            Modal,
            TextClickToCopy,
        },
    })
], CompanyDrawerRegisterTab);
export default CompanyDrawerRegisterTab;
