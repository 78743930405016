import { render, staticRenderFns } from "./TextClickToCopy.vue?vue&type=template&id=a6b3b0d6&"
import script from "./TextClickToCopy.vue?vue&type=script&lang=ts&"
export * from "./TextClickToCopy.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports