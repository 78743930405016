var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { getAppExchangeBaseUrl, getApiUrl } from '@/shared/utils/helpers';
import { CompanyStatus } from '@/overmind/search/state';
import { mixpanelTracking } from '@/services/mixpanel';
import myNotes from '../components/CompanyDraewerApps/myNotes.vue';
import deleteNotesModal from '../components/CompanyDraewerApps/deleteNotesModal.vue';
import Tootltip from '@/components/Tooltip.vue';
import { overmind } from '@/overmind';
let CompanyDrawerApplets = class CompanyDrawerApplets extends Vue {
    constructor() {
        super(...arguments);
        this.baseUrl = '';
        this.showApplets = true;
        this.showAnotationApp = false;
        this.showRecordApp = false;
        this.showDecisores = false;
        this.showExternalApp = false;
        this.showCalendarApp = false;
        this.showWhatsapp = false;
        this.loadingApp = false;
        this.urlBaseDecisores = `${getAppExchangeBaseUrl()}/app-decisores`;
        this.urlBaseAnotation = `${getAppExchangeBaseUrl()}/app-annotation`;
        this.urlBaseRecord = `${getAppExchangeBaseUrl()}/app-gravacao`;
        this.urlBaseCalendar = 'https://app-exchange.app.speedio.com.br/app-calendario';
        this.urlBaseWhatsapp = `${getAppExchangeBaseUrl()}/app-whatsapp`;
        this.token = '';
        this.urlAnotation = '';
        this.urlRecord = '';
        this.urlCalendar = '';
        this.urlDecisors = '';
        this.urlWhatsapp = '';
        this.loadingComponent = false;
        this.appnnAnotation = { icon: 'icon-annotations', label: 'Anotações' };
        this.noHaveNotes = true;
        this.createNotes = false;
        this.msg = '';
        this.text = '';
        this.inputValue = '';
        this.selectedTags = [];
        this.potential = '';
        this.operation = 'date-notification';
        this.noteDeleted = {};
        this.notificationDate = '';
        this.showModalDeleteNotes = false;
        this.screen = '';
        this.notes = [];
        this.handleSave = true;
        this.companyApplets = [
            { icon: 'icon-calendar', label: 'Calendário' },
            { icon: 'icon-recordings', label: 'Registro' },
            { icon: 'icon-add-user', label: 'Mais Decisores' },
            { icon: 'icon-whatsapp', label: 'Whatsapp' },
            { icon: 'icon-reminder', label: 'Lembrete' },
            { icon: 'icon-dropbox', label: 'Dropbox' },
            { icon: 'icon-serasa', label: 'Serasa' },
            { icon: 'icon-mapas', label: 'Mapas' },
            { icon: 'icon-cheque', label: 'AutoEmail' },
            { icon: 'icon-diagram', label: 'ConexõesX' },
            { icon: 'icon-prueba', label: 'Qualificar+' },
        ];
        this.availableCategories = [];
        this.selectedCategories = [];
        this.categoriesName = [];
        this.showDrawerApplets = false;
        this.editDisable = false;
        this.tags = [];
    }
    get viewAs() {
        const viewAs = this.$route.query.view_as;
        return viewAs ? `&viewAs=${this.$route.query.view_as}` : '';
    }
    /*eslint-disable */
    get phoneContact() {
        let data = '';
        if (this.additionalContactDataPhonesIsPresent()) {
            //@ts-ignore
            this.company.additionalContactData.phones.map(phones => {
                let temporal = phones.phone;
                data = `${data} + ${temporal}/`;
            });
            return data;
        }
        else {
            return data;
        }
    }
    get whatsappPhone() {
        if (!this.additionalContactDataPhonesIsPresent())
            return '';
        const validator = (phoneData) => {
            if (phoneData.phone)
                if (phoneData.validations)
                    if (phoneData.validations.whatsapp)
                        if (phoneData.validations.whatsapp.result)
                            if (phoneData.validations.whatsapp.result === 'valid')
                                return phoneData.phone;
        };
        return (this.additionalContactDataPhonesIsPresent() &&
            //@ts-ignore
            this.company.additionalContactData.phones
                .filter(validator)
                .map(e => e.phone));
    }
    addNewCategories(value) {
        if (value && !this.availableCategories.includes(value)) {
            this.availableCategories.push(value);
        }
    }
    creatNewNote() {
        this.editDisable = false;
        this.createNotes = true;
        this.noHaveNotes = false;
        this.whichScreen(this.noHaveNotes, this.createNotes);
    }
    handleBtnSaveNote() {
        let saveTags = [];
        if (this.categoriesName) {
            this.categoriesName.forEach((t, index) => {
                if (this.tags[index]) {
                    // @ts-ignore
                    saveTags.push(t);
                }
            });
        }
        if (this.msg && this.potential && saveTags && this.notificationDate) {
            return false;
        }
        else {
            return true;
        }
    }
    goTo(type) {
        if (type == 'management') {
            window.open('/shop/config/anotation-app', '_blank');
        }
        else if (type == 'video') {
            window.open('https://www.loom.com/share/c5e1ad496e29484baa578dfd89fb775c?sid=30ddbd07-b726-4433-929c-313a4388c055', '_blank');
        }
    }
    async saveNote() {
        this.loadingApp = true;
        this.screen = '';
        const data = {
            company_id: this.companyId,
            note: this.msg,
            reminder: this.notificationDate,
            categories: this.selectedCategories,
            business_potential: this.potential,
        };
        this.msg = '';
        this.potential = '';
        this.categoriesName = [];
        this.notificationDate = '';
        //@ts-ignore
        await overmind.actions.users.createNewNote(data);
        //@ts-ignore
        const allNotes = await overmind.actions.users.getAllNotes(this.companyId);
        this.notes = [];
        allNotes.data.value.forEach(n => {
            //@ts-ignore
            this.notes.push(n);
        });
        this.loadingApp = false;
        this.createNotes = false;
        this.noHaveNotes = false;
        this.whichScreen(this.noHaveNotes, this.createNotes);
    }
    async backScreen() {
        this.loadingApp = true;
        this.screen = '';
        //@ts-ignore
        const allNotes = await overmind.actions.users.getAllNotes(this.companyId);
        this.notes = [];
        allNotes.data.value.forEach(n => {
            //@ts-ignore
            this.notes.push(n);
        });
        if (this.notes.length > 0) {
            this.noHaveNotes = false;
        }
        else {
            this.noHaveNotes = true;
        }
        this.msg = '';
        this.potential = '';
        this.categoriesName = [];
        this.notificationDate = '';
        this.loadingApp = false;
        this.whichScreen(this.noHaveNotes, false);
    }
    whichScreen(noHaveNotes, createNotes) {
        if (noHaveNotes == true && createNotes == false) {
            this.screen = 'noHaveNotes';
        }
        else if (createNotes == true) {
            this.screen = 'createNote';
        }
        else if (noHaveNotes == false && createNotes == false) {
            this.screen = 'myNotes';
        }
    }
    async removeNewnote() {
        this.noteDeleted = {};
        this.showModalDeleteNotes = true;
    }
    exibeNote(note) {
        this.msg = note.note;
        this.potential = 'R$' + note.business_potential;
        this.selectedCategories = note.categories;
        this.notificationDate = new Date(note.reminder).toLocaleDateString('pt-BR');
        this.createNotes = true;
        this.whichScreen(false, this.createNotes);
        this.editDisable = true;
    }
    companyHasWhatsappValidPhones(label) {
        if (label === 'Whatsapp')
            return (this.additionalContactDataPhonesIsPresent() &&
                //@ts-ignore
                this.company.additionalContactData.phones.some(res => res.validations &&
                    res.validations.whatsapp &&
                    res.validations.whatsapp.result === 'valid'));
    }
    additionalContactDataPhonesIsPresent() {
        return (this.company &&
            this.company.additionalContactData &&
            this.company.additionalContactData.phones);
    }
    mounted() {
        this.msg = '';
        this.potential = '';
        this.selectedCategories = [];
        this.notificationDate = '';
        this.availableCategories = [];
        this.getCategories();
    }
    async getCategories() {
        const result = await overmind.actions.companies.getAnnotationCategories();
        if (result && result.length) {
            this.availableCategories = result;
        }
    }
    async created() {
        this.token = localStorage.getItem('auth_token_default');
        this.baseUrl = getAppExchangeBaseUrl();
    }
    async confirmRemove() {
        this.loadingApp = true;
        this.screen = '';
        //@ts-ignore
        const allNotes = await overmind.actions.users.getAllNotes(this.companyId);
        this.notes = [];
        allNotes.data.value.forEach(n => {
            //@ts-ignore
            this.notes.push(n);
        });
        this.msg = '';
        this.potential = '';
        this.categoriesName = [];
        this.notificationDate = '';
        if (this.notes.length) {
            this.noHaveNotes = false;
        }
        else {
            this.noHaveNotes = true;
        }
        this.loadingApp = false;
        this.whichScreen(this.noHaveNotes, false);
    }
    onCompanyStatus(currVal) {
        if (currVal === CompanyStatus.FETCH_SUCCESS ||
            currVal === CompanyStatus.INITIAL)
            this.showDrawerApplets = true;
        else
            this.showDrawerApplets = false;
    }
    get companyName() {
        return this.company.fantasyName
            ? this.company.fantasyName
            : this.company.name;
    }
    // tracking
    handleMixpanelTracking(text) {
        return mixpanelTracking(text);
    }
    // end
    async showAnotation() {
        this.loadedApp();
        this.showAnotationApp = true;
        this.screen = '';
        this.showExternalApp = true;
        this.showWhatsapp = false;
        this.loadingApp = true;
        //@ts-ignore
        const allNotes = await overmind.actions.users.getAllNotes(this.companyId);
        this.loadingApp = false;
        if (allNotes.data.value.length) {
            this.noHaveNotes = false;
            this.notes = [];
            allNotes.data.value.forEach(n => {
                //@ts-ignore
                this.notes.push(n);
            });
        }
        else {
            this.noHaveNotes = true;
        }
        this.whichScreen(this.noHaveNotes, false);
    }
    removeNote(note) {
        this.noteDeleted = note;
        this.showModalDeleteNotes = true;
    }
    showApp(item) {
        this.handleMixpanelTracking(`pre-crm: Abriu o app ${item.label}`);
        setTimeout(() => {
            this.loadingApp = true;
        }, 10);
        if (item.label === 'Anotações') {
            this.showAnotationApp = true;
            this.showExternalApp = true;
            this.showWhatsapp = false;
            this.urlAnotation = `${this.urlBaseAnotation}?token=${this.token}&companyId=${this.companyId}&infosetId=${this.infosetId}${this.viewAs}`;
            this.loadedApp();
        }
        else if (item.label === 'Registro') {
            //this.loadApp();
            this.showAnotationApp = false;
            this.showCalendarApp = false;
            this.showDecisores = false;
            this.showRecordApp = true;
            this.showExternalApp = true;
            this.showWhatsapp = false;
            this.urlRecord = `${this.urlBaseRecord}?token=${this.token}&companyId=${this.companyId}&infosetId=${this.infosetId}&api=${getApiUrl()}${this.viewAs}`;
            navigator.mediaDevices
                .getUserMedia({ audio: true, video: false })
                .then(stream => {
                stream;
            });
            this.loadedApp();
        }
        else if (item.label === 'Mais Decisores') {
            this.showDecisores = true;
            this.showExternalApp = true;
            this.showAnotationApp = false;
            this.showRecordApp = false;
            this.showCalendarApp = false;
            this.showWhatsapp = false;
            this.urlDecisors = `${this.baseUrl}/app-decisores?token=${this.token}&companyId=${this.companyId}&infosetId=${this.infosetId}`;
            this.loadedApp();
        }
        else if (item.label === 'Calendário') {
            this.showAnotationApp = false;
            this.showRecordApp = false;
            this.showCalendarApp = true;
            this.showExternalApp = true;
            this.showWhatsapp = false;
            this.urlCalendar = `${this.urlBaseCalendar}?companyName=${this.company.name}&infosetId=${this.infosetId}&urlCompany=${this.company.webSearch.website || ''}&cnpj=${this.company.cnpj}&address=${(this.company &&
                this.company.additionalContactData &&
                this.company.additionalContactData.addresses[0].address) ||
                ''}&phones=${this.phoneContact}${this.viewAs}`;
            this.loadedApp();
        }
        else if (item.label === 'Whatsapp') {
            this.showAnotationApp = false;
            this.showRecordApp = false;
            this.showCalendarApp = false;
            this.showWhatsapp = true;
            this.showExternalApp = true;
            this.urlWhatsapp = `${this.urlBaseWhatsapp}?phones=${this.whatsappPhone}&nameFantasy=${this.companyName}&namePerson=${this.company.name}&administratorName=${this.company.administratorData.name}${this.viewAs}`;
            this.loadedApp();
        }
        else {
            return false;
        }
    }
    loadedApp() {
        setTimeout(() => {
            this.loadingApp = false;
        }, 1000);
    }
    closeApp() {
        this.msg = '';
        this.potential = '';
        this.categoriesName = [];
        this.notificationDate = '';
        this.showAnotationApp = false;
        this.showExternalApp = false;
        this.showRecordApp = false;
        this.showCalendarApp = false;
        this.showWhatsapp = false;
        this.showDecisores = false;
    }
    dateExpiresDisabledBeforeToday(date) {
        const today = new Date();
        return date < today;
    }
    setcategoriesName({ value }, type) {
        if (type == 'remove') {
            //@ts-ignore
            const indice = this.selectedCategories.indexOf(value[0]);
            if (indice !== -1) {
                this.selectedCategories.splice(indice, 1);
            }
        }
        else {
            //@ts-ignore
            this.selectedCategories.push(value);
        }
        return { value, type };
    }
};
__decorate([
    Prop({ default: '' })
], CompanyDrawerApplets.prototype, "infosetId", void 0);
__decorate([
    Prop({ default: '' })
], CompanyDrawerApplets.prototype, "companyId", void 0);
__decorate([
    Prop({ default: () => { } })
], CompanyDrawerApplets.prototype, "company", void 0);
__decorate([
    Prop({ default: false })
], CompanyDrawerApplets.prototype, "disableApps", void 0);
__decorate([
    Prop({ default: CompanyStatus.INITIAL })
], CompanyDrawerApplets.prototype, "companyStatus", void 0);
__decorate([
    Watch('companyStatus', { immediate: true })
], CompanyDrawerApplets.prototype, "onCompanyStatus", null);
CompanyDrawerApplets = __decorate([
    Component({
        name: 'CompanyDrawerApplets',
        components: {
            myNotes,
            deleteNotesModal,
            Tootltip,
        },
    })
], CompanyDrawerApplets);
export default CompanyDrawerApplets;
