import { render, staticRenderFns } from "./CompanyFinancialMetrics.vue?vue&type=template&id=035aee04&scoped=true&"
import script from "./CompanyFinancialMetrics.vue?vue&type=script&lang=ts&"
export * from "./CompanyFinancialMetrics.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "035aee04",
  null
  
)

export default component.exports