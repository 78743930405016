var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit, PropSync } from 'vue-property-decorator';
let Modal = class Modal extends Vue {
    close() {
        return false;
    }
    hideRightDrawer() {
        return true;
    }
};
__decorate([
    Prop({ default: 'hHh lpR fFf' })
], Modal.prototype, "layout", void 0);
__decorate([
    PropSync('open', { default: false })
], Modal.prototype, "show", void 0);
__decorate([
    Prop({ default: '' })
], Modal.prototype, "title", void 0);
__decorate([
    Prop({ default: false })
], Modal.prototype, "leftDrawer", void 0);
__decorate([
    Prop({ default: false })
], Modal.prototype, "rightDrawer", void 0);
__decorate([
    Prop({ default: 600 })
], Modal.prototype, "width", void 0);
__decorate([
    Prop({ default: 500 })
], Modal.prototype, "height", void 0);
__decorate([
    Prop({ default: 200 })
], Modal.prototype, "rightDrawerWidth", void 0);
__decorate([
    Prop({ default: false })
], Modal.prototype, "noHeader", void 0);
__decorate([
    Prop({ default: false })
], Modal.prototype, "noFooter", void 0);
__decorate([
    Prop({ default: () => ({}) })
], Modal.prototype, "confirm", void 0);
__decorate([
    Prop({ default: '' })
], Modal.prototype, "subTitle", void 0);
__decorate([
    Prop({ default: true })
], Modal.prototype, "showBorderHeader", void 0);
__decorate([
    Emit('onClose')
], Modal.prototype, "close", null);
__decorate([
    Emit('hideRightDrawer')
], Modal.prototype, "hideRightDrawer", null);
Modal = __decorate([
    Component({
        name: 'Modal',
    })
], Modal);
export default Modal;
