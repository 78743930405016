var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import DotsIcon from '@/modules/upgradeplans/icons/DotsIcon.vue';
import CancelPlanModal from '@/modules/upgradeplans/CancelPlanModal.vue';
import { mixpanelTracking } from '@/services/mixpanel';
import axios from 'axios';
Vue.prototype.$axios = axios.create();
let ChangePlanBtn = class ChangePlanBtn extends Vue {
    constructor() {
        super(...arguments);
        this.showOptions = false;
        this.showCancelPlanModal = false;
    }
    handleShowOptions() {
        this.showOptions = !this.showOptions;
    }
    handlePlan(plan) {
        return plan == 'Free' ? 'Teste gratuito' : plan;
    }
    handleCancelPlanModal() {
        mixpanelTracking("upgrade 2.0: 'Cancelar plano' foi selecionado");
        this.showCancelPlanModal = true;
    }
    handleEditPlan() {
        mixpanelTracking("upgrade 2.0: 'Editar plano' foi selecionado");
        this.$router.push('/upgrade_plans');
    }
};
__decorate([
    Prop({ default: '' })
], ChangePlanBtn.prototype, "currentPlan", void 0);
__decorate([
    Prop({ default: false })
], ChangePlanBtn.prototype, "showEditPlan", void 0);
ChangePlanBtn = __decorate([
    Component({
        name: 'ChangePlanBtn',
        components: {
            DotsIcon,
            CancelPlanModal,
        },
    })
], ChangePlanBtn);
export default ChangePlanBtn;
