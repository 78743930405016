var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import IconVisa from './CreditCardFlags/IconVisa.vue';
import IconMasterCard from './CreditCardFlags/IconMasterCard.vue';
import IconAmex from './CreditCardFlags/IconAmex.vue';
import IconElo from './CreditCardFlags/IconElo.vue';
import IconDiners from './CreditCardFlags/IconDiners.vue';
import IconAura from './CreditCardFlags/IconAura.vue';
let IconCreditCardManager = class IconCreditCardManager extends Vue {
    constructor() {
        super(...arguments);
        this.flagList = [
            'visa',
            'unionPay',
            'mastercard',
            'jcb',
            'hipercard',
            'enroute',
            'elo',
            'discover',
            'diners',
            'aura',
            'amex',
        ];
    }
};
__decorate([
    Prop({ default: 'visa' })
], IconCreditCardManager.prototype, "flag", void 0);
IconCreditCardManager = __decorate([
    Component({
        components: {
            visa: IconVisa,
            mastercard: IconMasterCard,
            amex: IconAmex,
            elo: IconElo,
            diners: IconDiners,
            aura: IconAura,
        },
    })
], IconCreditCardManager);
export default IconCreditCardManager;
