var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
let Alert = class Alert extends Vue {
    handleDialogMaxWidth() {
        return `max-width: ${this.maxWidth}`;
    }
};
__decorate([
    PropSync('open', { default: false })
], Alert.prototype, "openDialog", void 0);
__decorate([
    Prop({ default: '' })
], Alert.prototype, "warningMessage", void 0);
__decorate([
    Prop({ default: 'font-16' })
], Alert.prototype, "fontSize", void 0);
__decorate([
    Prop({ default: '520px' })
], Alert.prototype, "maxWidth", void 0);
Alert = __decorate([
    Component({
        name: 'Alert',
    })
], Alert);
export default Alert;
