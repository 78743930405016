var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as d3 from 'd3';
import { json } from 'overmind';
import { isEmpty } from 'lodash';
let CompanyHyperbolicGraphFullscreen = class CompanyHyperbolicGraphFullscreen extends Vue {
    hTree(nodes, links) {
        // set the dimensions and margins of the graph
        var margin = { top: 0, right: 0, bottom: 0, left: 0 };
        // width = 960 - margin.left - margin.right,
        // height = 600 - margin.top - margin.bottom;
        const screenWidth = this.$q.screen.width - this.$q.screen.width * 0.1;
        const screenHeight = this.$q.screen.height - this.$q.screen.height * 0.1;
        var colors = d3.scaleOrdinal(d3.schemeCategory10);
        var link, node, edgepaths, edgelabels;
        //const zoom = d3.zoom().scaleExtent([1, 10]).on('zoom', zoomed);
        // append the svg object to the body of the page
        var svg = d3
            .select('#svg-graph-full')
            //.append('svg')
            .attr('width', this.$q.screen.width - this.$q.screen.width * 0.1)
            .attr('height', this.$q.screen.height - this.$q.screen.height * 0.1);
        //const g = svg.append('g');
        //svg.call(zoom);
        let width = d3.select('svg').style('width');
        let height = d3.select('svg').style('height');
        width = width.substring(0, width.length - 2);
        height = height.substring(0, height.length - 2);
        //svg.attr('viewBox', `0 0 900 900`);
        //.attr('align', 'center')
        //.append('g')
        //.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
        svg
            .append('defs')
            .append('marker')
            .attr('id', 'arrowhead')
            .attr('viewBox', `${-screenWidth / 2} ${-screenHeight / 2} ${screenWidth * 2} ${screenHeight * 2}`)
            .attr('refX', 13)
            .attr('refY', 0)
            .attr('orient', 'auto')
            .attr('markerWidth', 13)
            .attr('markerHeight', 13)
            .attr('xoverflow', 'visible')
            .append('svg:path')
            .attr('d', 'M 0,-5 L 10 ,0 L 0,5')
            .attr('fill', '#999')
            .style('stroke', 'none')
            .call(d3.zoom().on('zoom', function () {
            // svg.attr('transform', d3.event.transform);
        }));
        // Let's list the force we wanna apply on the network
        var simulation = d3
            .forceSimulation() // Force algorithm is applied to data.nodes
            .force('link', d3
            .forceLink() // This force provides links between nodes
            .id(function (d, i) {
            return d.id;
        }) // This provide  the id of a node
            //.links(links) // and this the list of links
            .distance(200))
            .force('charge', d3.forceManyBody().strength(-450)) // This adds repulsion between nodes. Play with the -400 for the repulsion strength
            .force('center', d3.forceCenter(screenWidth / 2, screenHeight / 2)) // This force attracts nodes to the center of the svg area
            .force('collide', d3.forceCollide(50));
        // render
        function render() {
            // Initialize the links
            link = svg
                .selectAll('.link')
                .data(links)
                .enter()
                .append('line')
                .attr('class', 'link')
                .attr('marker-end', 'url(#arrowhead)')
                .style('stroke', '#aaa');
            link.append('title').text(function (d) {
                return d.label;
            });
            edgepaths = svg
                .selectAll('.edgepath')
                .data(links)
                .enter()
                .append('path')
                .attr('class', 'edgepath')
                .attr('fill-opacity', 0)
                .attr('stroke-opacity', 0)
                .attr('id', function (d, i) {
                return 'edgepath' + i;
            });
            edgelabels = svg
                .selectAll('.edgelabel')
                .data(links)
                .enter()
                .append('text')
                .style('pointer-events', 'none')
                .attr('class', 'edgelabel')
                .attr('id', function (d, i) {
                return 'edgelabel' + i;
            })
                .attr('font-size', 12)
                .attr('fill', '#b8b8b8');
            edgelabels
                .append('textPath')
                .attr('xlink:href', function (d, i) {
                return '#edgepath' + i;
            })
                .style('text-anchor', 'middle')
                .style('pointer-events', 'none')
                .attr('startOffset', '50%')
                .text(function (d) {
                return d.label;
            });
            // Initialize the nodes
            node = svg
                .selectAll('.node')
                .data(nodes)
                .enter()
                .append('g')
                .attr('class', 'node')
                .style('fill', '#69b3a2')
                .call(d3
                .drag()
                .on('start', dragstarted)
                .on('drag', dragged)
                .on('end', dragended));
            node
                .append('circle')
                .attr('r', 20)
                .style('fill', function (d, i) {
                return colors(i);
            })
                .append('svg:foreignObject')
                .attr('height', '15px')
                .attr('width', '15px');
            /*.html(function (d, i) {
                if (d.iconType === 'cpf') {
                  var color = colors(i);
                  return '<i aria-hidden="true" role="presentation" class="material-icons q-icon notranslate" style="font-size: 15px;">home_work</i>';
                }
              });*/
            var labels = node
                .append('text')
                .text(function (d) {
                return d.name;
            })
                .attr('dx', 0)
                .attr('dy', -25);
            node.append('title').text(function (d) {
                if (d.cnpj)
                    return `${d.name}\n${d.cnpj}`;
                else
                    return d.name;
            });
            node.on('mouseover', function (event, d) {
                // @ts-ignore
                d3.select(this)
                    .select('circle')
                    .transition()
                    .duration(100)
                    .attr('r', function (event, d) {
                    return 32;
                });
                // @ts-ignore
                d3.select(this).select('image').attr('width', '32px');
                // @ts-ignore
                d3.select(this).select('image').attr('height', '32px');
                // @ts-ignore
                d3.select(this).select('image').attr('x', '-16px');
                // @ts-ignore
                d3.select(this).select('image').attr('y', '-16px');
                node.style('fill', '#B8B8B8');
                // @ts-ignore
                d3.select(this).attr('stroke', 'black');
                // @ts-ignore
                d3.select(this).style('fill', '#000');
                // @ts-ignore
                d3.select(this).style('font-weight', 'bolder');
                // @ts-ignore
                d3.select(this).style('font-size', '18px');
                // @ts-ignore
                d3.select(this).style('cursor', 'pointer');
                // @ts-ignore
                d3.select(this).style('z-index', '10000');
                // @ts-ignore
                d3.select(this).raise();
                link
                    .style('stroke', function (link_d) {
                    return link_d.source.id === d.id || link_d.target.id === d.id
                        ? '#69b3b2'
                        : '#b8b8b8';
                })
                    .style('stroke-width', function (link_d) {
                    return link_d.source.id === d.id || link_d.target.id === d.id
                        ? 4
                        : 1;
                });
                edgelabels
                    .style('fill', function (edge_d) {
                    return edge_d.source.id === d.id || edge_d.target.id === d.id
                        ? '#000'
                        : 'transparent';
                })
                    .style('font-size', function (edge_d) {
                    return edge_d.source.id === d.id || edge_d.target.id === d.id
                        ? '14px'
                        : '12px';
                })
                    .raise(function (edge_d) {
                    return edge_d.source.id === d.id || edge_d.target.id === d.id
                        ? true
                        : false;
                });
            });
            node.on('mouseout', function (event, d) {
                // @ts-ignore
                d3.select(this)
                    .select('circle')
                    .transition()
                    .duration(100)
                    .attr('r', function (event, d) {
                    return 20;
                });
                // @ts-ignore
                d3.select(this).select('image').attr('width', '16px');
                // @ts-ignore
                d3.select(this).select('image').attr('height', '16px');
                // @ts-ignore
                d3.select(this).select('image').attr('x', '-8px');
                // @ts-ignore
                d3.select(this).select('image').attr('y', '-8px');
                node.style('fill', '#ccc');
                // @ts-ignore
                d3.select(this).attr('stroke', null);
                // @ts-ignore
                d3.select(this).style('fill', '#69b3b2');
                // @ts-ignore
                d3.select(this).style('font-weight', 'normal');
                // @ts-ignore
                d3.select(this).style('font-size', '14px');
                link
                    .style('stroke', function (link_d) {
                    return '#ccc';
                })
                    .style('stroke-width', function (link_d) {
                    return 1;
                });
                edgelabels
                    .style('fill', function (edge_d) {
                    return '#ccc';
                })
                    .style('font-size', function (edge_d) {
                    return '12px';
                })
                    .lower();
            });
            node
                .append('svg:image')
                .attr('class', 'circle')
                .attr('xlink:href', function (d) {
                if (d.iconType === 'cpf') {
                    return 'https://img.icons8.com/user';
                }
                else {
                    return 'https://img.icons8.com/company';
                }
            })
                .attr('x', '-8px')
                .attr('y', '-8px')
                .attr('width', '16px')
                .attr('height', '16px');
            link
                .append('text')
                .text(function (d) {
                return d.label;
            })
                .style('fill', '#ccc')
                .style('font-size', '12px')
                .attr('x', 560)
                .attr('y', 900);
            simulation.nodes(nodes).on('tick', ticked);
            simulation.force('link').links(links);
        } // end of render()
        /*
          TODO: Study a manner to update the correct positions for all elements when zooming in and zooming out
        function zoomed(event, d) {
          d3.selectAll('path') // To prevent stroke width from scaling
            .attr('transform', event.transform);
    
          d3.selectAll('circle') // To prevent stroke width from scaling
            .attr('transform', event.transform);
    
          d3.selectAll('line') // To prevent stroke width from scaling
            .attr('transform', event.transform);
    
          d3.selectAll('edgepath') // To prevent stroke width from scaling
            .attr('transform', event.transform);
          d3.selectAll('.edgelabel') // To prevent stroke width from scaling
            .attr('transform', event.transform);
    
          d3.selectAll('text') // To prevent stroke width from scaling
            .attr('transform', event.transform);
    
          d3.selectAll('svg:path') // To prevent stroke width from scaling
            .attr('transform', event.transform);
    
          d3.selectAll('.link') // To prevent stroke width from scaling
            .attr('transform', event.transform);
        }*/
        function dragstarted(event, d) {
            if (!event.active)
                simulation.alphaTarget(0.3).restart();
            node
                .filter(p => p === d)
                .raise()
                .attr('stroke', 'black');
            //d.fx = d.x;
            //d.fy = d.y;
        }
        function dragged(event, d) {
            d.fx = event.x;
            d.fy = event.y;
            /*node.each(function(e) {
              if (d !== e) {
                e.fx = e.x;
                e.fy = e.y;
              }
            });*/
        }
        function dragended(event, d) {
            if (!event.active)
                simulation.alphaTarget(0);
            //d.fx = d.x;
            //d.fy = d.y;
            node
                .filter(p => p === d)
                .raise()
                .attr('stroke', undefined);
        }
        render();
        // This function is run at each iteration of the force algorithm, updating the nodes position.
        function ticked() {
            link
                .attr('x1', function (d) {
                return d.source.x;
            })
                .attr('y1', function (d) {
                return d.source.y;
            })
                .attr('x2', function (d) {
                edgepaths;
                return d.target.x;
            })
                .attr('y2', function (d) {
                return d.target.y;
            });
            //@ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const self = this;
            node
                .attr('cx', function (d) {
                // same radius for each nodes
                //return d.x;
                return (d.x = Math.max(20, Math.min(screenWidth, d.x)));
            })
                .attr('cy', function (d) {
                const radius = 20;
                //return d.y;
                return (d.y = Math.max(20, Math.min(screenHeight, d.y)));
            });
            node.attr('transform', function (d) {
                return 'translate(' + d.x + ', ' + d.y + ')';
            });
            edgepaths.attr('d', function (d) {
                return ('M ' +
                    d.source.x +
                    ' ' +
                    d.source.y +
                    ' L ' +
                    d.target.x +
                    ' ' +
                    d.target.y);
            });
            edgelabels.attr('transform', function (d) {
                if (d.target.x < d.source.x) {
                    // @ts-ignore
                    var bbox = this.getBBox();
                    var rx = bbox.x + bbox.width / 2;
                    var ry = bbox.y + bbox.height / 2;
                    return 'rotate(180 ' + rx + ' ' + ry + ')';
                }
                else {
                    return 'rotate(0)';
                }
            });
        }
    }
    mounted() {
        if (this.data.nodes && this.data.links && !isEmpty(this.data.nodes))
            //this.hyperbolicTree(json(this.data.nodes), json(this.data.links));
            this.hTree(json(this.data.nodes), json(this.data.links));
    }
};
__decorate([
    Prop({ default: () => { } })
], CompanyHyperbolicGraphFullscreen.prototype, "data", void 0);
__decorate([
    Prop({ default: 900 })
], CompanyHyperbolicGraphFullscreen.prototype, "width", void 0);
__decorate([
    Prop({ default: 900 })
], CompanyHyperbolicGraphFullscreen.prototype, "height", void 0);
CompanyHyperbolicGraphFullscreen = __decorate([
    Component({
        name: 'CompanyHyperbolicGraphFullscreen',
    })
], CompanyHyperbolicGraphFullscreen);
export default CompanyHyperbolicGraphFullscreen;
