var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Watch } from 'vue-property-decorator';
import ChangePaymentMethod from '@/modules/subscriptions/components/ChangePaymentMethod.vue';
import IconBoleto from '@/components/Icons/IconBoleto.vue';
import IconCreditCardManager from '@/components/Icons/IconCreditCardManager.vue';
import IconInformation from '@/components/Icons/IconInformation.vue';
import { connect } from '@/overmind';
import { mixpanelTracking } from '@/services/mixpanel';
var resizeIcon;
(function (resizeIcon) {
    resizeIcon["elo"] = "20px";
})(resizeIcon || (resizeIcon = {}));
let AccountInformationPaymentMethod = class AccountInformationPaymentMethod extends Vue {
    constructor() {
        super(...arguments);
        this.infoLoaded = false;
        this.editMode = false;
        this.types = ['bank_slip', 'credit_card'];
        this.payment_method = {
            type: '',
            card: {
                number: '',
                flag: '',
                expiry_month: '',
                expiry_year: '',
            },
        };
        this.flagNames = {
            amex: 'American Express',
            diners: 'Diners Club',
        };
    }
    handleMixTracking() {
        this.editMode && mixpanelTracking('upgrade: Alterou o método de pagamento');
    }
    async mounted() {
        //@ts-ignore
        this.payment_method = await this.actions.getPaymentMethod();
        this.infoLoaded = true;
    }
    get ResizedIcon() {
        const customSize = resizeIcon[this.payment_method.card.flag];
        const size = customSize ? customSize : '30px';
        return size;
    }
    get ExpirationDate() {
        const currentMonth = this.payment_method.card.expiry_month;
        const month = +currentMonth > 9 ? currentMonth : '0' + currentMonth;
        const year = this.payment_method.card.expiry_year;
        return month + '/' + year;
    }
    get FlagName() {
        const flag = this.payment_method.card.flag;
        return this.flagNames[flag] || flag;
    }
    async cancelEditMode(updatedData) {
        this.editMode = false;
        if (updatedData) {
            this.infoLoaded = false;
            //@ts-ignore
            const payment_method = await this.actions.getPaymentMethod();
            this.payment_method = payment_method;
            this.infoLoaded = true;
        }
    }
    sendEditModeInfo(val) {
        this.$emit('sendEditModeInfo', val);
    }
};
__decorate([
    Watch('editMode')
], AccountInformationPaymentMethod.prototype, "handleMixTracking", null);
__decorate([
    Watch('editMode')
], AccountInformationPaymentMethod.prototype, "sendEditModeInfo", null);
AccountInformationPaymentMethod = __decorate([
    Component(connect(({ actions }) => ({
        actions: actions.shop,
    }), {
        components: {
            IconBoleto,
            IconCreditCardManager,
            ChangePaymentMethod,
            IconInformation,
        },
    }))
], AccountInformationPaymentMethod);
export default AccountInformationPaymentMethod;
