var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import TableBaseComponent from '../AdditionalDataComponents/ComponentsBase/TableBaseComponent.vue';
import MiniCardComponent from '../AdditionalDataComponents/ComponentsBase/MiniCardComponent.vue';
import { formatNumber } from '@/shared/utils/helpers.ts';
let CompanyWebsiteAccess = class CompanyWebsiteAccess extends Vue {
    constructor() {
        super(...arguments);
        this.headers = {
            ranking_site: ['Global', 'Pais', 'Categoria'],
            traffic_type: ['', 'Valor'],
            country_access: ['Pais', 'Percentual de acesso', 'Visitas por pagina'],
            device_access: ['Desktop', 'Mobile'],
            audience_demographic: ['Homem', 'Mulher'],
            traffic_social: ['Plataforma', 'Percentual'],
        };
        this.titles = {
            ranking_site: 'Ranking do site',
            traffic_type: 'Tipo de Tráfego',
            country_access: 'Acesso por pais',
            device_access: 'Acesso por dispositivo',
            audience_demographic: 'Acesso por sexo',
            traffic_social: 'Acesso por rede social',
        };
        this.table = true;
        this.model = 'Atual';
        this.options = ['Atual'];
    }
    getLastMonth() {
        return this.dataComponent.company_website_access.data[0].last_month;
    }
    getHistoric() {
        return this.dataComponent.company_website_access.data[0].historic;
    }
    formatNumeric(value) {
        return formatNumber(value);
    }
    buildRowsFake() {
        let rows = [];
        for (let index = 0; index < 5; index++) {
            rows.push({ '1': '******', '2': '****', '3': '****' });
        }
        return rows;
    }
    buildRows(type) {
        let rows = [];
        if (this.model == 'Atual') {
            rows = this.processData(type, this.getLastMonth());
        }
        else {
            this.getHistoric().forEach(value => {
                if (value['date'].includes(this.model)) {
                    rows = this.processData(type, value);
                }
            });
        }
        return rows;
    }
    processData(type, data) {
        let rows = [];
        if (type == 'ranking_site' && data[type]) {
            let rowFormated = {};
            rowFormated['global'] = `${this.formatNumeric(data[type]['global'])}°`;
            rowFormated['country'] = `${this.formatNumeric(data[type]['country'])}°`;
            rowFormated['category'] = `${this.formatNumeric(data[type]['category'])}°`;
            rows.push(rowFormated);
        }
        else if (type == 'device_access' && data[type]) {
            let rowFormated = {};
            rowFormated['desktop'] = this.formatNumeric(data[type][0]['desktop']);
            rowFormated['mobile'] = this.formatNumeric(data[type][0]['mobile']);
            rows.push(rowFormated);
        }
        else if (type == 'country_access' && data[type]) {
            data[type].forEach(country => {
                let rowFormated = {};
                rowFormated['nome'] = country['name'];
                if (country['country_access_percent'] == 0) {
                    rowFormated['porcentagem'] = '< 1%';
                }
                else {
                    rowFormated['porcentagem'] = `${country['country_access_percent'] * 100}%`;
                }
                rowFormated['visitas_por_pagina'] = country['visit_per_page'];
                rows.push(rowFormated);
            });
        }
        else if (type == 'audience_demographic' && data[type]) {
            let genre = data[type][0]['genre'];
            let rowFormated = {};
            rowFormated['homem'] = genre['men'];
            rowFormated['mulher'] = genre['women'];
            rows.push(rowFormated);
        }
        else if (type == 'traffic_type' && data[type]) {
            data[type].forEach(traffic_type => {
                let key = Object.keys(traffic_type).shift();
                let rowFormated = {};
                const keys = {
                    'Paid Search': 'Pesquisa Paga',
                    'Organic Search': 'Pesquisa Orgânica',
                    Direct: 'Acesso Direto',
                    Social: 'Redes Sociais',
                };
                if (key)
                    rowFormated['nome'] = keys[key];
                /*
                if (key == 'Paid Search') {
                  rowFormated['nome'] = 'Pesquisa Paga'
                }
                else if (key == 'Organic Search') {
                  rowFormated['nome'] = 'Pesquisa Orgânica'
                }
                else if (key == 'Direct') {
                  rowFormated['nome'] = 'Acesso Direto'
                }
                else if (key == 'Social') {
                  rowFormated['nome'] = 'Redes Socias'
                }
                */
                if (key)
                    rowFormated['valor'] = this.formatNumeric(traffic_type[key]);
                rows.push(rowFormated);
            });
        }
        else if (type == 'traffic_social' && data[type]) {
            rows = data[type];
        }
        return rows;
    }
    buildOptions() {
        this.dataComponent.company_website_access.data[0].historic.forEach(item => {
            if (item) {
                this.options.push(item['date'].slice(0, 7));
            }
        });
    }
    mounted() {
        this.buildOptions();
    }
};
__decorate([
    Prop({ default: {} })
], CompanyWebsiteAccess.prototype, "dataComponent", void 0);
CompanyWebsiteAccess = __decorate([
    Component({
        name: 'CompanyDrawerAdditionalDataTab',
        components: {
            TableBaseComponent,
            MiniCardComponent,
        },
    })
], CompanyWebsiteAccess);
export default CompanyWebsiteAccess;
