var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import TableBaseComponent from './ComponentsBase/TableBaseComponent.vue';
let ImportExport = class ImportExport extends Vue {
    buildHeader() {
        let header = [];
        this.dataComponent.import_export_companies.data.forEach(value => {
            if (value.import) {
                header.push('Anos que importou');
            }
            if (value.export) {
                header.push('Anos que exportou');
            }
        });
        return header;
    }
    buildRows() {
        let rowFormated = {};
        let rows = [];
        this.dataComponent.import_export_companies.data.forEach(value => {
            if (value.import) {
                rowFormated['import'] = value.import.join(', ');
            }
            if (value.export) {
                rowFormated['export'] = value.export.join(', ');
            }
        });
        rows.push(rowFormated);
        return rows;
    }
};
__decorate([
    Prop({ default: {} })
], ImportExport.prototype, "dataComponent", void 0);
ImportExport = __decorate([
    Component({
        name: 'ImportExport',
        components: {
            TableBaseComponent,
        },
    })
], ImportExport);
export default ImportExport;
