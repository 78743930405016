var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import TableBaseComponent from '../AdditionalDataComponents/ComponentsBase/TableBaseComponent.vue';
let CompanyGrowth = class CompanyGrowth extends Vue {
    constructor() {
        super(...arguments);
        this.header = [
            'Anos de comparação',
            'Percentual',
            'Crescimento/Diminuição Absoluto',
        ];
        this.table = true;
    }
    buildRowsFake() {
        let rows = [];
        for (let index = 0; index < 5; index++) {
            rows.push({ '1': '******', '2': '****', '3': '****' });
        }
        return rows;
    }
    builGraficFake() {
        let chartOptions = {
            credits: {
                enabled: false,
            },
            yAxis: {
                title: {
                    text: '',
                },
            },
            xAxis: {
                categories: ['**', '**', '**', '**', '**'],
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false,
                    },
                },
            },
            title: {
                text: '',
            },
            series: [{ name: 'Crescimento/Diminuição', data: [1, 2, 1, 2, 1] }],
        };
        return chartOptions;
    }
    buildRows() {
        let rows = [];
        if (this.dataComponent.company_growth.data[0].historic) {
            this.dataComponent.company_growth.data[0].historic.forEach(value => {
                let rowFormated = {};
                rowFormated['comparacao'] = value['now'];
                rowFormated['percent'] = `${value['increase_percent']}%`;
                rowFormated['crescimento-diminuicao'] = value['increase'];
                rows.push(rowFormated);
            });
        }
        return rows;
    }
    buildGraphic() {
        let chartOptions = {
            credits: {
                enabled: false,
            },
            yAxis: {
                title: {
                    text: '',
                },
            },
            xAxis: {
                categories: [],
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false,
                    },
                },
            },
            title: {
                text: '',
            },
            series: [],
        };
        if (this.dataComponent.company_growth.data[0].historic) {
            let rowFormated = {};
            rowFormated['name'] = 'Crescimento/Diminuição';
            rowFormated['data'] = [];
            this.dataComponent.company_growth.data[0].historic.forEach(value => {
                chartOptions['xAxis']['categories'].push(value['now']);
                rowFormated['data'].push(value['increase']);
            });
            rowFormated['data'].reverse();
            chartOptions['series'].push(rowFormated);
        }
        chartOptions['xAxis']['categories'] =
            chartOptions['xAxis']['categories'].reverse();
        return chartOptions;
    }
};
__decorate([
    Prop({ default: {} })
], CompanyGrowth.prototype, "dataComponent", void 0);
CompanyGrowth = __decorate([
    Component({
        name: 'CompanyDrawerAdditionalDataTab',
        components: {
            TableBaseComponent,
        },
    })
], CompanyGrowth);
export default CompanyGrowth;
