var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import IconCardDefault from '@/components/Icons/CreditCardFlags/IconCardDefault.vue';
import { toNumber } from 'lodash';
import { mixpanelTracking } from '@/services/mixpanel';
import { initializeStripe } from '@/shared/utils/stripe';
let EditPaymentMethod = class EditPaymentMethod extends Vue {
    constructor() {
        super(...arguments);
        this.errMessage = '';
        this.card = {
            name: '',
            number: '',
            expiration_date: '',
            cvv: '',
            token: 'xxxxx',
        };
    }
    get validateEmptyFields() {
        const cardInfo = Object.values(this.card);
        for (let item of cardInfo) {
            if (item == '') {
                this.errMessage = '* Todos os campos são obrigatórios';
                return false;
            }
        }
        this.errMessage = '';
        return true;
    }
    get validateDate() {
        const data = this.card.expiration_date.split('/');
        const validation = data.length == 2 && data[1].length == 2 && toNumber(data[0]) < 32;
        if (!validation) {
            this.errMessage = '* Data de vencimento do cartão inválida';
        }
        else {
            this.errMessage = '';
        }
        return validation;
    }
    submit() {
        if (this.validateEmptyFields && this.validateDate) {
            mixpanelTracking('upgrade: Adicionou um cartão de crédito como método de pagamento');
            this.$emit('updateCreditCard', this.card);
        }
    }
    async mounted() {
        mixpanelTracking('upgrade: Clicou em adicionar cartão de crédito como método de pagamento');
        if (this.isStripe) {
            this.stripe = await initializeStripe();
            const elements = this.stripe.elements();
            this.stripeCardForm = elements.create('card', {
                hidePostalCode: true,
                style: {
                    base: {
                        iconColor: '#95A5A6',
                        color: '#95A5A6',
                        fontWeight: '400',
                        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                        fontSize: '14px',
                    },
                    invalid: {
                        iconColor: '#F3705A',
                        color: '#F3705A',
                    },
                },
            });
            this.stripeCardForm.mount('#card-element');
        }
    }
    async submitStripeForm() {
        const result = await this.stripe.createToken(this.stripeCardForm);
        if (!result.error) {
            this.card.token = result.token.id;
            this.card.expiration_date =
                result.token.card.exp_month + '/' + result.token.card.exp_year;
            mixpanelTracking('upgrade: Adicionou um cartão de crédito como método de pagamento');
            this.$emit('updateCreditCard', this.card);
        }
    }
};
__decorate([
    Prop({ default: false })
], EditPaymentMethod.prototype, "isStripe", void 0);
__decorate([
    Prop({ default: true })
], EditPaymentMethod.prototype, "showIcon", void 0);
__decorate([
    Prop({ default: false })
], EditPaymentMethod.prototype, "loading", void 0);
EditPaymentMethod = __decorate([
    Component({
        components: {
            IconCardDefault,
        },
    })
], EditPaymentMethod);
export default EditPaymentMethod;
