var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import TableBaseComponent from './ComponentsBase/TableBaseComponent.vue';
import MiniCardComponent from './ComponentsBase/MiniCardComponent.vue';
import IconContract from '@/components/Icons/IconContract.vue';
import IconHandShake from '@/components/Icons/IconHandShake.vue';
let BiddingProcess = class BiddingProcess extends Vue {
    constructor() {
        super(...arguments);
        this.model = ''; // Se tiver só um nem aparece o selection box
        this.options = []; // vai ser gerado a partir dos dados que temos
        this.header = ['Identificador', 'Data', 'Valor do Contrato', 'Descrição'];
    }
    getDataBiddingProcess() {
        return this.dataComponent.bidding_process.data || [];
    }
    handleDataVisibility(key, label) {
        return this.dataComponent.bidding_process[key] && this.model === label;
    }
    buildOptions() {
        if (this.dataComponent.bidding_process.pregoes_participantes) {
            this.options.push('Pregões participantes');
        }
        if (this.dataComponent.bidding_process.contratos_firmados) {
            this.options.push('Contratos firmados');
        }
        this.model = this.options[0];
    }
    mounted() {
        this.buildOptions();
    }
    buildRows(type) {
        const rows = [];
        this.buildParticipatingTradingSessionsData(type, rows);
        this.buildSignedContractsData(type, rows);
        return rows;
    }
    buildParticipatingTradingSessionsData(type, rows) {
        if (this.dataComponent.bidding_process.pregoes_participantes &&
            type === 'pregoes') {
            this.dataComponent.bidding_process.pregoes_participantes.forEach(value => {
                const rowFormated = {
                    identificador: value.identificador,
                    date: this.formatDate(new Date(value.dt_participacao)),
                    quantity: this.formatPrice(value.valor),
                    name: value.nome,
                };
                rows.push(rowFormated);
            });
        }
    }
    buildSignedContractsData(type, rows) {
        if (this.dataComponent.bidding_process.contratos_firmados &&
            type === 'contratos') {
            this.dataComponent.bidding_process.contratos_firmados.forEach(value => {
                const dateRow = `${this.formatDate(new Date(value.dt_inicio))} até ${this.formatDate(new Date(value.dt_fim))}`;
                const rowFormated = {
                    identificador: value.identificador,
                    date: dateRow,
                    quantity: this.formatPrice(value.valor),
                    name: value.nome,
                };
                rows.push(rowFormated);
            });
        }
    }
    formatPrice(price) {
        let value = price.toString().replace(/\./g, ',');
        return 'R$ ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }
    formatDate(date) {
        return [
            this.padTo2Digits(date.getDate()),
            this.padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('/');
    }
};
__decorate([
    Prop({ default: {} })
], BiddingProcess.prototype, "dataComponent", void 0);
BiddingProcess = __decorate([
    Component({
        name: 'BiddingProcess',
        components: {
            TableBaseComponent,
            MiniCardComponent,
            IconContract,
            IconHandShake,
        },
    })
], BiddingProcess);
export default BiddingProcess;
