var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import Modal from './Modal.vue';
import { connect } from '@/overmind';
import { mixpanelTracking } from '@/services/mixpanel';
let ReactivationAndReengagementModal = class ReactivationAndReengagementModal extends Vue {
    constructor() {
        super(...arguments);
        this.open = false;
        this.loading = false;
        this.modalType = '';
        this.dealOwner = 0;
        this.event = '';
    }
    async mounted() {
        // @ts-ignore
        const result = await this.actions.showReactivationAndReengagementModal();
        if (result.show) {
            this.open = true;
            this.modalType = result.engageType;
            this.dealOwner = result.dealOwner;
            this.event = result.event;
            switch (this.modalType) {
                case 'Reengajamento CS':
                    mixpanelTracking('CS: modal de reengajamento foi renderizado');
                    break;
                case 'Reativação':
                    mixpanelTracking('CS: modal de reativação foi renderizado');
                    break;
            }
        }
    }
    get title() {
        switch (this.modalType) {
            case 'Reativação':
                return 'Sua conta foi selecionada para uma mentoria exclusiva com um especialista em vendas!';
            case 'Reengajamento CS':
                return 'Quer ainda mais resultados com seus dados? Marque agora uma reunião com seu executivo de contas!';
            default:
                return '';
        }
    }
    get description() {
        switch (this.modalType) {
            case 'Reativação':
                return 'Após a reunião, você terá certeza que de que está no caminho certo com a Speedio e ainda vai garantir 50 créditos em sua conta!';
            case 'Reengajamento CS':
                return 'Após a reunião, você terá uma melhor experiência com a Speedio e 50 créditos em sua conta.';
            default:
                return '';
        }
    }
    get buttonName() {
        switch (this.modalType) {
            case 'Reativação':
                return 'Agendar minha mentoria agora!';
            case 'Reengajamento CS':
                return 'Agendar minha reunião agora!';
            default:
                return '';
        }
    }
    async noThanks() {
        switch (this.modalType) {
            case 'Reengajamento CS':
                mixpanelTracking('CS: fechou modal de reengajamento');
                break;
            case 'Reativação':
                mixpanelTracking('CS: fechou modal de reativação');
                break;
        }
        await this.registerAction('Visualizado e não agendado');
        this.closeModal();
    }
    async closeModal() {
        // @ts-ignore
        this.actions.closeModal();
        this.open = false;
    }
    async schedule() {
        switch (this.modalType) {
            case 'Reengajamento CS':
                mixpanelTracking('CS: clicou em agendar reunião no modal de reengajamento');
                break;
            case 'Reativação':
                mixpanelTracking('CS: clicou em agendar mentoria no modal de reativação');
                break;
        }
        const owners = [
            {
                id: 756587036,
                url: 'https://central.speedio.com.br/meetings/alan-portela',
            },
            {
                id: 1081909883,
                url: 'https://central.speedio.com.br/meetings/aline-alves3',
            },
            {
                id: 1688237981,
                url: 'https://central.speedio.com.br/meetings/arthur-areas',
            },
            {
                id: 920632823,
                url: 'https://central.speedio.com.br/meetings/bruno-faria',
            },
            {
                id: 636753244,
                url: 'https://central.speedio.com.br/meetings/henrique-alves1',
            },
            {
                id: 756330332,
                url: 'https://central.speedio.com.br/meetings/jefferson-silva5',
            },
            {
                id: 500319825,
                url: 'https://central.speedio.com.br/meetings/luan-oliveira1',
            },
            {
                id: 500115615,
                url: 'https://central.speedio.com.br/meetings/mabile',
            },
            {
                id: 687823840,
                url: 'https://central.speedio.com.br/meetings/ricardo-cafe-fiocchi',
            },
            {
                id: 560318609,
                url: 'https://central.speedio.com.br/meetings/samanta-araujo',
            },
            {
                id: 500328727,
                url: 'https://central.speedio.com.br/meetings/wesley-almeida',
            },
            {
                id: 1738140688,
                url: 'https://central.speedio.com.br/meetings/giulia-lopes-speedio/kickoff-ativacao',
            },
            {
                id: 2070730805,
                url: 'https://central.speedio.com.br/meetings/jader-vieira-ribeiro',
            },
            {
                id: 1773085612,
                url: 'https://central.speedio.com.br/meetings/pamela-martin1',
            },
            {
                id: 1043192800,
                url: 'https://central.speedio.com.br/meetings/tainara-teles-/tainara-speedio',
            },
        ];
        const owner = owners.find(owner => owner.id == this.dealOwner);
        if (owner) {
            window.open(owner.url, '_blank');
        }
        else {
            window.open('https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0LfQw2yFWVnNtQ8LX2UOyQFxUGn_dingJwOlDv0pReMr7TGoP5q4fhaLABxzFNBEZ2HKaru0cq', '_blank');
        }
        await this.registerAction('Visualizado e agendado');
        this.closeModal();
    }
    async registerAction(action) {
        this.loading = true;
        try {
            const params = {
                user_action: action,
                event: this.event,
            };
            await Vue.axios.post('/accounts/reactivation_and_reengagement_action', params);
        }
        catch (e) {
            console.log(e);
        }
        this.loading = false;
    }
};
ReactivationAndReengagementModal = __decorate([
    Component(connect(({ state: { modals: state }, actions: { modals: actions } }) => ({
        actions: actions,
        state: state,
    }), {
        name: 'ReactivationAndReengagementModal',
        components: {
            Modal,
        },
    }))
], ReactivationAndReengagementModal);
export default ReactivationAndReengagementModal;
