var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Modal from 'components/Modal.vue';
import TextClickToCopy from './TextClickToCopy.vue';
let CompanyDrawerContactTabPhones = class CompanyDrawerContactTabPhones extends Vue {
    constructor() {
        super(...arguments);
        this.showPhonesModal = false;
        this.clickCopyItem = false;
    }
    changeIconCopyClick(currVal) {
        if (currVal)
            setTimeout(() => {
                this.clickCopyItem = false;
            }, 300);
    }
    onInfosetRouteChange(curr, old) {
        if (curr) {
            this.validatedPhones();
            this.validatedPhonesElasticSearch();
        }
    }
    formatPhoneNumber(phoneNumberString) {
        const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        let noMatch = true;
        const match = cleaned.match(/^(1|)?(\d{2})(\d{5})(\d{4})$/);
        const match2 = cleaned.match(/(\d{2})(\d{5})(\d{4})$/);
        const match3 = cleaned.match(/(\d{2})(\d{4})(\d{4})$/);
        let intlCode = '';
        if (match) {
            noMatch = false;
            intlCode = match[1] ? '+55' : '';
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        if (match2) {
            noMatch = false;
            intlCode = match2[0] ? '+55 ' : '';
            return [intlCode, '(', match2[1], ') ', match2[2], '-', match2[3]].join('');
        }
        if (match3) {
            noMatch = false;
            intlCode = match3[0] ? '+55 ' : '';
            return [intlCode, '(', match3[1], ') ', match3[2], '-', match3[3]].join('');
        }
        if (noMatch) {
            return phoneNumberString;
        }
        return null;
    }
    validatedPhones() {
        Object.keys(this.allPhones).forEach(key => {
            const phone = this.validPhones.find(phone => phone.value === key);
            if (phone && phone.validation) {
                this.allPhones[key].validation = phone.validation;
            }
        });
    }
    validatedPhonesElasticSearch() {
        Object.keys(this.allPhones).forEach(key => {
            if (this.allPhones[key].validationAtElasticSearch) {
                if (this.allPhones[key].validationAtElasticSearch.whatsapp)
                    this.allPhones[key].whatsapp =
                        this.allPhones[key].validationAtElasticSearch.whatsapp.result;
            }
        });
    }
};
__decorate([
    Prop({ default: () => { } })
], CompanyDrawerContactTabPhones.prototype, "allPhones", void 0);
__decorate([
    Prop({ default: () => [] })
], CompanyDrawerContactTabPhones.prototype, "validPhones", void 0);
__decorate([
    Prop({ default: () => false })
], CompanyDrawerContactTabPhones.prototype, "infosetRoute", void 0);
__decorate([
    Watch('clickCopyItem')
], CompanyDrawerContactTabPhones.prototype, "changeIconCopyClick", null);
__decorate([
    Watch('infosetRoute')
], CompanyDrawerContactTabPhones.prototype, "onInfosetRouteChange", null);
CompanyDrawerContactTabPhones = __decorate([
    Component({
        name: 'CompanyDrawerContactTabPhones',
        components: {
            Modal,
            TextClickToCopy,
        },
    })
], CompanyDrawerContactTabPhones);
export default CompanyDrawerContactTabPhones;
