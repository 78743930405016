var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/*eslint-disable */
import { Component, Prop, Vue } from 'vue-property-decorator';
import { LMap, LTileLayer, LMarker, LTooltip } from 'vue2-leaflet';
import { latLng, icon } from 'leaflet';
import { isEmpty } from 'lodash';
let CompanyMapLocalization = class CompanyMapLocalization extends Vue {
    constructor() {
        super(...arguments);
        this.geolocationEnable = true;
        this.zoom = 13;
        this.url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
        this.attribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
        this.icon = icon({
            iconUrl: 'statics/images/map-marker-icon.png',
            iconSize: [32, 37],
            iconAnchor: [16, 37],
        });
    }
    get checkGeolocation() {
        if (this.address.geolocation && !isEmpty(this.address.geolocation)) {
            let objectGeo = {};
            let lat = this.address.geolocation.lat;
            let lon = this.address.geolocation.lon;
            if (lat && lon) {
                objectGeo = { lat: lat.toFixed(5), lon: lon.toFixed(6) };
                this.geolocationEnable = true;
                return objectGeo;
            }
            else {
                this.geolocationEnable = false;
                return { lat: '', lon: '' };
            }
        }
        else {
            this.geolocationEnable = false;
            return { lat: '', lon: '' };
        }
    }
    get center() {
        return [this.checkGeolocation.lat, this.checkGeolocation.lon];
    }
    get marker() {
        return latLng(this.checkGeolocation.lat, this.checkGeolocation.lon);
    }
};
__decorate([
    Prop({ default: () => { } })
], CompanyMapLocalization.prototype, "address", void 0);
__decorate([
    Prop({ default: '' })
], CompanyMapLocalization.prototype, "companyName", void 0);
CompanyMapLocalization = __decorate([
    Component({
        name: 'CompanyMapLocalization',
        components: {
            LMap,
            LTileLayer,
            LMarker,
            LTooltip,
        },
    })
], CompanyMapLocalization);
export default CompanyMapLocalization;
