var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import { json } from 'overmind';
import CompanyHyperbolicDataContainer from '@/modules/infosets/containers/CompanyHyperbolicData.container.vue';
import CompanyAdditionalDataUnstructured from 'components/CompanyAdditionalDataUnstructured.vue';
import AditionalDataChar from './CompanyDrawerAdditionalDataChar.vue';
import _ from 'lodash';
// Load automatic components
/* eslint-disable */
/* @ts-ignore */
const req = require.context('./AdditionalDataComponents/', false, /\.(js|vue)$/i);
for (const key of req.keys()) {
    const name = key.match(/\w+/)[0];
    Vue.component(name, req(key).default);
}
/* eslint-enable */
let CompanyDrawerAdditionalDataTab = class CompanyDrawerAdditionalDataTab extends Vue {
    constructor() {
        super(...arguments);
        this.newAdditionalData = [];
        this.currentIndexData = null;
        this.showHyperbolicDataFiltered = false;
        this.thumbStyleScrool = {
            right: '2px',
            borderRadius: '5px',
            backgroundColor: '#CFD8DC',
            width: '5px',
            opacity: 0.75,
        };
        this.search = '';
        this.tab = 0;
    }
    getComponentByKey(data) {
        let KeyCompoment = Object.keys(data).shift();
        let namesData = {
            full_qsa: {
                name: 'Quadro Societário',
                component: 'FullQsa',
                order: 1,
            },
            rais_history: {
                name: 'Faturamento e funcionários',
                component: 'RaisHistory',
                order: 2,
            },
            financial_company: {
                name: 'Finanças',
                component: 'FinancialCompany',
                order: 3,
            },
            data_gpt: {
                name: 'Produto/serviço',
                component: 'GPTData',
                order: 4,
            },
            website_secondary: {
                name: 'Redes Sociais',
                component: 'WebSiteSecondary',
                order: 5,
            },
            pgfn_debts: {
                name: 'Dívidas abertas',
                component: 'PgfnDebts',
                order: 6,
            },
            lawsuits: {
                name: 'Processos Jurídicos',
                component: 'Lawsuits',
                order: 7,
            },
            complaints: {
                name: 'Reclamações',
                component: 'Complaints',
                order: 8,
            },
            bidding_process: {
                name: 'Licitações',
                component: 'BiddingProcess',
                order: 9,
            },
            technologies: {
                name: 'Tecnologias',
                component: 'Technologies',
                order: 10,
            },
            import_export_companies: {
                name: 'Exportações e Importações',
                component: 'ImportExport',
                order: 11,
            },
        };
        return namesData[KeyCompoment];
    }
    buildAdditionalData() {
        let dataVerify = [];
        this.additionalData.forEach(value => {
            let new_value = json(value);
            if (this.getComponentByKey(new_value)) {
                dataVerify.push(new_value);
            }
        });
        dataVerify.sort((a, b) => this.getComponentByKey(a).order > this.getComponentByKey(b).order ? 1 : -1);
        this.newAdditionalData = dataVerify;
        return dataVerify;
    }
    handleValidate(additionalData) {
        return additionalData && additionalData.length > 0;
    }
    hyperbolicTitleFiltered(searchTerm) {
        const hyperbolicTitle = _.deburr('arvore hiperbolica');
        const matchHyperbolicFilter = hyperbolicTitle
            .toLowerCase()
            .match(searchTerm);
        if ((this.hasHyperbolicData && searchTerm !== '' && matchHyperbolicFilter) ||
            (this.hasHyperbolicData && searchTerm === '')) {
            this.showHyperbolicDataFiltered = true;
        }
        else {
            this.showHyperbolicDataFiltered = false;
        }
    }
    mounted() {
        this.buildAdditionalData();
    }
};
__decorate([
    Prop({ default: () => [] })
], CompanyDrawerAdditionalDataTab.prototype, "additionalData", void 0);
__decorate([
    Prop({ default: false })
], CompanyDrawerAdditionalDataTab.prototype, "hasHyperbolicData", void 0);
__decorate([
    Prop({ default: '' })
], CompanyDrawerAdditionalDataTab.prototype, "companyName", void 0);
CompanyDrawerAdditionalDataTab = __decorate([
    Component({
        name: 'CompanyDrawerAdditionalDataTab',
        components: {
            AditionalDataChar,
            CompanyHyperbolicDataContainer,
            CompanyAdditionalDataUnstructured,
        },
    })
], CompanyDrawerAdditionalDataTab);
export default CompanyDrawerAdditionalDataTab;
