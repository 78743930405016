var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, PropSync, Prop, Emit } from 'vue-property-decorator';
import CompanyDetailsBodySkeleton from 'components/CompanyDetailsBodySkeleton.vue';
import CompanyDrawerSummaryTab from 'components/CompanyDrawerSummaryTab.vue';
import CompanyDrawerRegisterTab from 'components/CompanyDrawerRegisterTab.vue';
import CompanyDrawerContactTab from 'components/CompanyDrawerContactTab.vue';
import CompanyDrawerDecisorsTab from 'components/CompanyDrawerDecisorsTab.vue';
import CompanyDrawerAdditionalDataTab from 'components/CompanyDrawerAdditionalDataTab.vue';
import CompanyDrawerSpecialDataTab from 'components/CompanyDrawerSpecialDataTab.vue';
import SearchNoCredits from '@/modules/search/components/SearchNoCredits.vue';
import { isEmpty } from 'lodash';
import { CompanyStatus } from '@/overmind/search/state';
let CompanyDetailsBody = class CompanyDetailsBody extends Vue {
    notLoadedCompany() {
        return (this.companyStatus === CompanyStatus.FETCHING || isEmpty(this.company));
    }
    searchCompanyCEP(cep) {
        return cep;
    }
    searchCompanyCNPJ(cnpj) {
        return cnpj;
    }
    searchCompanyCNAE(event) {
        return event;
    }
    hasHyperbolicData() {
        return this.company.badges &&
            this.company.badges.hyperbolictree_size_estimation &&
            this.hyperbolicDataIsValid() &&
            this.hyperbolicData.nodes.length > 0 &&
            this.hyperbolicData.links.length > 0
            ? this.company.badges.hyperbolictree_size_estimation > 1
            : false;
    }
    hyperbolicDataIsValid() {
        return (this.company.matriz == '1' &&
            this.hyperbolicData &&
            this.hyperbolicData.nodes &&
            this.hyperbolicData.links);
    }
    getCompanyCnpjsNextPage(page) {
        this.$emit('getCompanyCnpjsNextPage', page);
    }
};
__decorate([
    PropSync('currentTab', { default: 'summary' })
], CompanyDetailsBody.prototype, "tab", void 0);
__decorate([
    Prop({ default: () => { } })
], CompanyDetailsBody.prototype, "company", void 0);
__decorate([
    Prop({ default: '' })
], CompanyDetailsBody.prototype, "additionalDataState", void 0);
__decorate([
    Prop({ default: false })
], CompanyDetailsBody.prototype, "noCreditsToGetCompany", void 0);
__decorate([
    Prop({ default: CompanyStatus.INITIAL })
], CompanyDetailsBody.prototype, "companyStatus", void 0);
__decorate([
    Prop({ default: () => { } })
], CompanyDetailsBody.prototype, "hyperbolicData", void 0);
__decorate([
    Emit('searchCompanyCEP')
], CompanyDetailsBody.prototype, "searchCompanyCEP", null);
__decorate([
    Emit('searchCompanyCNPJ')
], CompanyDetailsBody.prototype, "searchCompanyCNPJ", null);
__decorate([
    Emit('searchCompanyCNAE')
], CompanyDetailsBody.prototype, "searchCompanyCNAE", null);
CompanyDetailsBody = __decorate([
    Component({
        name: 'CompanyDetailsBody',
        components: {
            CompanyDetailsBodySkeleton,
            CompanyDrawerSummaryTab,
            CompanyDrawerRegisterTab,
            CompanyDrawerContactTab,
            CompanyDrawerDecisorsTab,
            CompanyDrawerAdditionalDataTab,
            CompanyDrawerSpecialDataTab,
            SearchNoCredits,
        },
    })
], CompanyDetailsBody);
export default CompanyDetailsBody;
