var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import { connect } from '@/overmind';
let deleteNotesModal = class deleteNotesModal extends Vue {
    async confirmRemove() {
        //@ts-ignore
        if (this.note._id) {
            //@ts-ignore
            await this.userActions.deletNote(this.note._id.$oid);
        }
        this.$emit('confirmRemove');
        this.showModal = false;
    }
};
__decorate([
    PropSync('open', { default: false })
], deleteNotesModal.prototype, "showModal", void 0);
__decorate([
    Prop({ default: {} })
], deleteNotesModal.prototype, "note", void 0);
deleteNotesModal = __decorate([
    Component(connect(({ actions }) => ({
        userActions: actions.users,
    }), {
        name: 'deleteNotesModal',
    }))
], deleteNotesModal);
export default deleteNotesModal;
