var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import { json } from 'overmind';
// Load automatic components
/* eslint-disable */
/* @ts-ignore */
const req = require.context('./SpecialDataComponents/', false, /\.(js|vue)$/i);
for (const key of req.keys()) {
    const name = key.match(/\w+/)[0];
    Vue.component(name, req(key).default);
}
/* eslint-enable */
let CompanyDrawerSpecialDataTab = class CompanyDrawerSpecialDataTab extends Vue {
    constructor() {
        super(...arguments);
        this.specialDataRaw = [
            {
                company_growth: {
                    data: [
                        {
                            historic: [
                                {
                                    increase_percent: 0,
                                    past: '2019',
                                    now: '2020',
                                    increase: 0,
                                },
                                {
                                    increase_percent: 16.67,
                                    past: '2018',
                                    now: '2019',
                                    increase: 10,
                                },
                                {
                                    increase_percent: -40.0,
                                    past: '2017',
                                    now: '2018',
                                    increase: -4,
                                },
                                {
                                    increase_percent: -33.33,
                                    past: '2016',
                                    now: '2017',
                                    increase: -5,
                                },
                                {
                                    increase_percent: 10.0,
                                    past: '2015',
                                    now: '2016',
                                    increase: 10,
                                },
                                {
                                    increase_percent: 25.0,
                                    past: '2014',
                                    now: '2015',
                                    increase: 1,
                                },
                            ],
                            last: [
                                {
                                    increase_percent: 0,
                                    past: '2019',
                                    now: '2020',
                                    increase: 0,
                                },
                            ],
                            tags: [],
                        },
                    ],
                },
            },
            {
                company_website_access: {
                    data: [
                        {
                            historic: [
                                {
                                    date: '2022-07-05',
                                    audience_demographic: [
                                        {
                                            genre: {
                                                men: 47.96,
                                                women: 52.04,
                                            },
                                        },
                                    ],
                                    traffic_social: [
                                        {
                                            type: 'Youtube',
                                            percent: 100.0,
                                        },
                                    ],
                                    geral_info: {
                                        pages_per_visit: 4,
                                        total_visits: 133482,
                                        rejection_rate_percent: 54,
                                        average_visit_duration: 1.24,
                                        category: 'outros',
                                    },
                                    ranking_site: {
                                        country: 13613,
                                        global: 312135,
                                        category: 377,
                                    },
                                    traffic_type: [
                                        {
                                            'Paid Search': 0,
                                        },
                                        {
                                            'Organic Search': 36814,
                                        },
                                        {
                                            Direct: 8160,
                                        },
                                        {
                                            Social: 37,
                                        },
                                    ],
                                    country_access: [
                                        {
                                            visit_per_page: 4,
                                            name: 'Brazil',
                                            country_access_percent: 0.97,
                                        },
                                        {
                                            visit_per_page: 1,
                                            name: 'Paraguay',
                                            country_access_percent: 0.01,
                                        },
                                        {
                                            visit_per_page: 1,
                                            name: 'Ukraine',
                                            country_access_percent: 0.01,
                                        },
                                        {
                                            visit_per_page: 1,
                                            name: 'United States',
                                            country_access_percent: 0.0,
                                        },
                                        {
                                            visit_per_page: 1,
                                            name: 'Germany',
                                            country_access_percent: 0.0,
                                        },
                                    ],
                                    device_access: [
                                        {
                                            desktop: 45010,
                                            mobile: 88472,
                                        },
                                    ],
                                },
                            ],
                            last_month: {
                                date: '2022-07-05',
                                audience_demographic: [
                                    {
                                        genre: {
                                            men: 47.96,
                                            women: 52.04,
                                        },
                                    },
                                ],
                                traffic_social: [
                                    {
                                        type: 'Youtube',
                                        percent: 100.0,
                                    },
                                ],
                                geral_info: {
                                    pages_per_visit: 4,
                                    total_visits: 133482,
                                    rejection_rate_percent: 54,
                                    average_visit_duration: 1.24,
                                    category: 'outros',
                                },
                                ranking_site: {
                                    country: 13613,
                                    global: 312135,
                                    category: 377,
                                },
                                traffic_type: [
                                    {
                                        'Paid Search': 0,
                                    },
                                    {
                                        'Organic Search': 36814,
                                    },
                                    {
                                        Direct: 8160,
                                    },
                                    {
                                        Social: 37,
                                    },
                                ],
                                country_access: [
                                    {
                                        visit_per_page: 4,
                                        name: 'Brazil',
                                        country_access_percent: 0.97,
                                    },
                                    {
                                        visit_per_page: 1,
                                        name: 'Paraguay',
                                        country_access_percent: 0.01,
                                    },
                                    {
                                        visit_per_page: 1,
                                        name: 'Ukraine',
                                        country_access_percent: 0.01,
                                    },
                                    {
                                        visit_per_page: 1,
                                        name: 'United States',
                                        country_access_percent: 0.0,
                                    },
                                    {
                                        visit_per_page: 1,
                                        name: 'Germany',
                                        country_access_percent: 0.0,
                                    },
                                ],
                                device_access: [
                                    {
                                        desktop: 45010,
                                        mobile: 88472,
                                    },
                                ],
                            },
                            tags: ['low_duration_visiting_time'],
                        },
                    ],
                },
            },
            {
                company_jobs: {
                    data: [
                        {
                            last_month: {
                                percent_hiring_total_employees: 0.5,
                                total_jobs_month: 8,
                                month_reference: '2022-05-01',
                                departments: [
                                    {
                                        opened_positions_ratios: 100.0,
                                        keywords: [
                                            'ensino Relatórios',
                                            'administrativas Recebimento',
                                            'Rotinas administrativas',
                                            'acadêmicas Orientações',
                                            'atividades',
                                            'realização',
                                            'aula',
                                            'sala',
                                            'professores',
                                            'semanais',
                                            'Intermediário.Disponibilidade',
                                        ],
                                        name: 'Outros',
                                        open_positions: 2,
                                        type_of_contract: ['CLT'],
                                    },
                                    {
                                        opened_positions_ratios: 100.0,
                                        keywords: ['React', 'Javascript'],
                                        name: 'TI',
                                        open_positions: 5,
                                        type_of_contract: [],
                                    },
                                    {
                                        opened_positions_ratios: 100.0,
                                        keywords: [],
                                        name: 'Administrativo',
                                        open_positions: 1,
                                        type_of_contract: ['CLT', 'PJ'],
                                    },
                                ],
                                published_platform: {
                                    adzuna: 1,
                                    melga: 1,
                                },
                            },
                            months: [
                                {
                                    total_jobs_month: 1,
                                    month_reference: '2022-08-01',
                                    departments: [
                                        {
                                            opened_positions_ratios: 100.0,
                                            keywords: [
                                                'pedagógica',
                                                'cultural',
                                                'usuários',
                                                'Atendimento',
                                                'Mediação',
                                                'atendimento aos usuários.Inglês',
                                                'Atendimento de usuários',
                                                'semanais',
                                                'Intermediário.Horário de TrabalhoSeis',
                                                'usuários.Inglês Intermediário.Horário',
                                                'conhecimentos ou experiências',
                                            ],
                                            name: 'Outros',
                                            open_positions: 1,
                                            type_of_contract: [],
                                        },
                                    ],
                                    published_platform: {
                                        linkedin: 1,
                                    },
                                },
                                {
                                    total_jobs_month: 3,
                                    month_reference: '2022-07-01',
                                    departments: [
                                        {
                                            opened_positions_ratios: 100.0,
                                            keywords: [
                                                'Intermediário.Disponibilidade',
                                                'Pedagogia.Inglês',
                                                'Letras',
                                                'semanais.Horário de TrabalhoSeis',
                                                'ValetransporteCertificação ConhecimentoCursando',
                                                'alimentação ValetransporteCertificação',
                                                'semanais.BenefíciosTicket alimentação',
                                                'atividades pedagógicas.Cursando',
                                                'realização das atividades',
                                                'sala de aula',
                                                'professores em sala',
                                            ],
                                            name: 'Outros',
                                            open_positions: 3,
                                            type_of_contract: [],
                                        },
                                    ],
                                    published_platform: {
                                        whatjobs: 1,
                                        bebee: 1,
                                        linkedin: 1,
                                    },
                                },
                                {
                                    total_jobs_month: 2,
                                    month_reference: '2022-06-01',
                                    departments: [
                                        {
                                            opened_positions_ratios: 100.0,
                                            keywords: [
                                                'ensino Relatórios',
                                                'administrativas Recebimento',
                                                'Rotinas administrativas',
                                                'acadêmicas Orientações',
                                                'atividades',
                                                'realização',
                                                'aula',
                                                'sala',
                                                'professores',
                                                'semanais',
                                                'Intermediário.Disponibilidade',
                                            ],
                                            name: 'Outros',
                                            open_positions: 2,
                                            type_of_contract: [],
                                        },
                                    ],
                                    published_platform: {
                                        adzuna: 1,
                                        melga: 1,
                                    },
                                },
                            ],
                            job_levels: [],
                            tags: ['low_tax_hires'],
                        },
                    ],
                },
            },
        ];
        this.specialDataRaw_empty = [
            {
                company_growth: {
                    data: [],
                },
            },
        ];
        this.search = '';
        this.specialData = [];
        this.tab = 0;
    }
    getComponentByKey(data) {
        let KeyCompoment = Object.keys(data).shift();
        let namesData = {
            company_growth: {
                name: 'Crescimento da empresa',
                component: 'CompanyGrowth',
                order: 1,
            },
            company_website_access: {
                name: 'Analise do site',
                component: 'CompanyWebsiteAccess',
                order: 2,
            },
            company_jobs: {
                name: 'Vagas de emprego',
                component: 'CompanyJobs',
                order: 3,
            },
        };
        return namesData[KeyCompoment];
    }
    buildSpecialData() {
        let dataVerify = [];
        this.specialDataRaw.forEach(value => {
            let newValue = json(value);
            if (this.getComponentByKey(newValue)) {
                dataVerify.push(newValue);
            }
        });
        dataVerify.sort((a, b) => this.getComponentByKey(a).order > this.getComponentByKey(b).order ? 1 : -1);
        this.specialData = dataVerify;
        return dataVerify;
    }
    mounted() {
        this.buildSpecialData();
    }
};
__decorate([
    Prop({ default: '' })
], CompanyDrawerSpecialDataTab.prototype, "companyName", void 0);
CompanyDrawerSpecialDataTab = __decorate([
    Component({
        name: 'CompanyDrawerSpecialDataTab',
        components: {},
    })
], CompanyDrawerSpecialDataTab);
export default CompanyDrawerSpecialDataTab;
