var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"financial_body column overflow-hidden"},[(_vm.options.length > 1)?_c('q-select',{staticClass:"selection-additional-data",attrs:{"rounded":"","outlined":"","dense":"","options":_vm.options},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):_vm._e(),_c('div',{staticClass:"row justify-between items-center q-mb-md"},[(_vm.handleTitleSectionVisibility(_vm.model))?_c('h5',{staticClass:"col title-section"},[_vm._v("\n      Dados em milhões de reais\n    ")]):_vm._e(),(_vm.model === 'Métricas financeiras')?_c('h5',{staticClass:"col title-section"}):_vm._e(),_c('div',{staticClass:"financial-company-button",class:!_vm.table ? 'q-pr-sm' : ''},[_c('q-btn',{attrs:{"unelevated":"","color":_vm.table ? 'primary' : 'grey-3',"icon":"grid_view","size":"10px","text-color":_vm.table ? 'white' : 'grey-8'},on:{"click":function($event){_vm.table = true}}}),_c('q-btn',{attrs:{"unelevated":"","color":_vm.table ? 'grey-3' : 'primary',"icon":"bar_chart","size":"10px","text-color":_vm.table ? 'grey-8' : 'white'},on:{"click":function($event){_vm.table = false}}})],1)]),_vm._l((_vm.getDataFinancialCompany()),function(value,index){return _c('div',{key:index,staticClass:"column"},[(
        _vm.handleDataVisibility(value, 'financial_data', 'Balanço Patrimonial')
      )?_c('div',{staticClass:"full-width q-mb-lg"},[(_vm.table)?_c('TableBaseComponent',{attrs:{"header":_vm.header,"rows":_vm.buildRows('balanco_patrimonial')}}):_c('highcharts',{ref:"chart",refInFor:true,staticClass:"q-mt-md",attrs:{"options":_vm.buildGraphic('balanco_patrimonial')}})],1):_vm._e(),(
        _vm.handleDataVisibility(
          value,
          'financial_data',
          'Demonstrativo do Exercício'
        )
      )?_c('div',{staticClass:"full-width q-mb-lg"},[(_vm.table)?_c('TableBaseComponent',{attrs:{"header":_vm.header,"rows":_vm.buildRows('demonstrativo_exercicio')}}):_c('highcharts',{ref:"chart",refInFor:true,staticClass:"q-mt-md",attrs:{"options":_vm.buildGraphic('demonstrativo_exercicio')}})],1):_vm._e(),(_vm.handleDataVisibility(value, 'financial_data', 'Fluxo de Caixa'))?_c('div',{staticClass:"full-width q-mb-lg"},[(_vm.table)?_c('TableBaseComponent',{attrs:{"header":_vm.header,"rows":_vm.buildRows('fluxo_caixa')}}):_c('highcharts',{ref:"chart",refInFor:true,staticClass:"q-mt-md",attrs:{"options":_vm.buildGraphic('fluxo_caixa')}})],1):_vm._e(),(
        _vm.handleDataVisibility(
          value,
          'financial_metrics',
          'Métricas financeiras'
        )
      )?_c('div',{staticClass:"full-width q-mb-lg"},[(_vm.table)?_c('TableBaseComponent',{attrs:{"header":_vm.header,"rows":_vm.buildRows('metricas_financeiras')}}):_c('highcharts',{ref:"chart",refInFor:true,staticClass:"q-mt-md",attrs:{"options":_vm.buildGraphic('metricas_financeiras')}})],1):_vm._e()])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }