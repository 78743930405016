var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import Modal from './Modal.vue';
import { connect } from '@/overmind';
import { mixpanelTracking } from '@/services/mixpanel';
let ContactAndSalesInfoModal = class ContactAndSalesInfoModal extends Vue {
    constructor() {
        super(...arguments);
        this.open = false;
        this.loading = false;
        this.currentStep = 1;
        this.phoneMask = '(xx) xxxx-xxxx';
        this.firstForm = {
            email: '',
            phoneNumber: '',
        };
        this.secondForm = {
            averageTicket: '',
            preSalesReps: '',
            salesReps: '',
        };
        this.thirdForm = {
            appointmentsGoal: null,
            appointmentsGoalAchievement: '',
        };
    }
    async mounted() {
        // @ts-ignore
        this.open = await this.actions.showContactAndSalesInfoModal();
        if (this.open) {
            mixpanelTracking('Forms de atualização de dados: foi renderizado');
        }
    }
    getTitle() {
        switch (this.currentStep) {
            case 1:
                return 'Vamos atualizar seu cadastro?';
            case 2:
                return 'Sucesso! Que tal ganhar 20 créditos?';
            case 3:
                return 'Quase lá!';
            case 4:
                return 'Você ganhou 20 créditos!';
            default:
                return '';
        }
    }
    getDescription() {
        switch (this.currentStep) {
            case 1:
                return 'Preencha os campos abaixo para atualizarmos seus dados na Speedio.';
            case 2:
                return 'Responda as próximas cinco perguntas e ganhe 10 crédito de consulta e 10 crédito de exportação!';
            case 3:
                return 'Faltam só mais duas perguntas para os seus 20 créditos!';
            case 4:
                return 'Parabéns! Você ganhou 10 créditos de consulta e 10 créditos de exportação que duram um mês e já estão disponíveis em sua conta.';
            default:
                return '';
        }
    }
    getFirstButtonName() {
        switch (this.currentStep) {
            case 1:
                return 'Concluir';
            case 2:
                return 'Próximas perguntas';
            case 3:
                return 'Finalizar';
            case 4:
                return 'Finalizar';
            default:
                return '';
        }
    }
    getSecondButtonName() {
        switch (this.currentStep) {
            case 2:
                return 'Não, obrigado';
            case 3:
                return 'Não quero meus creditos';
            default:
                return '';
        }
    }
    async noTanks() {
        if (this.currentStep == 2) {
            mixpanelTracking('Forms de atualização de dados: clicou em "Não, obrigado" na tela de dados da empresa');
        }
        else if (this.currentStep == 3) {
            mixpanelTracking('Forms de atualização de dados: clicou em "Não quero meus créditos" na tela de metas');
        }
        await this.closeModal();
    }
    async closeModal() {
        const result = await this.finishForm();
        if (result) {
            // @ts-ignore
            this.actions.closeModal();
            this.open = false;
        }
    }
    async nextStep() {
        if (this.currentStep == 1) {
            mixpanelTracking('Forms de atualização de dados: clicou em "Concluir" na tela inicial de dados');
            // @ts-ignore
            const valid = await this.$refs.firstForm.validate();
            if (valid) {
                this.currentStep += 1;
                mixpanelTracking('Forms de atualização de dados: tela de dados da empresa foi renderizada');
            }
            else {
                mixpanelTracking('Forms de atualização de dados: tentou prosseguir sem preecher telefone ou e-mail');
            }
        }
        else if (this.currentStep == 2) {
            mixpanelTracking('Forms de atualização de dados: clicou em "Próximas perguntas" na tela de dados da empresa');
            // @ts-ignore
            const valid = await this.$refs.secondForm.validate();
            if (valid) {
                this.currentStep += 1;
                mixpanelTracking('Forms de atualização de dados: tela de metas foi renderizada');
            }
            else {
                mixpanelTracking('Forms de atualização de dados: tentou prosseguir sem preencher dados da empresa');
            }
        }
        else if (this.currentStep == 3) {
            mixpanelTracking('Forms de atualização de dados: clicou em "Finalizar" na tela de metas');
            // @ts-ignore
            const valid = await this.$refs.thirdForm.validate();
            if (valid) {
                const result = await this.finishForm();
                if (result) {
                    this.currentStep += 1;
                    mixpanelTracking('Forms de atualização de dados: tela de créditos ganhos foi renderizada');
                }
            }
            else {
                mixpanelTracking('Forms de atualização de dados: tentou prosseguir sem dados de metas');
            }
        }
        else if (this.currentStep == 4) {
            mixpanelTracking('Forms de atualização de dados: clicou em "Finalizar" na tela de créditos ganhos');
            this.open = false;
        }
    }
    async finishForm() {
        this.loading = true;
        try {
            const params = {
                phone_number: this.firstForm.phoneNumber,
                email: this.firstForm.email,
                average_ticket: this.secondForm.averageTicket,
                pre_sales_reps: this.secondForm.preSalesReps,
                sales_reps: this.secondForm.salesReps,
                appointments_goal: this.thirdForm.appointmentsGoal,
                appointments_goal_achievement: this.thirdForm.appointmentsGoalAchievement,
            };
            await Vue.axios.post('/contact_and_sales_info/create_or_update', params);
            this.loading = false;
            return true;
        }
        catch (e) {
            this.loading = false;
            return false;
        }
    }
    setPhoneMask(value) {
        if (this.firstForm.phoneNumber.length >= 14) {
            this.phoneMask = '(xx) xxxxx-xxxx';
        }
        else {
            this.phoneMask = '(xx) xxxx-xxxx';
        }
    }
};
ContactAndSalesInfoModal = __decorate([
    Component(connect(({ state: { modals: state }, actions: { modals: actions } }) => ({
        actions: actions,
        state: state,
    }), {
        name: 'ContactAndSalesInfoModal',
        components: {
            Modal,
        },
    }))
], ContactAndSalesInfoModal);
export default ContactAndSalesInfoModal;
