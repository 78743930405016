var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import { isEmpty } from 'lodash';
import CompanyEmployeeAndBillingHistory from 'components/CompanyEmployeeAndBillingHistory.vue';
import CompanyFinancialMetrics from 'components/CompanyFinancialMetrics.vue';
import CompanyFinancialData from 'components/CompanyFinancialData.vue';
let CompanyAdditionalDataUnstructured = class CompanyAdditionalDataUnstructured extends Vue {
    get employeesBillingHistory() {
        if (!isEmpty(this.unstructured['Faixa de faturamento da empresa']) ||
            !isEmpty(this.unstructured['Faixa de funcionários da empresa']) ||
            !isEmpty(this.unstructured['Faixa de faturamento da unidade/cnpj']) ||
            !isEmpty(this.unstructured['Faixa de funcionários da unidade/cnpj'])) {
            return this.unstructured;
        }
        return undefined;
    }
    get financialMetrics() {
        if (!isEmpty(this.unstructured['ROA']) ||
            !isEmpty(this.unstructured['ROE']) ||
            !isEmpty(this.unstructured['Margem bruta']) ||
            !isEmpty(this.unstructured['Margem operacional']) ||
            !isEmpty(this.unstructured['Margem EBITDA']) ||
            !isEmpty(this.unstructured['Margem líquida']) ||
            !isEmpty(this.unstructured['Dívida sobre EBITDA']) ||
            !isEmpty(this.unstructured['Dívida sobre ATIVO'])) {
            // build metrics data with the correct order
            return this.buildFinancialMetricsData();
        }
        return undefined;
    }
    financialData() {
        if (!isEmpty(this.unstructured['Receita bruta']) ||
            !isEmpty(this.unstructured['Custos operacionais']) ||
            !isEmpty(this.unstructured['Resultado operacional bruto']) ||
            !isEmpty(this.unstructured['Despesas operacionais']) ||
            !isEmpty(this.unstructured['EBITDA']) ||
            !isEmpty(this.unstructured['EBIT']) ||
            !isEmpty(this.unstructured['Lucro líquido']) ||
            !isEmpty(this.unstructured['Ativo total']) ||
            !isEmpty(this.unstructured['Passivo total']) ||
            !isEmpty(this.unstructured['Passivo não circulante']) ||
            !isEmpty(this.unstructured['Passivo circulante']) ||
            !isEmpty(this.unstructured['Patrimonio líquido']) ||
            !isEmpty(this.unstructured['Fluxo de caixa das atividades operacionais']) ||
            !isEmpty(this.unstructured['Fluxo de caixa livre'])) {
            return this.buildFinancialData();
        }
        return undefined;
    }
    buildFinancialData() {
        let data = {
            'Receita bruta': this.unstructured['Receita bruta'],
            'Custos operacionais': this.unstructured['Custos operacionais'],
            'Resultado operacional bruto': this.unstructured['Resultado operacional bruto'],
            'Despesas operacionais': this.unstructured['Despesas operacionais'],
            EBITDA: this.unstructured['EBITDA'],
            EBIT: this.unstructured['EBIT'],
            'Lucro líquido': this.unstructured['Lucro líquido'],
            'Ativo total': this.unstructured['Ativo total'],
            'Passivo total': this.unstructured['Passivo total'],
            'Passivo não circulante': this.unstructured['Passivo não circulante'],
            'Passivo circulante': this.unstructured['Passivo circulante'],
            'Patrimonio líquido': this.unstructured['Patrimonio líquido'],
            'Fluxo de caixa das atividades operacionais': this.unstructured['Fluxo de caixa das atividades operacionais'],
            'Fluxo de caixa livre': this.unstructured['Fluxo de caixa livre'],
        };
        /* Remove possible undefined values */
        Object.keys(data).forEach(key => data[key] === undefined && delete data[key]);
        return data;
    }
    buildFinancialMetricsData() {
        let data = {
            ROA: this.unstructured['ROA'],
            ROE: this.unstructured['ROE'],
            'Margem bruta': this.unstructured['Margem bruta'],
            'Margem operacional': this.unstructured['Margem operacional'],
            'Margem EBITDA': this.unstructured['Margem EBITDA'],
            'Margem líquida': this.unstructured['Margem líquida'],
            'Dívida sobre EBITDA': this.unstructured['Dívida sobre EBITDA'],
            'Dívida sobre ATIVO': this.unstructured['Dívida sobre ATIVO'],
        };
        /* Remove possible undefined values */
        Object.keys(data).forEach(key => data[key] === undefined && delete data[key]);
        return data;
    }
};
__decorate([
    Prop({ default: () => { } })
], CompanyAdditionalDataUnstructured.prototype, "unstructured", void 0);
CompanyAdditionalDataUnstructured = __decorate([
    Component({
        name: 'CompanyAdditionalDataUnstructured',
        components: {
            CompanyEmployeeAndBillingHistory,
            CompanyFinancialMetrics,
            CompanyFinancialData,
        },
    })
], CompanyAdditionalDataUnstructured);
export default CompanyAdditionalDataUnstructured;
