var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
let createNotes = class createNotes extends Vue {
    constructor() {
        super(...arguments);
        //@ts-ignore
        this.mensage = this.notation.note;
        this.date = '';
    }
    mounted() {
        //@ts-ignore
        const partesData = this.notation.reminder.split('-');
        const dataFormatada = `${partesData[2]}/${partesData[1]}/${partesData[0]}`;
        this.date =
            dataFormatada +
                ' - ' +
                //@ts-ignore
                this.extractTimeWithOffset(this.notation.updated_at);
        if (this.mensage.length > 22) {
            this.mensage = this.mensage.substring(0, 22) + '...';
        }
    }
    removeNote() {
        this.$emit('removeNote', this.notation);
    }
    exibeNote() {
        this.$emit('exibeNote', this.notation);
    }
    extractTimeWithOffset(timestamp) {
        const inputDate = new Date(timestamp);
        const offsetDate = new Date(inputDate.getTime() - 3 * 60 * 60 * 1000);
        const hours = offsetDate.getUTCHours();
        const minutes = offsetDate.getUTCMinutes();
        const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}h`;
        return formattedTime;
    }
};
__decorate([
    Prop({ default: {} })
], createNotes.prototype, "notation", void 0);
createNotes = __decorate([
    Component({
        name: 'createNotes',
    })
], createNotes);
export default createNotes;
