var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import TableBaseComponent from './ComponentsBase/TableBaseComponent.vue';
let Technologies = class Technologies extends Vue {
    buildRows() {
        let rows = [];
        let rowFormated = {};
        this.dataComponent.technologies.forEach(value => {
            if (value.has_technology) {
                rowFormated['tech'] =
                    value.has_technology.data[0].technologies.join(', ');
            }
            if (value.mx_analysis) {
                if (value.mx_analysis.data[0].host_site) {
                    rowFormated['host'] = value.mx_analysis.data[0].host_site;
                }
                if (value.mx_analysis.data[0].host_email) {
                    rowFormated['email'] = value.mx_analysis.data[0].host_email;
                }
                if (value.mx_analysis.data[0].url_host) {
                    rowFormated['url'] = value.mx_analysis.data[0].url_host;
                }
                if (value.mx_analysis.data[0].date_crawled) {
                    rowFormated['date'] = this.formatDate(new Date(value.mx_analysis.data[0].date_crawled));
                }
            }
            if (value.has_practice) {
                rowFormated['practice'] = value.has_practice.data.join(', ');
            }
        });
        rows.push(rowFormated);
        return rows;
    }
    padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }
    formatDate(date) {
        return [
            this.padTo2Digits(date.getDate()),
            this.padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('/');
    }
    buildHeader() {
        let header = [];
        this.dataComponent.technologies.forEach(value => {
            if (value.has_technology) {
                header.push('Tecnologias');
            }
            if (value.mx_analysis) {
                if (value.mx_analysis.data[0].host_site) {
                    header.push('Hospedagem do Site');
                }
                if (value.mx_analysis.data[0].host_email) {
                    header.push('Hospedagem do e-mail');
                }
                if (value.mx_analysis.data[0].url_host) {
                    header.push('Website');
                }
                if (value.mx_analysis.data[0].date_crawled) {
                    header.push('Data de Captura');
                }
            }
            if (value.has_practice) {
                header.push('Práticas da empresa');
            }
        });
        return header;
    }
};
__decorate([
    Prop({ default: () => { } })
], Technologies.prototype, "dataComponent", void 0);
Technologies = __decorate([
    Component({
        name: 'Technologies',
        components: {
            TableBaseComponent,
        },
    })
], Technologies);
export default Technologies;
